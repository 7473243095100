import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import Button from '../../components/Button';
import LoadingSpinner from '../../components/LoadingSpinner';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import { fetchOrder } from '../../store/slices/order';

import collectionImg from '../../assets/images/collection-image.png';

import cySelectors from '../../tests/cySelectors';

const Confirmation = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { orderId, venueId } = useParams();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleFetchOrder = useCallback(() => {
    dispatch(fetchOrder({ params: orderId, query: { venueId } }));
  }, [dispatch, orderId, venueId]);

  const handleGoBackToConfirmationPage = useCallback(() => {
    history.push(`/confirmation/${venueId}/${orderId}`);
  }, [venueId, orderId, history]);

  const handleGoToCollectionConfirmationPage = useCallback(() => {
    history.push(`/collection-confirmation/${venueId}/${orderId}`);
  }, [venueId, orderId, history]);

  useEffect(() => {
    handleFetchOrder();
  }, [handleFetchOrder]);

  return orderData ? (
    <div className="collection">
      <div className="collection__wrapper">
        <div className="collection__img">
          <img src={collectionImg} alt="collection" />
        </div>
        <p className="collection__message">Please head to the bar to collect your order</p>

        <div className="collection__footer">
          <div className="collection__footer__ctas">
            <Button
              type="button"
              textCenter
              onClick={handleGoToCollectionConfirmationPage}
              data-cy={cySelectors.SUBMIT_BTN}
            >
              Okay, I am at the bar
            </Button>
            <Button type="button" white textCenter onClick={handleGoBackToConfirmationPage}>
              Go back
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Confirmation);
