const getCurrentMenuVenueId = (vendors = [], vendorIdQuery, venueId, isMultiMenuVenue) => {
  const [fallbackVendor] = vendors;
  if (isMultiMenuVenue && vendorIdQuery) {
    const vendor = vendors?.find(({ id }) => id === vendorIdQuery);

    return vendor.venueId;
  }

  if (isMultiMenuVenue) {
    return fallbackVendor?.venueId;
  }

  return venueId;
};

export default getCurrentMenuVenueId;
