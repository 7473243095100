import sanitiseGACartPayload from './sanitiseGACartPayload';

const sendGAItemListViewed = (checkoutData, list = 'menu', listId) => {
  if (window.gtag) {
    const items = sanitiseGACartPayload(checkoutData);

    window.gtag('event', 'view_item_list', {
      item_list_name: list,
      item_list_id: listId,
      items,
    });
  }
};

export default sendGAItemListViewed;
