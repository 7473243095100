import React from 'react';
import clsx from 'clsx';

import OpeningTimes from '../OpeningTimes';

import cySelectors from '../../tests/cySelectors';

const HeroBannerDesktop = ({
  text,
  isOpen,
  isClosedToday,
  closeTime,
  openTime,
  handleOpenOpeningHoursModal,
}) => {
  const isLongText = text.length >= 35;
  const isOpeningLaterToday = !isOpen && openTime;
  const isOpenUntil = isOpen && closeTime && !isOpeningLaterToday;
  const classes = clsx('hero-banner__desktop', {
    'hero-banner__desktop--long-text': isLongText,
  });

  return (
    <div className={classes} data-cy={cySelectors.HERO_BANNER__DESKTOP}>
      <div className="hero-banner__desktop__img" title={text} />
      <div className="hero-banner__desktop__content">
        <h1 className="hero-banner__desktop__content__text">{text}</h1>
        <div className="hero-banner__desktop__content__opening-times">
          {isClosedToday && <span>Closed</span>}
          {isOpeningLaterToday && <span>Opens soon {openTime} </span>}
          {isOpenUntil && <span>Open until {closeTime} </span>}
          <span className="hero-banner__desktop__content__opening-times__separator">•</span>{' '}
          <OpeningTimes handleOpenModal={handleOpenOpeningHoursModal} />
        </div>
      </div>
    </div>
  );
};

export default HeroBannerDesktop;
