import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BackIcon } from '../../assets/images/back.svg';

import cySelectors from '../../tests/cySelectors';

const BackButton = ({ to }) => {
  const history = useHistory();

  const handleGoBack = () => {
    if (to) {
      history.push(to);
    } else {
      history.goBack();
    }
  };

  return (
    <div className="back-button" data-cy={cySelectors.BACK_BTN}>
      <button type="button" aria-label="Back" onClick={handleGoBack}>
        <BackIcon title="back" />
      </button>
    </div>
  );
};

export default BackButton;
