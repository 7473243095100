import React from 'react';

import ItemModal from '../../../components/ItemModal';
import VouchersForm from './VouchersForm';

const VouchersModal = ({ isModalOpen, setIsModalOpen, onCloseModal, currentItem }) => (
  <ItemModal
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
    currentItem={currentItem}
    shouldSupportWebp={false}
    onCloseModal={onCloseModal}
    isVouchersModal
  >
    <VouchersForm currentItem={currentItem} onCloseModal={onCloseModal} />
  </ItemModal>
);

export default VouchersModal;
