import _filter from 'lodash.filter';

const getFilteredCategoryItems = (menu, filters) => {
  const filteredCategories = _filter(menu, {
    categoryItems: [
      {
        dietaryRequirements: filters.map((filter) => ({ requirementId: filter })),
      },
    ],
  });
  const categoriesWithFilteredCategoriesItems = filteredCategories.map((filteredCategory) => ({
    ...filteredCategory,
    categoryItems: _filter(filteredCategory.categoryItems, {
      dietaryRequirements: filters.map((filter) => ({ requirementId: filter })),
    }),
  }));

  return categoriesWithFilteredCategoriesItems;
};

export default getFilteredCategoryItems;
