import React from 'react';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';

import AppManager from './AppManager';

import { store } from '../../store';

const App = () => (
  <Provider store={store}>
    <HelmetProvider>
      <AppManager />
    </HelmetProvider>
  </Provider>
);

export default App;
