import React, { useEffect, useState } from 'react';

import ChoiceInput from '../../../../components/ChoiceInput';
import choiceInputTypes from '../../../../components/ChoiceInput/choiceInputTypes';

import cySelectors from '../../../../tests/cySelectors';

const name = 'allowMarketing';

const MarketingOptin = ({ isUserOptedIn = true, isMarketingEnabled, setIsMarketingEnabled }) => {
  const [checked, setChecked] = useState(true);
  const isFieldDirty = isMarketingEnabled !== null;

  const handleOnChange = () => {
    setChecked(!checked);
    setIsMarketingEnabled(!checked);
  };

  useEffect(() => {
    if (!isFieldDirty) {
      setChecked(isUserOptedIn);
    } else {
      setChecked(isMarketingEnabled);
    }
  }, [isUserOptedIn, checked, isFieldDirty, isMarketingEnabled]);

  return (
    <div className="marketing-optin" data-cy={cySelectors.CHECKOUT_MARKETING_OPTIN}>
      <label htmlFor={name}>
        <ChoiceInput
          type={choiceInputTypes.CHECKBOX}
          name={name}
          checked={checked}
          onChange={handleOnChange}
          isSmall
        />
        Remember me to be able to repeat orders, receive promotions and future communications
      </label>
    </div>
  );
};

export default MarketingOptin;
