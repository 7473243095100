/* eslint-disable react-hooks/rules-of-hooks */
import React, { createContext, useEffect, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import pickBy from 'lodash.pickby';
import clsx from 'clsx';

import useYupValidationResolver from '../../hooks/useYupValdationResolver';

// TODO: discuss if worth to integrate https://react-hook-form.com/advanced-usage#FormProviderPerformance
// on <FormProvider /> to avoid re-renders.
const FormContext = createContext();

const Form = ({
  handleOnSubmit = () => {},
  validationSchema,
  setFormInstance,
  children,
  validateOnLoad,
  mode = 'onBlur',
  defaultValues,
  disabled = false,
  extraClasses = '',
}) => {
  // One of the few occations when rule-of-hooks conditional executing can be ignored.
  const resolver = validationSchema ? useYupValidationResolver(validationSchema) : null;
  const formOptions = pickBy({
    mode,
    resolver,
  });

  const formMethods = useForm({ ...formOptions, defaultValues });
  const {
    isDirty,
    setError,
    formState: { isValid },
    clearErrors,
    watch,
    trigger,
    reset,
    getValues,
  } = formMethods;
  const classes = clsx('form', {
    [`${extraClasses}`]: extraClasses,
    'form--disabled': disabled,
  });

  const setFormInstanceCallback = useCallback(() => {
    setFormInstance({ setError, isValid, watch, reset, clearErrors, getValues });
  }, [setFormInstance, setError, isValid, watch, reset, clearErrors, getValues]);

  useEffect(() => {
    if (setFormInstance) {
      setFormInstanceCallback();
    }
  }, [setFormInstance, setFormInstanceCallback]);

  useEffect(() => {
    if (validateOnLoad && isDirty) {
      trigger();
    }
  }, [trigger, validateOnLoad, isDirty]);

  return (
    <FormContext.Provider value={{ validateOnLoad }}>
      <FormProvider {...formMethods}>
        <form className={classes} onSubmit={formMethods.handleSubmit(handleOnSubmit)}>
          {children}
        </form>
      </FormProvider>
    </FormContext.Provider>
  );
};

export { Form as default, FormContext };
