import React from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import Button from '../Button';
import cySelectors from '../../tests/cySelectors';

const FormField = ({
  name,
  label,
  helpText,
  placeholder,
  type = 'text',
  autocomplete = 'on',
  extraClasses,
  children,
  inlineSubmit,
  maxLength,
  ...extraProps
}) => {
  const {
    register,
    formState: { errors },
    getValues,
  } = useFormContext();

  const isTextArea = type === 'textarea';
  const {
    label: inlineSubmitBtnLabel,
    successResponse: inlineSubmitSuccessResponse,
    isLoading: inlineSubmitIsLoading,
    handleOnSubmit,
    isValid,
  } = inlineSubmit || {};
  const isInlineSuccessMessageVisible =
    inlineSubmit && inlineSubmitSuccessResponse && !errors[name];
  const classes = clsx(
    {
      [extraClasses]: extraClasses,
    },
    'form__field',
  );
  const handleInlineSubmit = (event) => {
    event.preventDefault();

    const inlineFieldValue = getValues(name);

    handleOnSubmit(inlineFieldValue);
  };

  return (
    <div className={classes}>
      {label && (
        <label className="form__field__label" htmlFor={name}>
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      <div className="form__field__input-container">
        {!isTextArea ? (
          <input
            type={type}
            placeholder={placeholder}
            {...register(name)}
            {...extraProps}
            id={name}
            name={name}
            aria-invalid={errors[name] ? 'true' : 'false'}
            autoComplete={autocomplete}
            maxLength={maxLength}
          />
        ) : (
          <textarea
            placeholder={placeholder}
            {...register(name)}
            {...extraProps}
            id={name}
            name={name}
            aria-invalid={errors[name] ? 'true' : 'false'}
            autoComplete={autocomplete}
            maxLength={maxLength}
          />
        )}
        {inlineSubmit && (
          <Button
            type="button"
            white
            textCenter
            disabled={inlineSubmitIsLoading || !isValid}
            onClick={handleInlineSubmit}
            data-cy={cySelectors.FORM_FIELD_SUBMIT}
          >
            {inlineSubmitBtnLabel}
          </Button>
        )}
      </div>
      {children}
      {errors[name] && (
        <div data-cy={cySelectors.FORM_FIELD_ERROR} className="form__field__error">
          {errors[name].message}
        </div>
      )}
      {isInlineSuccessMessageVisible && (
        <div data-cy={cySelectors.FORM_FIELD_SUCCESS} className="form__field__success">
          {inlineSubmitSuccessResponse}
        </div>
      )}
    </div>
  );
};

export default FormField;
