// https://medium.com/trabe/catching-asynchronous-errors-in-react-using-error-boundaries-5e8a5fd7b971
import { useCallback, useState } from 'react';

import ServedUpError from '../utils/ServedUpError';
import isInlineError from '../utils/isInlineError';

/**
 * A hook that will throw a full page ServedUpError if the error is not passed as one of the
 * inlineErrorCodes in errorCodes.js.
 *
 * @returns {function} - A function that will throw a ServedUpError if the error is not inline.
 */

const useAsyncServedUpError = () => {
  const [_error, setError] = useState();

  const handleError = useCallback(
    (errorPayload) => {
      const isInline = isInlineError(errorPayload);

      if (!isInline) {
        setError(() => {
          throw new ServedUpError(errorPayload);
        });
      }
    },
    [setError],
  );

  return handleError;
};

export default useAsyncServedUpError;
