import servedupRequest from '..';

const postRating = async ({ orderId, payload }) => {
  const { rating, review } = payload;

  const finalPayload = {
    ...payload,
    review: review || 'NONE',
    rating: parseInt(rating, 10),
  };

  return servedupRequest({
    endpoint: `orders/${orderId}/rating`,
    method: 'POST',
    payload: finalPayload,
    handleError: false,
  });
};

export default postRating;
