const sendGAPromotionClicked = (campaignId, itemId) => {
  if (window.gtag) {
    window.gtag('event', 'select_promotion', {
      promotion_id: campaignId,
      items: [{ item_id: itemId }],
    });
  }
};

export default sendGAPromotionClicked;
