import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'menu',
  ENDPOINT: 'venues',
  RESOURCE: 'menu',
};

const sliceName = sliceArgs.SLICE_NAME;

const fetchMenu = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: sliceArgs.RESOURCE,
  method: 'GET',
  version: 'v2',
});

const extraReducers = [fetchMenu];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchMenu };
export default reducer;
