import Logger from './Logger';

const detectWebpSupport = () => {
  try {
    const elem = typeof document === 'object' ? document.createElement('canvas') : {};

    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  } catch (error) {
    Logger.error(error);

    return false;
  }
};

export default detectWebpSupport;
