import sanitiseGACartPayload from './sanitiseGACartPayload';

const sendGAItemViewed = (checkoutData) => {
  if (window.gtag) {
    const { currency, total: value } = checkoutData;
    const items = sanitiseGACartPayload(checkoutData);

    window.gtag('event', 'add_to_cart', {
      currency,
      value,
      items,
    });
  }
};

export default sendGAItemViewed;
