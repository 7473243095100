import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'paidup',
  ENDPOINT: 'orders',
};

const getPaidUpOrderPayloadTransformer = ({ venueId }) => ({
  venueId,
});

const getPaidUpOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: `checkout/paidup`,
  method: 'GET',
  payloadTransformer: getPaidUpOrderPayloadTransformer,
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [getPaidUpOrder];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { getPaidUpOrder };
export default reducer;
