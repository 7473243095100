/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Modal from 'react-modal';

import Header from '../../../components/Header';
import LoadingSpinner from '../../../components/LoadingSpinner';

import filterProviders from '../../../utils/filterProviders';

import { ReactComponent as ChevronDown } from '../../../assets/images/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron-up.svg';

import cySelectors from '../../../tests/cySelectors';

const ProviderButton = ({ provider, chooseProvider }) => {
  const { name, logo } = provider;
  const [isLoading, setIsLoading] = useState(false);
  return (
    <div
      role="button"
      key={name}
      tabIndex={0}
      className="checkout__paidup__modal__content__box__provider"
      data-cy={cySelectors.CHECKOUT_PAIDUP_PROVIDER_BTN}
      onClick={() => {
        setIsLoading(!isLoading);
        chooseProvider(provider);
      }}
      onKeyDown={() => {
        setIsLoading(!isLoading);
        chooseProvider(provider);
      }}
    >
      {isLoading ? (
        <LoadingSpinner small />
      ) : (
        <div className="checkout__paidup__modal__content__box__provider__container">
          <div className="checkout__paidup__modal__content__box__provider__container__img">
            <img src={logo} alt={`${name}name`} />
          </div>
          <p>{name}</p>
        </div>
      )}
    </div>
  );
};

const CheckoutPaidUpModal = ({ isModalOpen, handleToggleModal, chooseProvider }) => {
  const [isContentShown, setIsContentShown] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const { data: bankedData } = useSelector((state) => state.banked);
  const {
    provider_brands: bankProviders,
    terms_and_conditions:
      terms = 'https://banked.com/terms-and-conditions/pay-by-bank?tab=tab-checkout',
    privacy_policy: privacy = 'https://banked.com/privacy-policy',
    legal_text: legal,
  } = bankedData || {};
  const { highlightedProviders, otherProviders } = filterProviders(bankProviders);
  const [showAllBanks, setShowAllBanks] = useState(false);
  const contentRef = useRef(null);
  const classes = clsx('checkout__paidup__modal__content', {
    'checkout__paidup__modal__content--is-visible': isContentShown,
    'checkout__paidup__modal__content--is-closing': isModalClosing,
  });

  const handleCloseModal = useCallback(() => {
    setIsModalClosing(true);
    handleToggleModal();
  }, [handleToggleModal]);

  const handleOnAfterCloseCleanup = () => {
    setIsModalClosing(false);
    setIsContentShown(false);
  };

  const handleCloseModalFromOverlay = useCallback(
    (event) => {
      const { target } = event;
      const isContentRef = target === contentRef.current;

      if (isContentRef) {
        handleCloseModal();
      }
    },
    [handleCloseModal],
  );

  return (
    // TODO: refactor into an animated slide up modal for <MenuFiltersModal /> and <CheckoutPaidUpModal />
    <Modal
      appElement={document.getElementById('root')}
      isOpen={isModalOpen}
      overlayClassName="checkout__paidup__modal__overlay"
      portalClassName="checkout__paidup__modal"
      className={classes}
      onAfterOpen={() => setIsContentShown(true)}
      onAfterClose={handleOnAfterCloseCleanup}
      closeTimeoutMS={500}
      contentElement={(props, contentElement) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          {...props}
          onKeyDown={handleCloseModalFromOverlay}
          onClick={handleCloseModalFromOverlay}
          ref={contentRef}
        >
          {contentElement}
        </div>
      )}
    >
      <div className="checkout__paidup__modal__content__box">
        <div className="checkout__paidup__modal__content__box__header">
          <div className="checkout__paidup__modal__content__box__header__text">
            <p className="checkout__paidup__modal__content__box__header__text__title">
              Pay via your app
            </p>
            <p>This will open your app for fast & secure payment</p>
          </div>
          <button onClick={handleCloseModal} type="button">
            Close
          </button>
        </div>
        <div
          className="checkout__paidup__modal__content__box__providers"
          data-cy={cySelectors.CHECKOUT_PAIDUP_BANKS}
        >
          {bankProviders &&
            highlightedProviders.map((provider) => (
              <ProviderButton
                provider={provider}
                chooseProvider={chooseProvider}
                key={provider.id}
              />
            ))}
          {showAllBanks && bankProviders && (
            <>
              {otherProviders.map((provider) => (
                <ProviderButton
                  provider={provider}
                  chooseProvider={chooseProvider}
                  key={provider.id}
                />
              ))}
            </>
          )}
        </div>

        {showAllBanks && bankProviders && (
          <div className="checkout__paidup__modal__content__message">
            If your bank is not here please choose another payment method.
          </div>
        )}
        <button
          className="checkout__paidup__modal__content__reveal__button"
          type="button"
          onClick={() => setShowAllBanks(!showAllBanks)}
        >
          <span> {showAllBanks ? 'Hide banks' : 'Show all banks'}</span>
          {showAllBanks ? <ChevronUp /> : <ChevronDown />}
        </button>
        <div className="checkout__paidup__modal__content__box__footer">
          {terms && (
            <>
              By selecting your bank you are agreeing to the{' '}
              <a target="_blank" href={terms} rel="noreferrer">
                Terms and Conditions
              </a>{' '}
              of the Pay by Bank service
            </>
          )}
          {privacy && (
            <>
              {' '}
              and the{' '}
              <a target="_blank" href={privacy} rel="noreferrer">
                Privacy Statement
              </a>
            </>
          )}{' '}
          You also give explicit consent for Banked to initiate this payment from the bank account
          you select. Powered by{' '}
          <a target="_blank" href="https://banked.com/" rel="noreferrer">
            Banked :
          </a>
          .{legal && <p>{legal}</p>}
        </div>
      </div>
    </Modal>
  );
};

export default CheckoutPaidUpModal;
