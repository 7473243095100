import sanitiseGACartPayload from './sanitiseGACartPayload';

const sendGAPurchase = (checkoutData) => {
  if (window.gtag) {
    const {
      currency,
      total: value,
      serviceSubtotal: tax,
      venueId: affiliation,
      promoCode,
      orderId,
    } = checkoutData;
    const items = sanitiseGACartPayload(checkoutData);

    window.gtag('event', 'purchase', {
      transaction_id: orderId,
      affiliation,
      value,
      currency,
      coupon: promoCode,
      tax,
      items,
    });
  }
};

export default sendGAPurchase;
