import React from 'react';

import ItemUpsellQuantityChanger from '../ItemUpsellQuantityChanger';
import MenuCategoryItem from '../MenuCategories/MenuCategoryItem';
import { ReactComponent as CheckCircle } from '../../assets/images/check-circle.svg';
import { ReactComponent as CheckCircleFilled } from '../../assets/images/check-circle-filled.svg';
import sendGAPromotionClicked from '../../utils/tracking/GA/sendGAPromotionClicked';

const ItemUpsells = ({
  upsells,
  allergensSelected,
  isNoTitle = false,
  handleUpsellModalToggle,
  handleGetItemCount,
  handleChangeQuantity,
  itemAdded = {},
}) => {
  const { itemName, thumbUrl } = itemAdded;
  return (
    upsells.length > 0 && (
      <div className={`item-upsells ${upsells.length > 4 && 'item-upsells-checkout'}`}>
        {itemName && (
          <div className="item-upsells__title">
            {thumbUrl ? (
              <div className="menu__category-item__box__img item-upsell__thumb">
                <img alt={itemName} src={thumbUrl} />
                <CheckCircleFilled className="item-upsells__title__check-filled" />
              </div>
            ) : (
              <CheckCircle className="item-upsells__title__check" />
            )}
            <div>
              <p>{itemName}</p>
              <p>
                <strong>Added to your order</strong>
              </p>
            </div>
          </div>
        )}
        {!isNoTitle && <h3>You might also like</h3>}
        {upsells.map((upsellItem) => {
          const upsoldItemCount = handleGetItemCount({ item: upsellItem });

          const handleModalToggle = () => {
            if (!upsoldItemCount) {
              handleUpsellModalToggle(upsellItem);
              sendGAPromotionClicked(
                isNoTitle ? 'CHECKOUT_UPSELL' : 'ITEM_UPSELL',
                upsellItem.itemId,
              );
            }

            return null;
          };

          return upsellItem ? (
            <div key={upsellItem?.itemId} className="item-upsell">
              <MenuCategoryItem
                key={upsellItem?.itemId}
                item={upsellItem}
                allergensSelected={allergensSelected}
                isLazyLoadEnabled={false}
                handleModalToggle={handleModalToggle}
                isUpsell
                isReviewUspell={isNoTitle}
              />
              <ItemUpsellQuantityChanger
                handleOpenModal={() => handleUpsellModalToggle(upsellItem)}
                handleChangeQuantity={handleChangeQuantity}
                itemCount={upsoldItemCount}
                item={upsellItem}
              />
            </div>
          ) : null;
        })}
      </div>
    )
  );
};

export default ItemUpsells;
