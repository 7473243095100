import React from 'react';
import { useSelector } from 'react-redux';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import VouchersListTable from './VouchersListTable';
import LoadingSpinner from '../../components/LoadingSpinner';

const VouchersList = () => {
  const {
    data: vouchersData,
    loading: vouchersLoading,
    error: vouchersError,
  } = useSelector((state) => state.vouchers);

  const hasVouchers = !!vouchersData?.length;

  return (
    <div className="vouchers-list">
      {vouchersError && <p>There was a problem in loading your vouchers.</p>}

      {vouchersLoading && <LoadingSpinner center />}

      {!hasVouchers && !vouchersLoading && <p>You don&apos;t have any vouchers yet.</p>}

      {hasVouchers && <VouchersListTable vouchers={vouchersData} />}
    </div>
  );
};

export default withHandleRoutesDispatch(VouchersList);
