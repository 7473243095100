import React, { useContext, useEffect } from 'react';
import AppContext from '../App/AppContext';
import ConfirmationSpinner from '../ConfirmationSpinner';
import LoadingSpinner from '../LoadingSpinner';
import { isMacLike } from '../../utils/isMacLike';

const UniversalConfirmation = () => {
  const { showUniversalConfirmation, showUniversalLoading } = useContext(AppContext);

  const show = showUniversalConfirmation || showUniversalLoading;

  useEffect(() => {
    if (showUniversalConfirmation) {
      if (!isMacLike) {
        window.navigator.vibrate(100);
      }
    }
  }, [showUniversalConfirmation]);

  return show ? (
    <div className="universal-confirmation">
      <div className="universal-confirmation__contents">
        <div className="universal-confirmation__contents__square">
          {showUniversalLoading && <LoadingSpinner />}
          {showUniversalConfirmation && <ConfirmationSpinner />}
        </div>
      </div>
    </div>
  ) : null;
};

export default UniversalConfirmation;
