import React from 'react';

import { ReactComponent as AllergensIcon } from '../../../assets/images/allergens-icon.svg';

import cySelectors from '../../../tests/cySelectors';

const MenuAllergens = ({ allergens }) => (
  <div className="menu__allergens" data-cy={cySelectors.MENU_ITEMS_MODAL__ALLERGENS}>
    <AllergensIcon />
    <ul className="menu__allergens__list">
      <li className="menu__allergens__list__label" aria-label="Contains">
        <span>Contains:</span>
      </li>
      {allergens.map((requirement, index) => {
        const key = requirement.requirementId;
        const isLastItem = index === allergens.length - 1;

        return (
          <li key={key} className="menu__allergens__list__item">
            {requirement.name}
            {isLastItem ? '' : ','}
          </li>
        );
      })}
    </ul>
  </div>
);

export default MenuAllergens;
