import groupBy from 'lodash.groupby';

const getGroupedCartItemsByPriority = (cartItems = [], priorities = []) => {
  const hasPriorities = !!priorities.length;

  if (!hasPriorities) {
    return [
      {
        items: cartItems,
      },
    ];
  }

  const otherPriorityCartItems = cartItems.filter(
    ({ priority, type }) => !priority && type !== 'DRINK',
  );
  const drinksPriorityCartItems = cartItems.filter(
    ({ type, priority }) => type === 'DRINK' && !priority,
  );
  const priorityCartItems = cartItems.filter(({ priority }) => priority);
  const groupedPriorityCartItems = groupBy(priorityCartItems, 'priority');
  const finalGroupedPriorityCartItems = Object.keys(groupedPriorityCartItems).map((priority) => ({
    priority,
    items: groupedPriorityCartItems[priority],
  }));
  const finalGroupedDrinksPriorityCartItems = {
    priority: 'DRINKS',
    items: drinksPriorityCartItems,
  };
  const finalGroupedOtherPriorityCartItems = {
    priority: 'OTHER',
    items: otherPriorityCartItems,
  };
  const orderedCombinedPriorityCartItems = [
    finalGroupedDrinksPriorityCartItems,
    ...finalGroupedPriorityCartItems,
    finalGroupedOtherPriorityCartItems,
  ];
  const filteredOrderedCombinedPriorityCartItems = orderedCombinedPriorityCartItems.filter(
    ({ items }) => !!items.length,
  );

  return filteredOrderedCombinedPriorityCartItems;
};

export default getGroupedCartItemsByPriority;
