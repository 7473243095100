import React, { useCallback, useEffect, useState } from 'react';

import cySelectors from '../../tests/cySelectors';

const Swiper = ({ onSwiped = () => {}, setSwipingStatus = () => {} }) => {
  const [swiperProgress, setSwiperProgress] = useState(0);
  const [isSwiped, setIsSwiped] = useState(false);
  const [hasAttempted, setHasAttempted] = useState(false);

  const handleOnInput = (event) => {
    setSwiperProgress(event.target.value);
  };

  const handleIsSwiping = useCallback(
    (status) => {
      setSwipingStatus(status);
    },
    [setSwipingStatus],
  );

  const handleResetProgress = useCallback(() => {
    setSwiperProgress(0);
  }, []);

  const handleResetSwiper = useCallback(() => {
    handleResetProgress();
    handleIsSwiping(false);
  }, [handleResetProgress, handleIsSwiping]);

  const handleOnStartSwiping = () => {
    handleIsSwiping(true);
    setHasAttempted(false);
  };

  const handleOnSwiped = useCallback(() => {
    (async () => {
      setHasAttempted(true);
      const response = await onSwiped();
      const isError = response.status >= 400;

      if (isError) {
        handleResetSwiper();
      }
    })();
  }, [handleResetSwiper, onSwiped]);

  const handleOnReleaseSwiping = () => {
    if (isSwiped && !hasAttempted) {
      handleOnSwiped();
    } else {
      handleResetSwiper();
    }
  };

  useEffect(() => {
    const isSwiperProgressFull = swiperProgress === '100';

    setIsSwiped(isSwiperProgressFull);
  }, [swiperProgress]);

  return (
    <div className="swiper">
      <input
        data-cy={cySelectors.COLLECTION_SWIPER_KNOB}
        type="range"
        min="0"
        max="100"
        value={swiperProgress}
        onInput={handleOnInput}
        onTouchStart={handleOnStartSwiping}
        onMouseDown={handleOnStartSwiping}
        onTouchEnd={handleOnReleaseSwiping}
        onMouseUp={handleOnReleaseSwiping}
      />
    </div>
  );
};

export default Swiper;
