import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';

import ChoiceInput from '../../../components/ChoiceInput';
import choiceInputTypes from '../../../components/ChoiceInput/choiceInputTypes';

import formatCurrency from '../../../utils/formatCurrency';

const Charge = ({ handleToggleCharge, chargeData, isChargeEnabled, dataCy }) => {
  const { charge, label, subtotal, rate, type } = chargeData;
  const isChargeMandatory = charge === 'MANDATORY';
  const isAmount = type === 'AMOUNT';
  const finalLabel = label || 'Service Charge';
  const formattedChargeSubtotal = formatCurrency(subtotal);
  const id = useMemo(() => nanoid(), []);

  const handleChange = ({ target }) => {
    const { checked } = target;

    handleToggleCharge(checked);
  };

  return (
    <div className="charge">
      <label htmlFor={id}>
        {!isChargeMandatory && (
          <ChoiceInput
            type={choiceInputTypes.CHECKBOX}
            name={id}
            checked={isChargeEnabled}
            isSmall
            onChange={handleChange}
            data-cy={dataCy}
          />
        )}
        <span className="charge__name">
          {finalLabel} {!isAmount && `(${rate}%)`}
        </span>
        <div className="charge__subtotal">{formattedChargeSubtotal}</div>
      </label>
    </div>
  );
};

export default Charge;
