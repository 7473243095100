import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import ChoiceInput from '../../../components/ChoiceInput';
import choiceInputTypes from '../../../components/ChoiceInput/choiceInputTypes';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';
import Button from '../../../components/Button';

const MenuModalModifierCheckbox = ({ modifier, modifierIndex, handleSetModifiersTotal }) => {
  const { register } = useFormContext();
  const { label, modifierId, modifierItems, minSelections, maxSelections } = modifier;
  const [selected, setSelected] = useState([]);

  const minHint = minSelections ? `At least ${minSelections}` : '';
  const maxHint = maxSelections ? `No more than ${maxSelections}` : '';
  const exactHint = minSelections === maxSelections ? `Exactly ${minSelections} selections` : false;
  const modifierHint =
    exactHint || `${minHint}${minSelections && maxSelections ? ', ' : ''}${maxHint}`;

  const getCumulativeQuantities = useCallback(() => {
    const quantities = selected.map((item) => item.quantity);
    return quantities.reduce((a, b) => a + b, 0);
  }, [selected]);

  const maxReached = useCallback(() => {
    const totalQuantities = getCumulativeQuantities();
    return totalQuantities >= maxSelections;
  }, [getCumulativeQuantities, maxSelections]);

  const handleOnChange = (itemId) => {
    setSelected((prevState) => {
      // https://stackoverflow.com/questions/62769684/setstate-of-usestate-hook-occurs-twice-by-one-call
      const newState = JSON.parse(JSON.stringify(prevState));
      const newItem = { modifierItemId: itemId, quantity: 1 };
      const itemFound = newState.find((item) => item.modifierItemId === itemId);

      if (itemFound) {
        return newState.filter((item) => item.modifierItemId !== itemId);
      }

      return [...newState, newItem];
    });
  };

  const handleDecreaseQuantity = (itemId) => {
    const itemFound = selected.find((item) => item.modifierItemId === itemId);
    if (itemFound.quantity > 1) {
      setSelected((prevState) => {
        // https://stackoverflow.com/questions/62769684/setstate-of-usestate-hook-occurs-twice-by-one-call
        const newState = JSON.parse(JSON.stringify(prevState));
        const itemIndex = newState.findIndex((item) => item.modifierItemId === itemId);
        newState[itemIndex].quantity -= 1;
        return newState;
      });
    }
  };

  const handleIncreaseQuantity = (itemId, multiMax) => {
    const itemFound = selected.find((item) => item.modifierItemId === itemId);
    const totalQuantities = getCumulativeQuantities();
    if (itemFound.quantity < multiMax && totalQuantities < maxSelections) {
      setSelected((prevState) => {
        // https://stackoverflow.com/questions/62769684/setstate-of-usestate-hook-occurs-twice-by-one-call
        const newState = JSON.parse(JSON.stringify(prevState));
        const itemIndex = newState.findIndex((item) => item.modifierItemId === itemId);
        newState[itemIndex].quantity += 1;
        return newState;
      });
    }
  };

  const getItemQuantity = useCallback(
    (itemId) => {
      const itemFound = selected.find((item) => item.modifierItemId === itemId);
      return itemFound ? itemFound.quantity : 0;
    },
    [selected],
  );

  const getCheckedItemStatus = useCallback(
    (itemId) => selected.find((item) => item.modifierItemId === itemId),
    [selected],
  );

  useEffect(() => {
    handleSetModifiersTotal(modifierId, modifierItems, selected);
  }, [handleSetModifiersTotal, modifierId, modifierItems, selected]);

  return (
    <div className="item__modal__modifier" data-cy={cySelectors.MODIFIERS_CHECKBOXES}>
      <fieldset>
        <legend>{label}</legend>
        {modifierHint && <p className="item__modal__modifier__hint">{modifierHint}</p>}
        <input
          type="hidden"
          name={`modifiers.${modifierIndex}.modifierId`}
          {...register(`modifiers.${modifierIndex}.modifierId`)}
          value={modifierId}
        />

        {modifierItems.map(({ itemName, itemPrice, label: itemLabel, itemId, multiMax }, index) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const {
            ref: innerRef,
            onChange,
            name,
            ...registerProps
          } = register(`modifiers.${modifierIndex}.modifierItems.${index}`);
          const formattedPrice = formatCurrency(itemPrice);
          const canChangeQuantity = multiMax > 1 && getCheckedItemStatus(itemId);

          return (
            <div key={itemName} className="item__modal__modifier__choice">
              <label htmlFor={name}>
                <span className="item__modal__modifier__choice__name">{itemLabel || itemName}</span>
                {canChangeQuantity && (
                  <div className="item__modal__modifier__choice__quantities">
                    <Button
                      disabled={getItemQuantity(itemId) <= 1}
                      small
                      moreRounded
                      onClick={() => {
                        handleDecreaseQuantity(itemId);
                      }}
                      data-cy={cySelectors.CART_ITEM_DECREASE_BTN}
                    >
                      -
                    </Button>
                    <span
                      className="item__modal__modifier__choice__count"
                      data-cy={cySelectors.CART_ITEM_COUNT}
                    >
                      {getItemQuantity(itemId)}
                    </span>

                    <Button
                      disabled={maxReached() || getItemQuantity(itemId) >= multiMax}
                      small
                      moreRounded
                      onClick={() => {
                        handleIncreaseQuantity(itemId, multiMax);
                      }}
                      data-cy={cySelectors.CART_ITEM_INCREASE_BTN}
                    >
                      +
                    </Button>
                  </div>
                )}
                {!!itemPrice && (
                  <span className="item__modal__modifier__choice__price">+{formattedPrice}</span>
                )}

                <ChoiceInput
                  type={choiceInputTypes.CHECKBOX}
                  name={name}
                  disabled={maxReached() && !getCheckedItemStatus(itemId)}
                  value={itemId}
                  checked={getCheckedItemStatus(itemId)}
                  innerRef={innerRef}
                  data-cy={cySelectors.MODIFIERS_CHECKBOX}
                  {...registerProps}
                  onChange={(event) => {
                    onChange(event);
                    handleOnChange(itemId);
                  }}
                />
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalModifierCheckbox;
