import React from 'react';
import { toast } from 'react-toastify';

import { ReactComponent as InfoIcon } from '../assets/images/info-icon.svg';
import { ReactComponent as SuccessIcon } from '../assets/images/success-icon.svg';
import { ReactComponent as WarningIcon } from '../assets/images/warning-icon.svg';
import { ReactComponent as ErrorIcon } from '../assets/images/error-icon.svg';
import { ReactComponent as CloseIcon } from '../assets/images/close-icon.svg';

const notifyIcon = {
  [toast.TYPE.INFO]: InfoIcon,
  [toast.TYPE.SUCCESS]: SuccessIcon,
  [toast.TYPE.WARNING]: WarningIcon,
  [toast.TYPE.ERROR]: ErrorIcon,
  [toast.TYPE.DEFAULT]: false,
};

const CloseButton = () => (
  <div className="notification__close-btn">
    <CloseIcon />
  </div>
);

const Notify = ({ heading, text }) => (
  <>
    {heading && <strong className="notification__heading">{heading}</strong>}
    <div className="notification__text">{text}</div>
  </>
);

const notify = ({ text, heading, type = toast.TYPE.DEFAULT }) => {
  const Icon = notifyIcon[type];
  const options = {
    type,
    icon: !notifyIcon[type] ? false : <Icon />,
  };

  return toast(<Notify heading={heading} text={text} />, options);
};

const initNotifications = () =>
  toast.configure({
    limit: 1,
    className: 'notification',
    hideProgressBar: true,
    position: toast.POSITION.TOP_CENTER,
    closeButton: () => <CloseButton />,
  });

export { notify, initNotifications };
