import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../components/LoadingSpinner';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import saveTableNameToLocalStorage from './saveTableNameToLocalStorage';

const Venue = () => {
  const params = useParams();
  const { tableName } = params;

  useEffect(() => {
    if (tableName) {
      saveTableNameToLocalStorage(tableName);
    }
  }, [tableName]);

  return <LoadingSpinner center />;
};

export default withHandleRoutesDispatch(Venue);
