const checkImageExists = (url) =>
  new Promise((resolve) => {
    const img = new Image();
    img.src = url;

    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });

const setPersonalisationCssVariables = async (personalisation, vendorId) => {
  const { colour = '', font = '', bannerImgUrl = '', logoImgUrl = '' } = personalisation;
  const { REACT_APP_ASSETS_URL } = process.env;
  const defaultBannerImgPath = '/personalisation/banner.jpg';
  const multiVendorBannerImg = `${REACT_APP_ASSETS_URL}/${vendorId}${defaultBannerImgPath}`;

  const isMultiVendorBannerImgAvailable =
    vendorId && (await checkImageExists(multiVendorBannerImg));
  const bannerImg = isMultiVendorBannerImgAvailable ? multiVendorBannerImg : bannerImgUrl;

  const documentStyle = document.documentElement.style;

  documentStyle.setProperty('--su-colour', colour);
  documentStyle.setProperty('--su-banner-img-url', `url('${bannerImg}')`);
  documentStyle.setProperty('--su-logo-img-url', logoImgUrl);
  documentStyle.setProperty('--su-font-family', font);

  return isMultiVendorBannerImgAvailable;
};

export default setPersonalisationCssVariables;
