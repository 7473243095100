import React from 'react';
import clsx from 'clsx';

const HeroBanner = ({ text }) => {
  const isLongText = text.length >= 35;
  const classes = clsx('hero-banner', {
    'hero-banner--long-text': isLongText,
  });

  return (
    <div className={classes}>
      <h1 className="hero-banner__text">{text}</h1>
      <div className="hero-banner__img" title={text} />
    </div>
  );
};

export default HeroBanner;
