import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useQuery from '../../hooks/useQuery';

import LoadingSpinner from '../../components/LoadingSpinner';
import FooterCta from '../../components/FooterCta';

import ConfirmationDetails from './ConfirmationDetails';

import { ReactComponent as CheckIcon } from '../../assets/images/check-circle.svg';
import PromotionsModal from '../../components/PromotionsModal';
import AppContext from '../../components/App/AppContext';
import VoucherCTA from '../../components/VoucherCTA';
import LeaveReviewCTA from '../../components/LeaveReviewCTA';
import CartItems from '../../components/CartItems';

import { fetchOrder } from '../../store/slices/order';
import { fetchPromotions } from '../../store/slices/promotions';

import cySelectors from '../../tests/cySelectors';

const Confirmation = ({ isVouchers = false }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: promotionsData, loading: promotionsLoading } = useSelector(
    (state) => state.promotions,
  );
  const { venueName, cart: orderCart, acceptedAt } = orderData || {};
  const {
    vouchersEnabled,
    ratingsEnabled,
    isMultiMenuVenue,
    isHardwarelessCollectionEnabled,
    priorities,
    vendors,
  } = venueData || {};
  const { orderId, venueId } = useParams();
  const queries = useQuery();
  const history = useHistory();
  const { isPromotionModalOpen, setIsPromotionModalOpen } = useContext(AppContext);
  const dispatch = useDispatch();
  const isCollectionConfirmation = queries.get('isCollection') === 'true';
  const isLeaveReviewCtaVisible =
    (!queries.get('isLeaveReviewCtaVisible') ||
      queries.get('isLeaveReviewCtaVisible') !== 'false') &&
    ratingsEnabled;

  const acceptedAtTime = acceptedAt
    ? new Date(acceptedAt).toLocaleTimeString('en-Gb', {
        hour12: false,
        hour: '2-digit',
        minute: '2-digit',
      })
    : null;
  const { phone } = orderData || {};
  const hasPromotionsData = !!promotionsData?.length;
  const isVoucherCTAVisible = vouchersEnabled && !isVouchers;

  const handleNavigateToMenuPage = useCallback(() => {
    const getNewOrderPath = () => {
      switch (true) {
        case isMultiMenuVenue:
          return 'vendors';
        case isVouchers:
          return 'vouchers';
        default:
          return 'menu';
      }
    };

    const newOrderPath = getNewOrderPath();
    const newOrderPageRoute = `/${newOrderPath}/${venueId}`;

    window.location.pathname = newOrderPageRoute;
  }, [isMultiMenuVenue, isVouchers, venueId]);

  const handleNavigateToCollectionPage = useCallback(() => {
    history.push(`/collection/${venueId}/${orderId}`);
  }, [history, orderId, venueId]);

  const handleFetchPromotions = useCallback(() => {
    dispatch(
      fetchPromotions({
        params: orderId,
        payload: { phone, venueId },
        query: {
          isRedeemed: false,
        },
      }),
    );
  }, [dispatch, orderId, phone, venueId]);

  const handleFetchOrder = useCallback(() => {
    dispatch(fetchOrder({ params: orderId, query: { venueId } }));
  }, [dispatch, orderId, venueId]);

  const handleToggleCollapsed = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed]);

  const handleOnSetRating = useCallback(
    (rating) => {
      history.push(`/rating/${venueId}/${orderId}?rating=${rating}`);
    },
    [history, orderId, venueId],
  );

  useEffect(() => {
    if (hasPromotionsData) {
      setIsPromotionModalOpen(true);
    }
  }, [hasPromotionsData, setIsPromotionModalOpen]);

  useEffect(() => {
    handleFetchOrder();
  }, [handleFetchOrder]);

  useEffect(() => {
    if (!isVouchers && venueData) {
      handleFetchPromotions();
    }
  }, [handleFetchPromotions, isVouchers, venueData]);

  return orderData ? (
    <div className="confirmation">
      <div className="confirmation__message">
        {!isCollectionConfirmation ? (
          <>
            <CheckIcon title="success" />
            <h2 className="confirmation__message__title">Payment Complete</h2>
            <p className="confirmation__message__text">
              You should receive an email when this order is confirmed by venue staff.
            </p>
          </>
        ) : (
          <h2 className="confirmation__message__title">Order Claimed at {acceptedAtTime} </h2>
        )}
      </div>
      <div className="confirmation__receipt">
        <h1>You ordered</h1>
        <CartItems items={orderCart} vendors={vendors} priorities={priorities} />
        <ConfirmationDetails
          handleToggleCollapsed={handleToggleCollapsed}
          isCollapsed={isCollapsed}
        />
      </div>
      {!isHardwarelessCollectionEnabled || isCollectionConfirmation ? (
        <FooterCta textCenter data-cy={cySelectors.SUBMIT_BTN} onClick={handleNavigateToMenuPage}>
          Start new order
        </FooterCta>
      ) : (
        <FooterCta
          textCenter
          data-cy={cySelectors.SUBMIT_BTN}
          onClick={handleNavigateToCollectionPage}
        >
          Collect my order
        </FooterCta>
      )}
      {hasPromotionsData && !promotionsLoading && (
        <PromotionsModal
          title="Your promotions!"
          promotions={promotionsData}
          isModalOpen={isPromotionModalOpen}
          setIsModalOpen={setIsPromotionModalOpen}
        />
      )}
      {!isHardwarelessCollectionEnabled && (
        <>
          {isLeaveReviewCtaVisible && (
            <LeaveReviewCTA venueName={venueName} handleOnSetRating={handleOnSetRating} />
          )}
          {isVoucherCTAVisible && <VoucherCTA venueName={venueName} venueId={venueId} />}
        </>
      )}
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Confirmation);
