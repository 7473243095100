import React, { useCallback, useEffect, useContext } from 'react';
import { useParams, useHistory, useLocation, matchPath } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useAsyncServedUpError from '../hooks/useAsyncServedUpError';
import useQuery from '../hooks/useQuery';
import useScrollToTop from '../hooks/useScrollToTop';

import AppContext from '../components/App/AppContext';

import { fetchVenue } from '../store/slices/venue';
import { fetchPickupTimes } from '../store/slices/pickup-times';
import { createOrder, fetchOrder } from '../store/slices/order';
import { fetchDietaryRequirements } from '../store/slices/dietary-requirements';
import { updateCart } from '../store/slices/cart';
import { fetchMenu } from '../store/slices/menu';
import { fetchTables } from '../store/slices/tables';
import { createCheckoutPaidUpConfirmRequest } from '../store/slices/checkout';
import { fetchUser } from '../store/slices/user';
import { fetchPromotions } from '../store/slices/promotions';

import getDetailsFormFromLocalStorage from '../utils/getDetailsFormFromLocalStorage';
import getSanitisedPhoneWithCountryCode from '../utils/getSanitisedPhoneWithCountryCode';
import getCurrentMenuVenueId from '../utils/getCurrentMenuVenueId';
import ServedUpError from '../utils/ServedUpError';
import { errorCodes } from '../utils/errorCodes';

import { fetchVouchers } from '../store/slices/vouchers';
import { getPaidUpOrder } from '../store/slices/paidup';

/**
 * A HOC that handles the network requests during routing for the app with the main purpose of
 * fetching the data needed for the current route avoiding overfetching.
 * It also handles the redirections to the correct route and full page error handling.
 *
 * Wrap the necessary pages with this component to handle the routing or the whole app.
 *
 * @param {JSX.Element} Component
 *
 * @returns  {JSX.Element}
 *
 */

const withHandleRoutesDispatch = (Component) => (props) => {
  const { venuePath, orderId, venueId } = useParams();
  const { pathname } = useLocation();
  const queries = useQuery();
  const stringifiedQueries = `?${queries.toString()}`;
  const history = useHistory();
  const dispatch = useDispatch();
  const throwError = useAsyncServedUpError();

  useScrollToTop(pathname);

  // RW: Useful for debugging routing issues.
  // useEffect(() => {
  //   history.listen((location) => {
  //     console.log(`You changed the page to: ${location.pathname}`);
  //   });
  // }, [history]);

  const { setIsContainedAppLayout } = useContext(AppContext);
  const {
    data: venueData,
    loading: venueLoading,
    error: venueError,
  } = useSelector((state) => state.venue);
  const {
    data: orderData,
    loading: orderLoading,
    error: orderError,
  } = useSelector((state) => state.order);
  const {
    data: userData,
    loading: userLoading,
    error: userError,
  } = useSelector((state) => state.user);
  const {
    data: menuData,
    loading: menuLoading,
    error: menuError,
  } = useSelector((state) => state.menu);
  const {
    data: vouchersData,
    loading: vouchersLoading,
    error: vouchersError,
  } = useSelector((state) => state.vouchers);
  const {
    data: tablesData,
    loading: tablesLoading,
    error: tablesError,
  } = useSelector((state) => state.tables);
  const {
    data: checkoutData,
    loading: checkoutLoading,
    error: checkoutError,
  } = useSelector((state) => state.checkout);
  const { loading: paidupLoading, error: paidupError } = useSelector((state) => state.paidup);
  const {
    data: pickupTimesData,
    loading: pickupTimesLoading,
    error: pickupTimesError,
  } = useSelector((state) => state.pickupTimes);
  const {
    data: promotionsData,
    loading: promotionsLoading,
    error: promotionsError,
  } = useSelector((state) => state.promotions);
  const {
    data: dietaryRequirementsData,
    loading: dietaryRequirementsLoading,
    error: dietaryRequirementsError,
  } = useSelector((state) => state.dietaryRequirements);

  const { phone, isUserDetailsEditable } = orderData || {};
  const cachedUserDetails = getDetailsFormFromLocalStorage();
  const phoneWithCountryCodeFromCache = getSanitisedPhoneWithCountryCode(
    cachedUserDetails.phone,
    cachedUserDetails.countryCode?.value,
  );

  const isVendorsViewOnlyRoute = matchPath(pathname, {
    path: '/vendors/:venueId/menu-view',
    exact: true,
    strict: true,
  });

  const isVendorsRoute =
    !isVendorsViewOnlyRoute &&
    matchPath(pathname, {
      path: '/vendors/:venueId/:orderId?',
      exact: true,
      strict: true,
    });

  const isReviewRoute = matchPath(pathname, {
    path: '/review/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isVouchersReviewRoute = matchPath(pathname, {
    path: '/vouchers/review/:venueId/:orderId',
    exact: true,
    strict: true,
  });

  const isMenuRoute = matchPath(pathname, {
    path: '/menu/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  const isCheckoutRoute = matchPath(pathname, {
    path: '/(checkout|checkout-epos)/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isDetailsRoute = matchPath(pathname, {
    path: '/details/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isConfirmationRoute = matchPath(pathname, {
    path: '/confirmation/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  const isConfirmationPaidUpRoute = matchPath(pathname, {
    path: '/confirmation/paidup/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  const isVoucherDetailsRoute = matchPath(pathname, {
    path: '/vouchers/details/:venueId/:orderId',
    exact: true,
    strict: true,
  });
  const isVouchersListRoute = matchPath(pathname, {
    path: '/vouchers/list/:orderId',
    exact: true,
    strict: true,
  });
  const isVouchersRoute =
    !isVouchersListRoute &&
    matchPath(pathname, {
      path: '/vouchers/:venueId/:orderId?',
      exact: true,
      strict: true,
    });
  const isVouchersParentRoute = matchPath(pathname, {
    path: '/vouchers/**',
    exact: true,
    strict: true,
  });
  const isVouchersItemRoute = matchPath(pathname, {
    path: '/vouchers/list/:orderId/:voucherCode',
    exact: true,
    strict: true,
  });
  const isVouchersCheckoutRoute = matchPath(pathname, {
    path: '/vouchers/checkout/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  const isRatingRoute = matchPath(pathname, {
    path: '/rating/:venueId/:orderId?',
    exact: true,
    strict: true,
  });
  // const isCollectionRoute = matchPath(pathname, {
  //   path: '/collection/:venueId/:orderId?',
  //   exact: true,
  //   strict: true,
  // });
  const isCollectionConfirmationRoute = matchPath(pathname, {
    path: '/collection-confirmation/:venueId/:orderId?',
    exact: true,
    strict: true,
  });

  const isMenuViewPath = new RegExp(/\/menu-view$/).test(pathname);
  const isPathNameMenuRoute = new RegExp(/^\/menu\//).test(pathname);
  const {
    isOpen,
    isClosedToday,
    nextOpeningTime,
    nextOpeningDay,
    isTakeawayEnabled,
    openTime,
    vouchersEnabled,
    isMultiMenuVenue,
    vendors,
    isQrEnabled,
    upsells: upsellsStatus = {},
  } = venueData || {};
  const isBasketUpsellsEnabled = upsellsStatus.checkout;
  const vendorIdQuery = parseInt(queries.get('vendorId'), 10) || null;
  const isMenuViewQuery = queries.get('isMenuView');
  const finalMenuVenueId = getCurrentMenuVenueId(vendors, vendorIdQuery, venueId, isMultiMenuVenue);
  const isQrDisabled = !isMenuViewPath && !isMenuViewQuery && !isQrEnabled;
  const isClosed =
    !isVouchersParentRoute &&
    !isMenuViewPath &&
    !isMenuViewQuery &&
    !isOpen &&
    !isTakeawayEnabled &&
    isClosedToday;
  const isOpeningLaterToday =
    !isMenuViewPath && !isMenuViewQuery && !isClosedToday && !isOpen && !isTakeawayEnabled;

  const isVenueRoute =
    !isPathNameMenuRoute &&
    !isVouchersRoute &&
    !isVendorsRoute &&
    !isVendorsViewOnlyRoute &&
    matchPath(pathname, {
      path: '/:venuePath/:tableName?',
      exact: true,
      strict: true,
    });

  const handleErrors = useCallback(() => {
    switch (true) {
      case !!menuError:
        throwError({ error: menuError, venueId, orderId });
        break;
      case !!orderError:
        throwError({ error: orderError, venueId, orderId });
        break;
      case !!venueError:
        throwError({ error: venueError, venueId, orderId });
        break;
      case !!tablesError:
        throwError({ error: tablesError, venueId, orderId });
        break;
      case !!pickupTimesError:
        throwError({ error: pickupTimesError, venueId, orderId });
        break;
      case !!checkoutError:
        throwError({ error: checkoutError, venueId, orderId });
        break;
      case !!paidupError:
        throwError({ error: paidupError, venueId, orderId });
        break;
      default:
        break;
    }
  }, [
    menuError,
    orderError,
    venueError,
    tablesError,
    checkoutError,
    paidupError,
    pickupTimesError,
    venueId,
    orderId,
    throwError,
  ]);

  const handleVenueErrors = useCallback(() => {
    const errorData = {
      nextOpeningTime,
      nextOpeningDay,
      openTime,
      venueId,
      venuePath: venueData.venuePath || null,
      venueName: venueData.name || null,
    };
    switch (true) {
      case isQrDisabled:
        throw new ServedUpError({
          error: {
            message: errorCodes.QR_DISABLED,
            errorData,
          },
          venueId,
          orderId,
        });
      case isClosed:
        throw new ServedUpError({
          error: {
            message: errorCodes.CLOSED,
            errorData,
          },
          venueId,
          orderId,
        });
      case isOpeningLaterToday:
        throw new ServedUpError({
          error: {
            message: errorCodes.CLOSED_OPENING_LATER_TODAY,
            errorData,
          },
          venueId,
          orderId,
        });
      default:
        return null;
    }
  }, [
    nextOpeningTime,
    nextOpeningDay,
    openTime,
    venueId,
    venueData,
    isQrDisabled,
    orderId,
    isClosed,
    isOpeningLaterToday,
  ]);

  const handleVouchersNotEnabledError = useCallback(() => {
    throw new ServedUpError({
      error: {
        message: errorCodes.VOUCHERS_NOT_ENABLED,
      },
      venueId,
      orderId,
    });
  }, [orderId, venueId]);

  const handleFetchOrder = useCallback(() => {
    if (!orderData && !orderLoading) {
      dispatch(fetchOrder({ params: orderId, query: { venueId } }));
    }
  }, [dispatch, orderData, orderId, orderLoading, venueId]);

  const handleFetchVenueFromId = useCallback(() => {
    if (!venueData && !venueLoading && venueId) {
      dispatch(fetchVenue({ params: venueId }));
    }
  }, [dispatch, venueData, venueId, venueLoading]);

  const handleFetchPaidUpStatusFromId = useCallback(async () => {
    if (!paidupLoading && orderId) {
      const { status } = await dispatch(
        getPaidUpOrder({ params: orderId, query: { venueId } }),
      ).unwrap();
      if (status === 'pending') {
        requestAnimationFrame(handleFetchPaidUpStatusFromId);
      }
    }
  }, [dispatch, orderId, paidupLoading, venueId]);

  const handleConfirmPaidupOrder = useCallback(async () => {
    if (!checkoutData && !checkoutLoading && orderId && venueId) {
      dispatch(createCheckoutPaidUpConfirmRequest({ params: orderId, payload: { venueId } }));
    }
  }, [dispatch, checkoutData, checkoutLoading, orderId, venueId]);

  const handleFetchUser = useCallback(() => {
    dispatch(fetchUser({ params: phoneWithCountryCodeFromCache }));
  }, [dispatch, phoneWithCountryCodeFromCache]);

  const handleFetchVenueFromPath = useCallback(() => {
    if (!venueData && !venueLoading && venuePath) {
      dispatch(fetchVenue({ params: venuePath }));
    }
  }, [dispatch, venueData, venueLoading, venuePath]);

  const handleFetchTables = useCallback(() => {
    if (!tablesData && !tablesLoading) {
      dispatch(fetchTables({ params: venueId }));
    }
  }, [dispatch, tablesData, tablesLoading, venueId]);

  const handleFetchPickupTimes = useCallback(() => {
    if (!pickupTimesData && !pickupTimesLoading) {
      dispatch(fetchPickupTimes({ params: venueId }));
    }
  }, [dispatch, pickupTimesData, pickupTimesLoading, venueId]);

  const handleCreateOrder = useCallback(() => {
    if (!orderData && !orderLoading && venueId) {
      dispatch(createOrder({ payload: { venueId } }));
    }
  }, [dispatch, orderData, orderLoading, venueId]);

  const handleVendorsRoute = useCallback(() => {
    if (!venueData) {
      handleFetchVenueFromId();
    }
    if (orderId) {
      handleFetchOrder();
    }
    if (!orderId) {
      handleCreateOrder();
    }
    if (venueData && orderData && !orderId) {
      history.replace(`/vendors/${venueData.venueId}/${orderData.orderId}${stringifiedQueries}`);
    }
  }, [
    handleCreateOrder,
    handleFetchOrder,
    handleFetchVenueFromId,
    history,
    orderData,
    orderId,
    stringifiedQueries,
    venueData,
  ]);

  const handleVendorsViewOnlyRoute = useCallback(() => {
    if (orderId) {
      handleFetchOrder();
    }
    if (!orderId) {
      handleCreateOrder();
    }
    if (!venueData) {
      handleFetchVenueFromId();
    }
  }, [handleCreateOrder, handleFetchOrder, handleFetchVenueFromId, orderId, venueData]);

  const handleFetchMenu = useCallback(() => {
    if (venueData && !menuData && !menuLoading && venueId && !vendorIdQuery) {
      if (venuePath) {
        dispatch(fetchMenu({ params: venuePath }));
      }
      dispatch(fetchMenu({ params: finalMenuVenueId }));
    }
  }, [
    dispatch,
    finalMenuVenueId,
    menuData,
    menuLoading,
    vendorIdQuery,
    venueData,
    venueId,
    venuePath,
  ]);

  const handleReviewRoute = useCallback(() => {
    if (!orderData) {
      handleFetchOrder();
    }

    if (!venueData) {
      handleFetchVenueFromId();
    }

    if (isBasketUpsellsEnabled) {
      handleFetchMenu();
    }
  }, [
    handleFetchMenu,
    handleFetchOrder,
    handleFetchVenueFromId,
    isBasketUpsellsEnabled,
    orderData,
    venueData,
  ]);

  const handleFetchPromotions = useCallback(
    ({ isRedeemed }) => {
      if (!promotionsData && !promotionsLoading && !promotionsError && orderData) {
        dispatch(
          fetchPromotions({
            params: orderId,
            payload: { phone, venueId },
            query: {
              isRedeemed,
            },
          }),
        );
      }
    },
    [
      dispatch,
      orderData,
      orderId,
      phone,
      promotionsData,
      promotionsError,
      promotionsLoading,
      venueId,
    ],
  );

  const handleRedirectIfNotEditable = useCallback(() => {
    if (!isUserDetailsEditable) {
      history.replace(`/checkout/${venueId}/${orderId}`);
    }
  }, [history, isUserDetailsEditable, orderId, venueId]);

  const handleVenueRoute = useCallback(() => {
    handleFetchVenueFromPath();

    if (venueData) {
      const menuViewQuery = isMenuViewPath ? '?isMenuView=true' : '';
      history.replace(`/menu/${venueData.venueId}${menuViewQuery}`);
    }
  }, [handleFetchVenueFromPath, history, isMenuViewPath, venueData]);

  const handleFetchDietaryRequirements = useCallback(() => {
    if (!dietaryRequirementsData && !dietaryRequirementsLoading && !dietaryRequirementsError) {
      dispatch(fetchDietaryRequirements());
    }
  }, [dietaryRequirementsData, dietaryRequirementsError, dietaryRequirementsLoading, dispatch]);

  const handleMenuRoute = useCallback(() => {
    if (venueId) {
      handleFetchVenueFromId();
    }
    handleFetchMenu();
    handleFetchDietaryRequirements();

    if (orderId) {
      handleFetchOrder();
    } else {
      handleCreateOrder();
    }
    if (venueData && orderData && !orderId) {
      history.replace(`/menu/${venueData.venueId}/${orderData.orderId}${stringifiedQueries}`);
    }
  }, [
    venueId,
    handleFetchMenu,
    handleFetchDietaryRequirements,
    orderId,
    venueData,
    orderData,
    handleFetchVenueFromId,
    handleFetchOrder,
    handleCreateOrder,
    history,
    stringifiedQueries,
  ]);

  const handleCheckoutRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();

    if (isTakeawayEnabled) {
      handleFetchPickupTimes();
    }

    handleFetchPromotions({ isRedeemed: true });
  }, [
    handleFetchOrder,
    handleFetchPickupTimes,
    handleFetchPromotions,
    handleFetchVenueFromId,
    isTakeawayEnabled,
  ]);

  const handleConfirmationRoute = useCallback(() => {
    handleFetchVenueFromId();
  }, [handleFetchVenueFromId]);

  const handleConfirmationPaidUpRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchPaidUpStatusFromId();
    handleConfirmPaidupOrder();
  }, [handleConfirmPaidupOrder, handleFetchOrder, handleFetchPaidUpStatusFromId]);

  const handleVoucherCheckoutRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();
  }, [handleFetchOrder, handleFetchVenueFromId]);

  const handleDetailsRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();
    handleFetchTables();

    if (orderData) {
      handleRedirectIfNotEditable();
    }

    if (phone && orderData && !userData && !userLoading && !userError) {
      handleFetchUser();
    }
  }, [
    handleFetchOrder,
    handleFetchTables,
    handleFetchUser,
    handleFetchVenueFromId,
    handleRedirectIfNotEditable,
    orderData,
    phone,
    userData,
    userError,
    userLoading,
  ]);

  const handleVouchersDetailsRoute = useCallback(() => {
    handleFetchOrder();
    handleFetchVenueFromId();

    if (phone && orderData && !userData && !userLoading && !userError) {
      handleFetchUser();
    }
  }, [
    handleFetchOrder,
    handleFetchUser,
    handleFetchVenueFromId,
    orderData,
    phone,
    userData,
    userError,
    userLoading,
  ]);

  const handleVouchersRoute = useCallback(() => {
    handleFetchVenueFromId();

    if (orderId) {
      handleFetchOrder();
    } else {
      handleCreateOrder();
    }

    if (venueData && orderData && !orderId) {
      history.replace(`/vouchers/${venueData.venueId}/${orderData.orderId}`);
    }
  }, [
    handleCreateOrder,
    handleFetchOrder,
    handleFetchVenueFromId,
    history,
    orderData,
    orderId,
    venueData,
  ]);

  const handleCartUpdate = useCallback(() => {
    const { cart = [] } = orderData;
    try {
      dispatch(updateCart(cart));
    } catch (error) {
      throwError({ error, venueId, orderId });
    }
  }, [dispatch, throwError, orderData, venueId, orderId]);

  const handleVouchersListRoute = useCallback(() => {
    if (!vouchersData && !vouchersLoading && !vouchersError) {
      dispatch(fetchVouchers({ params: orderId }));
    }
  }, [dispatch, orderId, vouchersData, vouchersError, vouchersLoading]);

  const handleRatingRoute = useCallback(() => {
    if (!venueData && !venueLoading && !venueError) {
      dispatch(fetchVenue({ params: venueId }));
    }
  }, [dispatch, venueData, venueError, venueId, venueLoading]);

  const handleCollectionConfirmationRoute = useCallback(() => {
    handleFetchVenueFromId();
  }, [handleFetchVenueFromId]);

  useEffect(() => {
    if (isVenueRoute) handleVenueRoute();
  }, [handleVenueRoute, isVenueRoute, pathname, venuePath]);

  useEffect(() => {
    if (isVendorsRoute) handleVendorsRoute();
  }, [handleVendorsRoute, isVendorsRoute]);

  useEffect(() => {
    if (isVendorsViewOnlyRoute) handleVendorsViewOnlyRoute();
  }, [handleVendorsViewOnlyRoute, isVendorsViewOnlyRoute]);

  useEffect(() => {
    if (isReviewRoute || isVouchersReviewRoute) handleReviewRoute();
  }, [handleReviewRoute, isVouchersReviewRoute, isReviewRoute]);

  useEffect(() => {
    if (isMenuRoute) {
      handleMenuRoute();
    }
  }, [handleMenuRoute, isMenuRoute]);

  useEffect(() => {
    if (isCheckoutRoute) {
      handleCheckoutRoute();
    }
  }, [handleCheckoutRoute, isCheckoutRoute]);

  useEffect(() => {
    if (isDetailsRoute) handleDetailsRoute();
  }, [handleDetailsRoute, isDetailsRoute]);

  useEffect(() => {
    if (isVoucherDetailsRoute) handleVouchersDetailsRoute();
  }, [handleVouchersDetailsRoute, isVoucherDetailsRoute]);

  useEffect(() => {
    if (isVouchersCheckoutRoute) handleVoucherCheckoutRoute();
  }, [handleVoucherCheckoutRoute, isVouchersCheckoutRoute]);

  useEffect(() => {
    if (isVouchersRoute) handleVouchersRoute();
  }, [handleVouchersRoute, isVouchersRoute]);

  useEffect(() => {
    if (orderData) handleCartUpdate();
  }, [handleCartUpdate, orderData]);

  useEffect(() => {
    if (isVouchersListRoute || isVouchersItemRoute) handleVouchersListRoute();
  }, [handleVouchersListRoute, isVouchersItemRoute, isVouchersListRoute]);

  useEffect(() => {
    handleErrors();

    if (venueData) {
      handleVenueErrors();
    }
  }, [handleErrors, handleVenueErrors, isMenuRoute, venueData]);

  useEffect(() => {
    if (isConfirmationRoute) handleConfirmationRoute();
  }, [handleConfirmationRoute, isConfirmationRoute]);

  useEffect(() => {
    if (isConfirmationPaidUpRoute) handleConfirmationPaidUpRoute();
  }, [handleConfirmationPaidUpRoute, isConfirmationPaidUpRoute]);

  useEffect(() => {
    const isVouchersEnabledInVouchersRoute = isVouchersParentRoute && venueData && !vouchersEnabled;

    if (isVouchersEnabledInVouchersRoute) {
      handleVouchersNotEnabledError();
    }
  }, [handleVouchersNotEnabledError, isVouchersParentRoute, venueData, vouchersEnabled]);

  useEffect(() => {
    if (isRatingRoute) {
      handleRatingRoute();
    }
  }, [handleRatingRoute, isRatingRoute]);

  useEffect(() => {
    if (isCollectionConfirmationRoute) handleCollectionConfirmationRoute();
  }, [handleCollectionConfirmationRoute, isCollectionConfirmationRoute]);

  useEffect(() => {
    setIsContainedAppLayout(!isMenuRoute && !isVouchersRoute);
  }, [isMenuRoute, isVouchersRoute, setIsContainedAppLayout]);

  return <Component {...props} />;
};

export default withHandleRoutesDispatch;
