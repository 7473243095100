import pickBy from 'lodash.pickby';
import pick from 'lodash.pick';

import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'checkout',
  ENDPOINT: 'orders',
  RESOURCE: 'checkout',
};

const sliceName = sliceArgs.SLICE_NAME;

const createCheckoutRequestPayloadTransformer = (payload) => {
  const { pickupTime, pickupDay, isQrPayment, allowMarketing } = payload;
  const finalAllowMarketing = allowMarketing || allowMarketing === null;

  const updatedPayload = pickBy(
    {
      ...payload,
      pickupTime: pickupTime?.value,
      pickupDay: pickupDay?.value,
      allowMarketing: finalAllowMarketing,
      isQrPayment,
    },
    (value) => value !== undefined,
  );

  return updatedPayload;
};

const createCheckoutRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: sliceArgs.RESOURCE,
  method: 'PATCH',
  payloadTransformer: createCheckoutRequestPayloadTransformer,
});

const createVouchersCheckoutRequestPayloadTransformer = ({ venueId }) => ({ venueId });

const createVouchersCheckoutRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  resource: sliceArgs.RESOURCE,
  method: 'PATCH',
  payloadTransformer: createVouchersCheckoutRequestPayloadTransformer,
});

const createCheckoutComRequestPayloadTransformer = (payload) => {
  const { pickupTime, pickupDay, isQrPayment, allowMarketing } = payload;
  const finalAllowMarketing = allowMarketing || allowMarketing === null;

  const sanitisedPayload = pickBy(
    {
      ...payload,
      pickupTime: pickupTime?.value,
      pickupDay: pickupDay?.value,
      allowMarketing: finalAllowMarketing,
      isQrPayment,
    },
    (value) => value !== undefined,
  );

  return sanitisedPayload;
};

const createCheckoutComRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: `${sliceArgs.RESOURCE}/checkoutcom`,
  method: 'PATCH',
  payloadTransformer: createCheckoutComRequestPayloadTransformer,
});

const createPaidUpRequestPayloadTransformer = (payload) => {
  const { pickupTime, pickupDay, isQrPayment, allowMarketing } = payload;
  const finalAllowMarketing = allowMarketing || allowMarketing === null;

  const sanitisedPayload = pickBy(
    {
      ...payload,
      pickupTime: pickupTime?.value,
      pickupDay: pickupDay?.value,
      allowMarketing: finalAllowMarketing,
      isQrPayment,
    },
    (value) => value !== undefined,
  );

  return sanitisedPayload;
};

const createPaidUpRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: `${sliceArgs.RESOURCE}/paidup`,
  method: 'PATCH',
  payloadTransformer: createPaidUpRequestPayloadTransformer,
});

const createVouchersCheckoutComRequestPayloadTransformer = (payload) =>
  pick(payload, ['venueId', 'tokens', 'enable3ds', 'paymentMethod']);

const createVouchersCheckoutComRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  resource: `${sliceArgs.RESOURCE}/checkoutcom`,
  method: 'PATCH',
  payloadTransformer: createVouchersCheckoutComRequestPayloadTransformer,
});

const createCheckoutComSCAConfirmRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: 'confirm/checkoutcom',
  method: 'POST',
});

const createVouchersCheckoutComSCAConfirmRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  resource: 'confirm/checkoutcom',
  method: 'POST',
});

const createCheckoutConfirmRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: 'confirm',
  method: 'POST',
});

const createCheckoutPaidUpConfirmRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: 'confirm/paidup',
  method: 'POST',
});

const createVouchersCheckoutConfirmRequest = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  resource: 'confirm',
  method: 'POST',
});

const transformCheckoutComGooglePayTokenPayload = (payload) => {
  const parsedToken = JSON.parse(payload);

  const updatedPayload = {
    type: 'googlepay',
    token_data: parsedToken,
  };

  return updatedPayload;
};

const fetchCheckoutComGooglePayToken = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  externalEndpoint: process.env.REACT_APP_CHECKOUT_COM_API_TOKEN_ENDPOINT,
  method: 'POST',
  payloadTransformer: transformCheckoutComGooglePayTokenPayload,
  headers: {
    Authorization: process.env.REACT_APP_CHECKOUT_COM_API_KEY,
    'Content-Type': 'application/json',
  },
});

const transformCheckoutComApplePayTokenPayload = (payload) => {
  const token = payload;

  const updatedPayload = {
    type: 'applepay',
    token_data: token,
  };

  return updatedPayload;
};

const fetchCheckoutComApplePayToken = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  externalEndpoint: process.env.REACT_APP_CHECKOUT_COM_API_TOKEN_ENDPOINT,
  method: 'POST',
  payloadTransformer: transformCheckoutComApplePayTokenPayload,
  headers: {
    Authorization: process.env.REACT_APP_CHECKOUT_COM_API_KEY,
    'Content-Type': 'application/json',
  },
});

const transformCheckoutComApplePayValidationSessionPayload = (payload) => {
  const updatedPayload = {
    url: payload,
  };

  return updatedPayload;
};

const fetchCheckoutComApplePayValidationSession = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'POST',
  resource: 'checkout/applepay',
  payloadTransformer: transformCheckoutComApplePayValidationSessionPayload,
});

const transformVouchersCheckoutComApplePayValidationSessionPayload = (payload) => {
  const updatedPayload = {
    url: payload,
  };

  return updatedPayload;
};

const fetchVouchersCheckoutComApplePayValidationSession = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  method: 'POST',
  resource: 'checkout/applepay',
  payloadTransformer: transformVouchersCheckoutComApplePayValidationSessionPayload,
});

const extraReducers = [
  createCheckoutComRequest,
  createCheckoutComSCAConfirmRequest,
  createCheckoutConfirmRequest,
  createCheckoutRequest,
  fetchCheckoutComGooglePayToken,
  fetchCheckoutComApplePayValidationSession,
  fetchCheckoutComApplePayToken,
  createVouchersCheckoutRequest,
  createPaidUpRequest,
  createCheckoutPaidUpConfirmRequest,
  createVouchersCheckoutComRequest,
  createVouchersCheckoutComSCAConfirmRequest,
  createVouchersCheckoutConfirmRequest,
  fetchVouchersCheckoutComApplePayValidationSession,
];

const slice = sliceHandler({ sliceName, extraReducers });
const { actions, reducer } = slice;

export {
  createCheckoutComRequest,
  createCheckoutComSCAConfirmRequest,
  createCheckoutRequest,
  createCheckoutConfirmRequest,
  fetchCheckoutComGooglePayToken,
  fetchCheckoutComApplePayValidationSession,
  fetchCheckoutComApplePayToken,
  createVouchersCheckoutRequest,
  createPaidUpRequest,
  createCheckoutPaidUpConfirmRequest,
  createVouchersCheckoutComRequest,
  createVouchersCheckoutComSCAConfirmRequest,
  createVouchersCheckoutConfirmRequest,
  fetchVouchersCheckoutComApplePayValidationSession,
};
export const { replaceStateData } = actions;

export default reducer;
