// TODO update backend to be able to collate quantities in BE
const getChangeCartItemQuantityPayload = (cart, uniqItemId, quantityChange, isDelete = false) => {
  const updatedCart = [...cart];
  const targetItemIndex = updatedCart.findIndex((cartItem) => cartItem.uniqItemId === uniqItemId);
  const { quantity } = updatedCart[targetItemIndex];
  const updatedQuantity = quantity + quantityChange;

  const newItemPayload = {
    quantity: updatedQuantity,
    isDelete,
    uniqItemId,
  };

  updatedCart[targetItemIndex] = newItemPayload;

  return updatedCart;
};

export default getChangeCartItemQuantityPayload;
