import React from 'react';
import { nanoid } from 'nanoid';

import CartItem from '../CartItem';

import getGroupedCartItemsByVendorAndPriority from '../../utils/getGroupedCartItemsByVendorAndPriority';
import getGroupedCartItemsWithVendorName from '../../utils/getGroupedCartItemsWithVendorName';

import cySelectors from '../../tests/cySelectors';

const CartItems = ({
  items,
  handleChangeQuantity,
  isOrderLoading,
  vendors,
  isEditable = false,
  priorities,
}) => {
  const groupedCartItemsWithVendorName = getGroupedCartItemsWithVendorName(
    items,
    vendors,
    priorities,
  );
  const groupedCartItemsWithVendorNameAndPriority = getGroupedCartItemsByVendorAndPriority(
    groupedCartItemsWithVendorName,
  );

  return (
    <div className="cart__items">
      {groupedCartItemsWithVendorNameAndPriority.map(
        ({ vendorName, priorities: vendorPriorities }) => {
          const vendorKey = nanoid();

          return (
            <div
              className="cart__items__vendor"
              key={vendorKey}
              data-cy={cySelectors.CART_GROUP_ITEM}
            >
              {vendorName && (
                <p
                  className="cart__items__vendor__name"
                  data-cy={cySelectors.CART_ITEM_VENDOR_NAME}
                >
                  {vendorName}
                </p>
              )}
              {vendorPriorities.map(({ priority, items: vendorItems }) => {
                const priorityKey = nanoid();

                return (
                  <div className="cart__items__priority" key={priorityKey}>
                    {priority && (
                      <p
                        className="cart__items__priority__name"
                        data-cy={cySelectors.CART_GROUP_ITEM_PRIORITY}
                      >
                        {priority}
                      </p>
                    )}
                    {vendorItems.map((cartItem) => {
                      const key = cartItem.uniqItemId;

                      return (
                        <CartItem
                          key={key}
                          cartItem={cartItem}
                          handleChangeQuantity={handleChangeQuantity}
                          isOrderLoading={isOrderLoading}
                          isEditable={isEditable}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          );
        },
      )}
    </div>
  );
};

export default CartItems;
