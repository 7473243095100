const getChangeUpsellQuantityPayload = (cart, item, quantityChange, upsoldFrom) => {
  const { itemId } = item;

  const updatedCart = [...cart];
  const targetItemIndex = cart.findIndex(({ uniqItemId }) => {
    const { itemId: cartItemId } = JSON.parse(atob(uniqItemId));

    return cartItemId === itemId;
  });
  const { quantity } = updatedCart[targetItemIndex];
  const { uniqItemId } = updatedCart[targetItemIndex];
  const updatedQuantity = quantity + quantityChange;

  const newItemPayload = {
    quantity: updatedQuantity,
    isUpsell: true,
    upsoldFrom,
    uniqItemId,
  };

  updatedCart[targetItemIndex] = newItemPayload;

  return updatedCart;
};

export default getChangeUpsellQuantityPayload;
