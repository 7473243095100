import clsx from 'clsx';
import React from 'react';
import choiceInputTypes from './choiceInputTypes';

const ChoiceInput = ({
  type,
  name,
  disabled,
  value,
  checked,
  isSmall,
  onChange,
  onBlur,
  innerRef,
  ...props
}) => {
  const finalType = type === choiceInputTypes.CHECKBOX ? 'checkbox' : 'radio';
  const classes = clsx('choice-input', {
    'choice-input--small': isSmall,
  });

  return (
    <div className={classes}>
      <input
        onChange={onChange}
        type={finalType}
        id={name}
        name={name}
        disabled={disabled}
        value={value || ''}
        checked={checked || false}
        ref={innerRef}
        {...props}
      />
    </div>
  );
};

export default ChoiceInput;
