import setPersonalisationIframeCssVariables from './setPersonalisationIframeCssVariables';
import upsertUserPreferences from './upsertUserPreferences';
import loadFonts from './loadFonts';

const initPersonalisationIframeListener = (setIframeMode, setIsIframeBannerVisible) => {
  const isWithinIframe = window.self !== window.top;

  if (isWithinIframe) {
    upsertUserPreferences({ cookieConsentAgreed: true });

    window.addEventListener(
      'message',
      (event) => {
        const { origin } = event;
        const { data } = event;
        const { type } = data;
        const isAllowedOrigin = origin === process.env.REACT_APP_SERVEDUP_DASHBOARD_URL;
        const isServedupMessage = type === 'servedup';

        if (!(isAllowedOrigin && isServedupMessage)) return;

        setIframeMode(true);

        const isIframeBannerVisible = data.bannerImgUrl !== 'url(null)' || !data.bannerImgUrl;
        setIsIframeBannerVisible(data.bannerImgUrl === 'none' ? false : isIframeBannerVisible);
        loadFonts(data.font);
        setPersonalisationIframeCssVariables(data);
      },
      false,
    );
  }
};

export default initPersonalisationIframeListener;
