import React from 'react';
import clsx from 'clsx';

import MenuFiltersModal from '../MenuFiltersModal';

import { ReactComponent as FiltersIcon } from '../../../assets/images/filters.svg';

import cySelectors from '../../../tests/cySelectors';

const MenuFilters = ({
  hasFiltersSelected,
  setFilters,
  isFiltersOpen,
  handleToggleFiltersModal,
  filteredCategoryItemsNumber,
  dietaryRequirementsData,
}) => {
  const classes = clsx('menu__filters', {
    'menu__filters--has-filters': hasFiltersSelected,
  });

  return (
    <div className={classes}>
      <button
        type="button"
        className="menu__filters__cta"
        onClick={handleToggleFiltersModal}
        data-cy={cySelectors.MENU_NAVIGATION_FILTER_BTN}
      >
        <FiltersIcon />
      </button>
      <MenuFiltersModal
        dietaryRequirementsData={dietaryRequirementsData}
        isModalOpen={isFiltersOpen}
        handleToggleModal={handleToggleFiltersModal}
        hasFiltersSelected={hasFiltersSelected}
        setFilters={setFilters}
        filteredCategoryItemsNumber={filteredCategoryItemsNumber}
      />
    </div>
  );
};

export default MenuFilters;
