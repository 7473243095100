import sanitiseFormResponse from './sanitiseFormResponse';

const checkModifiersValidity = (chosenModifiers, menuModifiers, modifiersTotal) => {
  const areModifiersValid = chosenModifiers.every((modifier) => {
    const { modifierItems, modifierId } = modifier;

    const sourceModifier = menuModifiers.find((mod) => mod.modifierId === modifierId);
    const updatedModifier = modifiersTotal?.find((mod) => mod.modifierId === modifierId);

    const sanitisedModifierItems = sanitiseFormResponse(modifierItems);
    const newTotalChosen =
      sanitisedModifierItems && modifiersTotal
        ? updatedModifier?.selectedItemIds?.reduce((acc, _curr) => acc + (_curr.quantity || 0), 0)
        : sanitisedModifierItems.filter((mod) => mod !== false).length;

    const { minSelections, maxSelections } = sourceModifier;
    if (maxSelections === 1) {
      return true;
    }
    const isValid = newTotalChosen >= minSelections && newTotalChosen <= maxSelections;

    return isValid;
  });

  return areModifiersValid;
};

export default checkModifiersValidity;
