import React from 'react';
import { addMonths, endOfDay, startOfDay } from 'date-fns';
import { useFormContext } from 'react-hook-form';

import FormFieldDatePicker from '../../../../../components/FormFieldDatePicker';
import cySelectors from '../../../../../tests/cySelectors';

const VouchersFormExpiryDates = ({ defaultVoucherExpiry = 6 }) => {
  const { watch } = useFormContext();
  const nextYearDate = endOfDay(addMonths(new Date(), defaultVoucherExpiry));
  const today = startOfDay(new Date());
  const minDate = watch('startDate');
  const maxDate = watch('endDate');

  return (
    <div className="vouchers__modal__expiry-dates" data-cy={cySelectors.VOUCHERS_EXPIRY_CHOOSERS}>
      <FormFieldDatePicker
        name="startDate"
        label="Start date"
        datePickerProps={{ startDate: today, minDate: today, maxDate }}
      />
      <FormFieldDatePicker
        name="endDate"
        label="End date"
        datePickerProps={{ startDate: nextYearDate, minDate }}
      />
    </div>
  );
};

export default VouchersFormExpiryDates;
