import React, { createRef, useCallback, useContext, useEffect } from 'react';
import debounce from 'lodash.debounce';

import MenuCategory from './MenuCategory';

import AppContext from '../App/AppContext';

import cySelectors from '../../tests/cySelectors';

const MenuCategories = ({
  menuCategoryItems,
  handleGetItemCount,
  handleModalToggle,
  allergensSelected,
}) => {
  const { setCurrentViewedCategory } = useContext(AppContext);

  const categoriesRef = createRef();

  const getMenuCategoriesDistancesFromTop = useCallback(() => {
    const headerAndMenuNavigationHeight = Array.from(
      document.querySelectorAll('.menu-page-header, .menu-page-header__deskop, .menu__navigation'),
    )
      .map(({ clientHeight }) => clientHeight)
      .reduce((a, b) => a + b, 0);

    return Array.from(categoriesRef.current.children).map((categoryElement) => {
      const { clientHeight } = categoryElement;
      const offsetTop =
        categoryElement.getBoundingClientRect().top + clientHeight - headerAndMenuNavigationHeight;

      return offsetTop;
    });
  }, [categoriesRef]);

  const handleSetCurrentlyViewedCategory = useCallback(() => {
    // Guard is needed as the component is unmounted before the ref is set
    if (categoriesRef.current) {
      const menuCategoriesElementsDistanceFromTop = getMenuCategoriesDistancesFromTop();
      const closestMenuCategoryElement = Math.min(
        ...menuCategoriesElementsDistanceFromTop.filter((distance) => distance > 0),
      );
      const currentlyViewedCategoryIndex = menuCategoriesElementsDistanceFromTop.indexOf(
        closestMenuCategoryElement,
      );
      const currentlyViewedCategory = categoriesRef.current.children[currentlyViewedCategoryIndex];
      const currentlyViewedCategoryName = currentlyViewedCategory?.querySelector(
        '[data-servedup-category-id]',
      )?.innerText;

      setCurrentViewedCategory(currentlyViewedCategoryName);
    }
  }, [categoriesRef, getMenuCategoriesDistancesFromTop, setCurrentViewedCategory]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleSetCurrentlyViewedCategory = useCallback(
    debounce(handleSetCurrentlyViewedCategory, 200),
    [categoriesRef],
  );

  useEffect(() => {
    const isCategoryRefMounted = categoriesRef.current;

    if (isCategoryRefMounted) {
      window.addEventListener('scroll', debouncedHandleSetCurrentlyViewedCategory);
    }

    return () => window.removeEventListener('scroll', debouncedHandleSetCurrentlyViewedCategory);
  }, [categoriesRef, debouncedHandleSetCurrentlyViewedCategory]);

  useEffect(() => {
    handleSetCurrentlyViewedCategory();
  }, [handleSetCurrentlyViewedCategory]);

  return (
    <div className="menu__categories" data-cy={cySelectors.MENU_CATEGORIES} ref={categoriesRef}>
      {menuCategoryItems.map((menuCategoryItem, index) => {
        const key = `${menuCategoryItem.categoryId}-${index}`;
        return (
          <MenuCategory
            key={key}
            menuCategoryItem={menuCategoryItem}
            handleGetItemCount={handleGetItemCount}
            handleModalToggle={handleModalToggle}
            allergensSelected={allergensSelected}
          />
        );
      })}
    </div>
  );
};

export default MenuCategories;
