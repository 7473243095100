import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Form from '../../../components/Form';
import FormField from '../../../components/FormField';

import { addCheckoutCode } from '../../../store/slices/order';
import CheckoutCodeFieldValidationSchema from './CheckoutCodeFieldValidationSchema';

import cySelectors from '../../../tests/cySelectors';
import CheckoutContext from '../CheckoutContext';
import getHumanReadableErrorMessage from '../../../utils/getHumanReadableErrorMessage';
import { inlineErrorCodes } from '../../../utils/errorCodes';

const CheckoutCodeField = () => {
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { orderId, venueId } = orderData;
  const {
    checkoutCodeSuccessResponse,
    setCheckoutCodeSuccessResponse,
    setCheckoutCodeFormInstance,
    checkoutCodeFormInstance,
  } = useContext(CheckoutContext);
  const dispatch = useDispatch();
  const { isValid, reset } = checkoutCodeFormInstance;

  const handleOnSubmit = useCallback(
    async (code) => {
      try {
        // TODO: BE can't be changed for now so for both promoCodes and voucherCodes we use promoCode as prop key
        const payload = { venueId, promoCode: code };
        const { message: successResponseMessage } = getHumanReadableErrorMessage({
          errorCode: inlineErrorCodes.CODE_APPLIED_SUCCESSFULLY,
        });

        await dispatch(addCheckoutCode({ params: orderId, payload })).unwrap();

        setCheckoutCodeSuccessResponse(successResponseMessage);
        checkoutCodeFormInstance.clearErrors('code');
        reset({ code: '' });
      } catch (error) {
        setCheckoutCodeSuccessResponse(null);
        const errorResponseMessage = {
          type: 'manual',
          message: getHumanReadableErrorMessage(error).message,
        };

        checkoutCodeFormInstance.setError('code', errorResponseMessage);
      }
    },
    [venueId, dispatch, orderId, setCheckoutCodeSuccessResponse, checkoutCodeFormInstance, reset],
  );

  return (
    <div className="checkout-code">
      <Form
        validationSchema={CheckoutCodeFieldValidationSchema}
        setFormInstance={setCheckoutCodeFormInstance}
        mode="onChange"
      >
        <FormField
          name="code"
          label="Promo code or voucher"
          placeholder="Code"
          data-cy={cySelectors.CHECKOUTCODE_INPUT}
          inlineSubmit={{
            label: 'Apply',
            successResponse: checkoutCodeSuccessResponse,
            isLoading: orderLoading,
            handleOnSubmit,
            isValid,
          }}
        />
      </Form>
    </div>
  );
};

export default CheckoutCodeField;
