import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import LoadingSpinner from '../../../components/LoadingSpinner';
import withHandleRoutesDispatch from '../../../hoc/withHandleRoutesDispatch';

const PaidUpSuccess = () => {
  const { data: paidupData, error: paidupError } = useSelector((state) => state.paidup);
  const { data: checkoutData, error: checkoutError } = useSelector((state) => state.checkout);
  const { venueId, orderId } = useParams();
  const { status } = paidupData || {};
  const history = useHistory();
  const checkoutPageRoute = useMemo(() => `/checkout/${venueId}/${orderId}`, [venueId, orderId]);
  const confirmationPageRoute = useMemo(
    () => `/confirmation/${venueId}/${orderId}`,
    [venueId, orderId],
  );
  const failedPageRoute = useMemo(() => `/error/paidup/${venueId}/${orderId}`, [venueId, orderId]);

  useEffect(() => {
    if (status === 'failed' || checkoutError || paidupError) {
      history.push(failedPageRoute);
    } else if (status === 'sent') {
      history.push(confirmationPageRoute);
    }
  }, [
    checkoutPageRoute,
    confirmationPageRoute,
    history,
    status,
    checkoutData,
    failedPageRoute,
    checkoutError,
    paidupError,
  ]);

  return <LoadingSpinner />;
};

export default withHandleRoutesDispatch(PaidUpSuccess);
