import sanitiseGACartPayload from './sanitiseGACartPayload';

const sendGAPaymentInfo = (checkoutData) => {
  if (window.gtag) {
    const { currency, total: value, promoCode, paymentService } = checkoutData;
    const items = sanitiseGACartPayload(checkoutData);

    window.gtag('event', 'add_payment_info', {
      currency,
      value,
      coupon: promoCode,
      payment_type: paymentService,
      items,
    });
  }
};

export default sendGAPaymentInfo;
