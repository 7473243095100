const sanitiseGACartPayload = (checkoutData) => {
  const { currency, venueId, cart, promoCode } = checkoutData;

  return cart.map((item, index) => {
    const { itemId, itemName, itemOption, quantity, price, category, type } = item;
    const finalPrice = price / 1000000;

    return {
      item_id: itemId,
      item_name: itemName,
      affiliation: venueId,
      coupon: promoCode,
      currency,
      index,
      item_category: type,
      item_category2: category,
      item_category3: itemOption,
      quantity,
      price: finalPrice,
    };
  });
};

export default sanitiseGACartPayload;
