import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import CheckoutContext from '../CheckoutContext';

import Button from '../../../components/Button';

import { ReactComponent as NewIcon } from '../../../assets/images/new-tag.svg';

import cySelectors from '../../../tests/cySelectors';

const CheckoutPaidUpButton = () => {
  const { isUserPaying, isBankedRequestInitiated, handleBankedOnSubmit } =
    useContext(CheckoutContext);
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <>
      <Button
        Icon={NewIcon}
        dataCy={cySelectors.CHECKOUT_PAIDUP_BUTTON}
        loading={isUserPaying && isBankedRequestInitiated}
        disabled={isUserPaying || !isValid}
        onClick={handleBankedOnSubmit}
      >
        Pay via bank app
      </Button>
    </>
  );
};

export default CheckoutPaidUpButton;
