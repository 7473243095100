const getAvailableCategoryMenuItems = (categories = []) => {
  const filteredCategoryItems = categories.filter(({ categoryItems }) => {
    const isEmptyCategoryItems = !categoryItems.length;
    const isAllItemsDisabled = categoryItems.every(({ available }) => !available);

    return !isEmptyCategoryItems && !isAllItemsDisabled;
  });

  return filteredCategoryItems;
};

export default getAvailableCategoryMenuItems;
