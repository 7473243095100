import clsx from 'clsx';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronRightIcon } from '../../../assets/images/chevron-right.svg';

import cySelectors from '../../../tests/cySelectors';

const Vendor = ({ vendorData, venueId, orderId, viewOnly }) => {
  const { venueName, image, id, isOpen, openTime, nextOpeningDay, nextOpeningTime, isClosedToday } =
    vendorData;
  const dataCySelector = !isOpen ? cySelectors.VENDOR_ITEM_CLOSED : cySelectors.VENDOR_ITEM;

  const vendorPath = useCallback(() => {
    if (viewOnly) {
      return `/menu/${venueId}/mock?vendorId=${id}&isMenuView=true`;
    }
    if (isOpen) {
      return `/menu/${venueId}/${orderId}?vendorId=${id}`;
    }
    return '#';
  }, [id, isOpen, orderId, venueId, viewOnly]);

  const classes = clsx('vendor', {
    'vendor--closed': !isOpen,
  });

  return (
    <Link to={vendorPath} className={classes} data-cy={dataCySelector}>
      <div className="vendor__wrapper">
        {image && (
          <div className="vendor__image">
            <img src={image} alt={venueName} />
            {!isOpen && !viewOnly && (
              <>
                <div className="vendor--closed__overlay" />
                <div className="vendor--closed__info">
                  <p className="vendor--closed__info__tag">Closed</p>
                  {nextOpeningDay && nextOpeningTime && (
                    <p className="vendor--closed__info__text">
                      {!isClosedToday
                        ? `Opens soon at ${openTime}`
                        : `Opens ${nextOpeningDay} at ${nextOpeningTime}`}
                    </p>
                  )}
                </div>
              </>
            )}
          </div>
        )}
        <div className="vendor__cta">
          <div className="vendor__cta__text">
            <strong className="vendor__cta__text__name">{venueName}</strong>
            {!isOpen && !image && !viewOnly && (
              <p className="vendor__cta__text_info">
                Closed{' '}
                {nextOpeningDay && nextOpeningTime && (
                  <>
                    -{' '}
                    {!isClosedToday
                      ? `Opens soon at ${openTime}`
                      : `Opens ${nextOpeningDay} at ${nextOpeningTime}`}
                  </>
                )}
              </p>
            )}
          </div>
          <ChevronRightIcon className="vendor__cta__icon" />
        </div>
      </div>
    </Link>
  );
};

export default Vendor;
