import React, { useCallback, useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';

import LoadingSpinner from '../../components/LoadingSpinner';
import FooterCta from '../../components/FooterCta';
import Header from '../../components/Header';
import CartItems from '../../components/CartItems';

import getUpsellsFromMenuData from '../../components/ItemUpsells/getUpsellsFromMenuData';

import ReviewForm from './ReviewForm';

import {
  patchOrder,
  changeCartItemQuantity,
  changeCartVoucherQuantity,
  addToCart,
  changeUpsellQuantity,
} from '../../store/slices/order';
import { fetchBasketUpsells } from '../../store/slices/upsells';

import formatCurrency from '../../utils/formatCurrency';
import pageNames from '../../utils/pageNames';

import ReviewUpsellsModal from './ReviewUpsellsModal';
import getItemCount from '../Menu/getItemCount';

import cySelectors from '../../tests/cySelectors';
import MenuModal from '../Menu/MenuModal';
import AppContext from '../../components/App/AppContext';

import sendGAItemAddToCart from '../../utils/tracking/GA/sendGAItemAddToCart';

const ReviewSubmitButton = ({ patchNotesError }) => {
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const hasOrders = orderData && orderData.cart.length > 0;
  const { total } = orderData || {};
  const formattedTotal = formatCurrency(total);

  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <FooterCta
      type="submit"
      loading={orderLoading}
      disabled={!isValid || !hasOrders}
      data-cy={cySelectors.SUBMIT_BTN}
    >
      <span>{patchNotesError ? 'Please try again' : 'Continue'}</span>

      <span>{formattedTotal}</span>
    </FooterCta>
  );
};

const Review = ({ isVouchers = false }) => {
  const [reviewFormInstance, setReviewFormInstance] = useState(null);
  const [currentUpsellItem, setCurrentUpsellItem] = useState(null);
  const [isUpsellItemModalOpen, setIsUpsellItemModalOpen] = useState(false);
  const [isUpsellsModalOpen, setIsUpsellsModalOpen] = useState(false);
  const [patchNotesError, setPatchNotesError] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { venueId, orderId } = useParams();
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const hasOrders = orderData && orderData.cart.length > 0;
  const { data: cartData } = useSelector((state) => state.cart);
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: upsellsData = [] } = useSelector((state) => state.upsells);
  const { data: menuData = [] } = useSelector((state) => state.menu);
  const { menu, venueId: childVenueId } = menuData || {};
  const { upsells } = upsellsData || {};
  const { itemId: currentUpsellItemId, categoryId: currentUpsellItemCategoryId } =
    currentUpsellItem || {};
  const { cart: orderCart, subtotal, total, menuName } = orderData || {};
  const { vendors, priorities, upsells: upsellsStatus = {} } = venueData || {};
  const isBasketUpsellsEnabled = upsellsStatus.checkout;
  const upsellsMenuItems = getUpsellsFromMenuData(upsells, menu);
  const hasSeenUpsellReviewModal = !!window.sessionStorage.getItem('hasSeenUpsellReviewModal');
  const hasUpsells = !!upsellsMenuItems.length;
  const isUpsellFlow = !hasSeenUpsellReviewModal && isBasketUpsellsEnabled && hasUpsells;
  const [hasUpsold, setHasUpsold] = useState(false);

  const formattedSubtotal = formatCurrency(subtotal);
  const formattedTotal = formatCurrency(total);
  const throwError = useAsyncServedUpError();
  const { displayUniversalConfirmation, displayUniversalLoading, hideUniversalConfirmation } =
    useContext(AppContext);

  const handleNavigateToDetailsPage = useCallback(() => {
    const detailsPageRoute = isVouchers
      ? `/vouchers/details/${venueId}/${orderId}`
      : `/details/${venueId}/${orderId}`;

    history.push(detailsPageRoute);
  }, [isVouchers, venueId, orderId, history]);

  const handleOpenUpsellModal = useCallback(() => {
    window.sessionStorage.setItem('hasSeenUpsellReviewModal', true);

    setIsUpsellsModalOpen(true);
  }, []);

  const handleCloseUpsellModal = useCallback(() => {
    setIsUpsellsModalOpen(false);
  }, []);

  const handleCloseUpsellItemModal = useCallback(() => {
    setIsUpsellItemModalOpen(false);
  }, []);

  const handlePatchNotes = useCallback(async () => {
    const reviewFormValues = reviewFormInstance.getValues();
    const { notes } = reviewFormValues;
    const payload = {
      venueId,
      notes,
    };

    try {
      await dispatch(patchOrder({ params: orderId, payload })).unwrap();

      setPatchNotesError(false);

      handleNavigateToDetailsPage();
    } catch (error) {
      setPatchNotesError(true);
      throwError({ error, venueId, orderId });
    }
  }, [reviewFormInstance, venueId, dispatch, orderId, handleNavigateToDetailsPage, throwError]);

  const handleChangeQuantity = useCallback(
    async ({ uniqItemId, quantityChange, isDelete, amount }) => {
      const payload = {
        venueId,
        cart: cartData,
        uniqItemId,
        quantityChange,
        isDelete,
      };
      const vouchersPayload = {
        ...payload,
        amount,
      };
      const finalPayload = isVouchers ? vouchersPayload : payload;

      try {
        const finalChangeCartItemQuantity = isVouchers
          ? changeCartVoucherQuantity
          : changeCartItemQuantity;

        await dispatch(
          finalChangeCartItemQuantity({ params: orderId, payload: finalPayload }),
        ).unwrap();
      } catch (error) {
        throwError({ error, venueId, orderId });
      }
    },
    [venueId, cartData, isVouchers, dispatch, orderId, throwError],
  );

  const handleFetchBasketUpsells = useCallback(() => {
    dispatch(fetchBasketUpsells({ params: { orderId }, query: { menuName } }));
  }, [orderId, menuName, dispatch]);

  const handleGetItemCount = useCallback(
    ({ item }) => getItemCount({ item, cart: cartData, venueId }),
    [cartData, venueId],
  );

  const handleUpsellModalToggle = useCallback(
    (upsellItem) => {
      setCurrentUpsellItem(upsellItem);
      setIsUpsellItemModalOpen(!isUpsellItemModalOpen);
    },
    [isUpsellItemModalOpen],
  );

  const handleUpsellModalSubmit = async (itemQuantity, values) => {
    const { itemOption, modifiers: checkModifiers, priority, notes } = values;
    const itemModifiers =
      (checkModifiers && Object.keys(checkModifiers).map((key) => checkModifiers[key])) || [];
    const newItem = {
      itemId: currentUpsellItemId,
      itemOption,
      modifiers: itemModifiers,
      priority,
      categoryId: currentUpsellItemCategoryId,
      notes,
      isUpsell: true,
      upsoldFrom: 'BASKET',
    };

    const payload = {
      venueId,
      childVenueId,
      newItem,
      itemQuantity,
      cart: cartData,
    };

    try {
      displayUniversalLoading();
      await dispatch(addToCart({ params: orderId, payload })).unwrap();

      sendGAItemAddToCart({ cart: [{ item_id: currentUpsellItemId }], venueId });
      await displayUniversalConfirmation();
      setHasUpsold(true);
      handleCloseUpsellItemModal();
    } catch (error) {
      hideUniversalConfirmation();
      handleCloseUpsellItemModal();
      throwError({ error, venueId, orderId });
    }
  };

  const handleChangeUpsellQuantity = useCallback(
    async ({ quantityChange, item }) => {
      try {
        dispatch(
          changeUpsellQuantity({
            params: orderId,
            payload: {
              venueId,
              cart: cartData,
              item,
              quantityChange,
              upsoldFrom: 'BASKET',
            },
          }),
        );
      } catch (error) {
        throwError({ error, venueId, orderId });
      }
    },
    [cartData, dispatch, orderId, throwError, venueId],
  );

  useEffect(() => {
    if (menuName && isBasketUpsellsEnabled) {
      handleFetchBasketUpsells();
    }
  }, [isBasketUpsellsEnabled, menuName, handleFetchBasketUpsells]);

  return (
    <>
      <Header hasBackBtn title={pageNames.REVIEW} />
      {orderData && orderCart && cartData && venueData ? (
        <div className="review">
          <CartItems
            items={orderCart}
            handleChangeQuantity={handleChangeQuantity}
            isOrderLoading={orderLoading}
            isEditable
            vendors={vendors}
            priorities={priorities}
          />

          {!isVouchers && (
            <>
              <ReviewForm handleOnSubmit={handlePatchNotes} setFormInstance={setReviewFormInstance}>
                {!isUpsellFlow && <ReviewSubmitButton patchNotesError={patchNotesError} />}
              </ReviewForm>

              {isUpsellFlow && (
                <FooterCta
                  data-cy={cySelectors.SUBMIT_BTN}
                  onClick={handleOpenUpsellModal}
                  disabled={!hasOrders}
                >
                  <span>Continue</span>

                  <span>{formattedTotal}</span>
                </FooterCta>
              )}

              <ReviewUpsellsModal
                upsells={upsellsMenuItems}
                isModalOpen={isUpsellsModalOpen}
                setIsModalOpen={setIsUpsellsModalOpen}
                handlePatchNotes={handlePatchNotes}
                handleCloseModal={handleCloseUpsellModal}
                handleGetItemCount={handleGetItemCount}
                handleChangeQuantity={handleChangeUpsellQuantity}
                handleUpsellModalToggle={handleUpsellModalToggle}
                hasUpsold={hasUpsold}
              />
              {isUpsellItemModalOpen && currentUpsellItem && (
                <MenuModal
                  isModalOpen={isUpsellItemModalOpen}
                  setIsModalOpen={setIsUpsellItemModalOpen}
                  handleCloseModal={handleCloseUpsellItemModal}
                  handleGetItemCount={handleGetItemCount}
                  handleOnSubmit={handleUpsellModalSubmit}
                  currentItem={currentUpsellItem}
                  allergensContained={[]}
                  priorities={priorities}
                />
              )}
            </>
          )}

          {isVouchers && (
            <FooterCta
              data-cy={cySelectors.SUBMIT_BTN}
              onClick={handleNavigateToDetailsPage}
              disabled={!hasOrders}
            >
              <span>Continue</span>
              <span>{formattedSubtotal}</span>
            </FooterCta>
          )}
        </div>
      ) : (
        <LoadingSpinner center />
      )}
    </>
  );
};

export default withHandleRoutesDispatch(Review);
