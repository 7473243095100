import React from 'react';

import { ReactComponent as DietaryRequirementsIcon } from '../../../assets/images/dietary-icon.svg';

import cySelectors from '../../../tests/cySelectors';

const MenuDietaryPreferences = ({ dietaryPreferences }) => (
  <div
    className="menu__dietary-preferences"
    data-cy={cySelectors.MENU_ITEMS_MODAL__DIETARY_PREFERENCES}
  >
    <div className="menu__dietary-preferences__label">
      <DietaryRequirementsIcon />
    </div>
    <ul className="menu__dietary-preferences__list">
      {dietaryPreferences.map(({ requirementId, name }, index) => {
        const isLastItem = index === dietaryPreferences.length - 1;

        return (
          <li key={requirementId} className="menu__dietary-preferences__list__item">
            {name}
            {isLastItem ? (
              ''
            ) : (
              <span className="menu__dietary-preferences__list__item__separator">•</span>
            )}
          </li>
        );
      })}
    </ul>
  </div>
);

export default MenuDietaryPreferences;
