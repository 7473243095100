import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'upsells',
  ENDPOINT: 'orders',
};

const sliceName = sliceArgs.SLICE_NAME;

const fetchUpsellsParamsTransformer = (params) => {
  const { orderId, itemId } = params;

  return `${orderId}/${sliceName}/item/${itemId}`;
};

const fetchUpsells = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: sliceArgs.RESOURCE,
  method: 'GET',
  paramsTransformer: fetchUpsellsParamsTransformer,
});

const fetchBasketUpsellsParamsTransformer = (params) => {
  const { orderId } = params;

  return `${orderId}/${sliceName}/basket`;
};

const fetchBasketUpsells = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  resource: sliceArgs.RESOURCE,
  method: 'GET',
  paramsTransformer: fetchBasketUpsellsParamsTransformer,
});

const reducers = {
  resetUpsells: (state) => {
    state.data = null;
    state.loading = false;
    state.error = null;
  },
};

const extraReducers = [fetchUpsells, fetchBasketUpsells];

const slice = sliceHandler({ sliceName, extraReducers, reducers });
const { actions, reducer } = slice;

export { fetchUpsells, fetchBasketUpsells };
export const { resetUpsells } = actions;
export default reducer;
