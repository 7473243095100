import React, { useEffect, useContext, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import { FormContext } from '../Form';

const FormFieldToggle = ({
  name,
  label,
  helpText,
  prefillValue,
  disabled,
  extraClasses,
  ...props
}) => {
  const [toggled, setToggled] = useState(false);
  const {
    register,
    getValues,
    setValue,
    formState: { dirtyFields, errors },
    trigger,
  } = useFormContext();
  const { validateOnLoad } = useContext(FormContext);

  const isFieldDirty = dirtyFields[name];
  const isValidateOnLoad = !isFieldDirty && getValues(name) && validateOnLoad;
  const classes = clsx(
    {
      'form__field__toggle--toggled': toggled,
      [extraClasses]: extraClasses,
    },
    'form__field__toggle',
  );

  const handleOnClick = () => {
    setToggled(!toggled);
  };

  useEffect(() => {
    if (prefillValue) {
      setValue(name, prefillValue);
    }
  }, [name, prefillValue, setValue]);

  useEffect(() => {
    if (isValidateOnLoad) {
      trigger(name);
    }
  }, [isValidateOnLoad, name, trigger]);

  return (
    <div className={classes}>
      {label && (
        <div aria-label="label" className="form__field__label">
          {label}
        </div>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}

      <label className="form__field__toggle__container" htmlFor={name}>
        <div
          role="checkbox"
          onClick={handleOnClick}
          onKeyDown={handleOnClick}
          aria-checked={toggled}
          tabIndex={0}
          {...props}
        >
          <input
            type="checkbox"
            {...register(name)}
            id={name}
            name={name}
            aria-invalid={errors[name] ? 'true' : 'false'}
            disabled={disabled}
          />

          <span className="form__field__toggle__circle" />
        </div>
      </label>
    </div>
  );
};

export default FormFieldToggle;
