// TODO: refactor to make use of RKT query: https://redux-toolkit.js.org/rtk-query/overview
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import getVersionedQueryStrings from './getVersionedQueryStrings';

import Logger from '../../utils/Logger';

/**
 * An enriched version of createAsyncThunk with additional params that allows flexibility
 * to call external endpoints and also allows to transform the payload and params before sending it to the server.
 *
 * @param {string} name - name of the action eg. user
 * @param {string} endpoint - endpoint to call eg. orders
 * @param {string} externalEndpoint - external endpoint to call eg. https://jsonplaceholder.typicode.com
 * @param {string} resource - resource to call eg. menu
 * @param {string} method - method to call eg. GET
 * @param {object} headers - headers to call eg. { 'Content-Type': 'application/json' }
 * @param {string} version - version to call eg. v1
 * @param {function} payloadTransformer - transformer function to transform the payload before sending it to the server
 * @param {function} paramsTransformer - transformer function to transform the params before sending it to the server
 *
 *
 * @returns {function} - createAsyncThunk
 * @see https://redux-toolkit.js.org/api/createAsyncThunk
 *
 * @description - This function is used to create a thunk that can be used to call an external endpoint or an
 * internal endpoint.
 * When calling the function that this function returns, it accepts an multi-purpose object
 * that will create and format the request for you, it accepts the following properties:
 * @param {object} params - params to be passed to the endpoint eg. { id: 1 }
 * @param {object} payload - payload to be passed to the endpoint eg. { name: 'John' }
 * @param {object} query - query to be passed to the endpoint eg. { limit: 10 }
 *
 *
 * @example
 *
 * const fetchUser = createEnhancedAsynThunk({
 *  name: 'user',
 *  endpoint: 'users',
 *  method: 'POST',
 *  paramsTransformer: userParamsTransformer
 * });
 *
 * const fetchUser = createEnhancedAsynThunk({
 *  name: 'user',
 *  externalEndpoint: 'https://jsonplaceholder.typicode.com',
 *  resource: 'users',
 *  method: 'GET',
 *  headers: {
 *   'Content-Type': 'application/json',
 *  },
 *  version: 'v1',
 *  payloadTransformer: userPayloadTransformer,
 *  paramsTransformer: (params) => params,
 * });
 *
 *
 */

const createEnhancedAsynThunk = ({
  name,
  endpoint,
  externalEndpoint,
  resource,
  method,
  headers,
  version = '',
  payloadTransformer = (payload) => payload,
  paramsTransformer = (params) => params,
}) => {
  const { REACT_APP_API_URL } = process.env;
  return createAsyncThunk(`${name}/${method}`, async (thunkPayload, { rejectWithValue }) => {
    const { params, payload, query } = thunkPayload || {};
    const paramsPath = params ? `/${paramsTransformer(params)}` : '';
    const resourcePath = resource ? `/${resource}` : '';
    const finalVersionedQuery = getVersionedQueryStrings(version, query);
    const data = payload ? payloadTransformer(payload) : null;
    const url =
      (externalEndpoint &&
        `${externalEndpoint}${paramsPath}${resourcePath}${finalVersionedQuery}`) ||
      `${REACT_APP_API_URL}/${endpoint}${paramsPath}${resourcePath}${finalVersionedQuery}`;

    try {
      const response = await axios({
        method,
        url,
        data,
        headers,
      });
      return response.data;
    } catch (error) {
      Logger.error(error);

      return rejectWithValue(error.response?.data || error);
    }
  });
};

export default createEnhancedAsynThunk;
