import React, { useState, useEffect, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import ChoiceInput from '../../../components/ChoiceInput';
import choiceInputTypes from '../../../components/ChoiceInput/choiceInputTypes';

import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';

const MenuModalModifierRadio = ({ modifier, modifierIndex, handleSetModifiersTotal }) => {
  const { register, setValue } = useFormContext();
  const { label, modifierId, modifierItems } = modifier;
  const [selected, setSelected] = useState('');

  const handleOnChange = (value) => {
    setSelected(value);
  };

  const preselectFirstOption = useCallback(() => {
    const [firstOption] = modifierItems;
    const { itemId } = firstOption;

    setSelected(itemId);
  }, [modifierItems]);

  const presetFirstOptionValue = useCallback(() => {
    const value = { modifierId, modifierItems: selected };

    setValue(`modifiers.${modifierIndex}`, value);
  }, [modifierId, modifierIndex, selected, setValue]);

  const getCheckedItemStatus = (itemId, selectedItem) => itemId === selectedItem;

  useEffect(() => {
    preselectFirstOption();
  }, [preselectFirstOption]);

  useEffect(() => {
    presetFirstOptionValue();
  }, [presetFirstOptionValue]);

  useEffect(() => {
    if (selected) {
      handleSetModifiersTotal(modifierId, modifierItems, selected);
    }
  }, [handleSetModifiersTotal, modifierId, modifierItems, selected]);

  return (
    <div className="item__modal__modifier" data-cy={cySelectors.MODIFIERS_RADIOS}>
      <fieldset>
        <legend>{label}</legend>
        <input
          type="hidden"
          name={`modifiers.${modifierIndex}.modifierId`}
          {...register(`modifiers.${modifierIndex}.modifierId`)}
          value={modifierId}
        />

        {modifierItems.map(({ itemName, itemPrice, label: itemLabel, itemId }) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const {
            ref: innerRef,
            onChange,
            name,
            ...registerProps
          } = register(`modifiers.${modifierIndex}.modifierItems`);
          const formattedPrice = formatCurrency(itemPrice);

          return (
            <div key={itemName} className="item__modal__modifier__choice">
              <label htmlFor={name}>
                <span className="item__modal__modifier__choice__name">{itemLabel || label}</span>
                {!!itemPrice && (
                  <span className="item__modal__modifier__choice__price">+{formattedPrice}</span>
                )}

                <ChoiceInput
                  type={choiceInputTypes.RADIO}
                  name={name}
                  value={itemId}
                  checked={getCheckedItemStatus(itemId, selected)}
                  innerRef={innerRef}
                  data-cy={cySelectors.MODIFIERS_RADIO}
                  {...registerProps}
                  onChange={(event) => {
                    onChange(event);
                    handleOnChange(itemId);
                  }}
                />
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalModifierRadio;
