/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import LoadingSpinner from '../../components/LoadingSpinner';
import FooterCta from '../../components/FooterCta';
import formatCurrency from '../../utils/formatCurrency';
import Vendor from './Vendor';
import useQuery from '../../hooks/useQuery';
import saveTableNameToLocalStorage from '../Venue/saveTableNameToLocalStorage';
import ExternalVendorLink from './ExternalVendorLink';

const Vendors = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { orderId, venueId } = useParams();
  const queries = useQuery();
  const tableName = queries.get('t');

  useEffect(() => {
    if (tableName) {
      saveTableNameToLocalStorage(tableName);
    }
  }, [tableName]);

  const { subtotal } = orderData || {};
  const { vendors, name, multiVendorCollection, externalLinks } = venueData || {};
  const hasVendors = !!vendors?.length;
  const hasExternalLinks = !!externalLinks?.length;
  const history = useHistory();

  const formattedSubtotal = formatCurrency(subtotal);
  const isCartEmpty = !orderData?.cart?.length;
  const isFooterCtaVisibile = !isCartEmpty && orderId;

  const handleNavigateToReviewPage = useCallback(() => {
    history.push(`/review/${venueId}/${orderId}`);
  }, [history, orderId, venueId]);

  return venueData ? (
    <div className="vendors">
      <div className="vendors__header">
        <h1>{name}</h1>
        {multiVendorCollection && (
          <p>
            You can combine food & drink from multiple vendors. If you select an order for
            collection, please keep an eye on your phone for alerts.
          </p>
        )}
      </div>

      <div className="vendors__list">
        {hasExternalLinks &&
          externalLinks.map((externalLink) => (
            <ExternalVendorLink key={externalLink.name} externalVendorData={externalLink} />
          ))}
        {hasVendors ? (
          vendors.map((vendorData) => (
            <Vendor
              key={vendorData.id}
              vendorData={vendorData}
              venueId={venueId}
              orderId={orderId}
            />
          ))
        ) : (
          <p>No vendors found for this venue.</p>
        )}
      </div>
      {isFooterCtaVisibile && (
        <FooterCta onClick={handleNavigateToReviewPage} loading={orderLoading}>
          <span>Go to basket</span>
          <span>{formattedSubtotal}</span>
        </FooterCta>
      )}
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Vendors);
