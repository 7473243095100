import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import ItemModal from '../../../components/ItemModal';
import sendGAItemViewed from '../../../utils/tracking/GA/sendGAItemViewed';

import MenuModalForm from '../MenuModalForm';

const MenuModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  handleOnSubmit,
  currentItem,
  isViewOnly,
  allergensContained,
  priorities,
}) => {
  const hasAllergensSelected = !!allergensContained?.length;
  const { data: venueData } = useSelector((state) => state.venue);
  const { currency, venueId } = venueData;

  useEffect(() => {
    if (isModalOpen && currentItem && venueId) {
      sendGAItemViewed({ venueId, cart: [currentItem], currency });
    }
  }, [isModalOpen, currentItem, venueId, currency]);

  return (
    <ItemModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      currentItem={currentItem}
      shouldSupportWebp={false}
      handleCloseModal={handleCloseModal}
      allergensContained={allergensContained}
    >
      <>
        <MenuModalForm
          priorities={priorities}
          currentItem={currentItem}
          handleOnSubmit={handleOnSubmit}
          isViewOnly={isViewOnly}
          hasAllergensSelected={hasAllergensSelected}
        />
      </>
    </ItemModal>
  );
};

export default MenuModal;
