import { useEffect } from 'react';

const useScrollToTop = (trigger) => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, [trigger]);
};

export default useScrollToTop;
