import React from 'react';

import ChoiceInput from '../../../components/ChoiceInput';
import choiceInputTypes from '../../../components/ChoiceInput/choiceInputTypes';

import cySelectors from '../../../tests/cySelectors';

const MenuFiltersDietaryRequirement = ({
  requirement,
  type,
  register,
  getRequirementSelectedValue,
}) => {
  const { requirementId, abbreviation, name } = requirement;
  const { onChange, onBlur, ref: innerRef } = register(type);
  const id = `${type}-${requirementId}`;
  const checked = getRequirementSelectedValue(requirementId);

  return (
    <div className="menu-filters-dietary-requirement">
      <label htmlFor={id}>
        <span className="">{name}</span>
        <div className="menu-filters-dietary-requirement__input">
          <span className="menu-filters-dietary-requirement__input__abbreviation">
            {abbreviation}
          </span>
          <ChoiceInput
            type={choiceInputTypes.CHECKBOX}
            name={type}
            id={id}
            data-cy={cySelectors.MENU_MODAL_FILTERS_INPUT}
            value={requirementId}
            onChange={onChange}
            onBlur={onBlur}
            innerRef={innerRef}
            checked={checked}
          />
        </div>
      </label>
    </div>
  );
};

export default MenuFiltersDietaryRequirement;
