import React, { useEffect } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import clsx from 'clsx';

import cySelectors from '../../tests/cySelectors';

const CustomInput = ({ children, selectProps, ...extraProps }) => (
  <components.Input {...extraProps} data-cy={selectProps['data-cy']} />
);

const FormFieldSelect = ({
  name,
  label,
  helpText,
  placeholder,
  options,
  extraClasses,
  hideValueInDropdown,
  readOnly,
  children,
  defaultValue,
  disabled,
  ...extraProps
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  const classes = clsx(
    {
      [extraClasses]: extraClasses,
    },
    'form__field form__field__select',
  );
  const selectStyles = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #636264',
      ':hover, :active, :focus': {
        borderColor: 'var(--su-colour, #2E2E2E)',
        boxShadow: '0 0 0 1px var(--su-colour, #2E2E2E)',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#111827',
      fontWeight: state.isSelected ? 600 : 400,
      backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
      '&:hover': {
        backgroundColor: '#ddebff',
      },
    }),
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue, name, setValue]);

  return (
    <div className={classes}>
      {label && (
        <label className="form__field__label" htmlFor={name} id={name}>
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      <Controller
        aria-invalid={errors[name] ? 'true' : 'false'}
        name={name}
        isClearable
        control={control}
        render={({ field }) => (
          <Select
            components={{ Input: CustomInput }}
            {...field}
            {...extraProps}
            defaultValue={defaultValue}
            aria-labelledby={name}
            placeholder={placeholder}
            options={options}
            openMenuOnClick={!readOnly}
            isDisabled={readOnly || disabled}
            styles={selectStyles}
            // formatOptionLabel={<FormatOptionLabel hideValueInDropdown={hideValueInDropdown} />}
            menuPlacement="auto"
          />
        )}
      />
      {errors[name] && (
        <div data-cy={cySelectors.FORM_FIELD_SELECT_ERROR} className="form__field__error">
          {errors[name].message}
        </div>
      )}
    </div>
  );
};

export default FormFieldSelect;
