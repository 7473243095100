import React, { createRef, useContext, useEffect } from 'react';
import clsx from 'clsx';
import AppContext from '../../../components/App/AppContext';

const MenuNavigationCategoryButton = ({
  category,
  handleSetScrollToCurrentCategory,
  handleScrollToCategory,
}) => {
  const { currentViewedCategory } = useContext(AppContext);
  const { categoryId, categoryName } = category;
  const ref = createRef();

  const isCurrentlyViewedCategory = currentViewedCategory === categoryName;
  const classes = clsx({
    'menu__navigation__categories__item--active': isCurrentlyViewedCategory,
  });

  useEffect(() => {
    const element = ref.current;

    if (isCurrentlyViewedCategory) {
      handleSetScrollToCurrentCategory(element);
    }
  }, [handleSetScrollToCurrentCategory, isCurrentlyViewedCategory, ref]);

  return (
    <li key={categoryId} className={classes} ref={ref}>
      <button type="button" onClick={() => handleScrollToCategory(categoryId)}>
        {categoryName}
      </button>
    </li>
  );
};

export default MenuNavigationCategoryButton;
