import React from 'react';
import clsx from 'clsx';

const LoadingSpinner = ({ center, small }) => {
  const classes = clsx('loading-spinner', {
    'loading-spinner--centered': center,
    'loading-spinner--small': small,
  });

  return <div className={classes} />;
};

export default LoadingSpinner;
