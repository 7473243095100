import groupBy from 'lodash.groupby';

const groupMenuCategoryItemsByCategoryName = (menuCategoryItems) => {
  const groupedMenuCategoryItems = groupBy(menuCategoryItems, 'categoryName');

  const finalGroupedMenuCategoryItems = Object.keys(groupedMenuCategoryItems).map(
    (categoryName) => {
      const categoryItems = groupedMenuCategoryItems[categoryName];
      const { categoryId } = categoryItems[0];

      return {
        categoryId,
        categoryItems,
        categoryName,
      };
    },
  );

  return finalGroupedMenuCategoryItems;
};

export default groupMenuCategoryItemsByCategoryName;
