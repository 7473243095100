import md5 from 'md5';

const createGAUserId = (orderPhone, localStorageDetailsForm) => {
  if (orderPhone) {
    const hashedPhone = md5(orderPhone);

    return hashedPhone;
  }

  if (localStorageDetailsForm) {
    const { phone, countryCode } = JSON.parse(localStorageDetailsForm) || {};
    const hasLocalStoragePhone = phone && countryCode;

    if (hasLocalStoragePhone) {
      const { value: countryCodeValue } = countryCode || {};
      const noTrailingLocalStoragePHone = parseInt(phone, 10)?.toFixed(0);
      const hashedPhone = md5(`${countryCodeValue}${noTrailingLocalStoragePHone}`);

      return hashedPhone;
    }
  }

  return null;
};

export default createGAUserId;
