import React from 'react';
import { useSelector } from 'react-redux';

import MenuCategoryItem from '../MenuCategoryItem';

const MenuCategory = ({
  menuCategoryItem,
  handleGetItemCount,
  handleModalToggle,
  allergensSelected,
}) => {
  const { categoryName, categoryId, categoryItems } = menuCategoryItem;
  const { loading: cartLoading } = useSelector((state) => state.cart);

  return (
    <div key={categoryId} className="menu__category">
      <h2 data-servedup-category-id={categoryId} className="menu__category__name">
        {categoryName}
      </h2>
      <div className="menu__category__items">
        {!cartLoading &&
          categoryItems.map((item, index) => {
            const key = `${item.itemId}-${index}`;

            return (
              <MenuCategoryItem
                key={key}
                item={item}
                handleModalToggle={() => handleModalToggle(item)}
                itemCount={handleGetItemCount({ item })}
                allergensSelected={allergensSelected}
              />
            );
          })}
      </div>
    </div>
  );
};

export default MenuCategory;
