import React, { lazy, Suspense, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import clsx from 'clsx';

import CartItems from '../../components/CartItems';
import types from '../../components/FullscreenNotification/types';
import Button from '../../components/Button';
import LoadingSpinner from '../../components/LoadingSpinner';
import Swiper from '../../components/Swiper';

import ConfirmationDetails from '../Confirmation/ConfirmationDetails';

import { fetchOrder, acceptOrder } from '../../store/slices/order';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import { notify } from '../../utils/notify';
import getHumanReadableErrorMessage from '../../utils/getHumanReadableErrorMessage';

const FullScreenNotification = lazy(() => import('../../components/FullscreenNotification'));

const CollectionConfirmation = () => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [isSwiping, setIsSwiping] = useState(false);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: venueData } = useSelector((state) => state.venue);
  const { cart: orderCart } = orderData || {};
  const { vendors, priorities } = venueData || {};
  const { orderId, venueId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const helperTextClasses = clsx('collection-confirmation__footer__helper-text', {
    'collection-confirmation__footer__helper-text--swiping': isSwiping,
  });

  const handleFetchOrder = useCallback(() => {
    dispatch(fetchOrder({ params: orderId, query: { venueId } }));
  }, [dispatch, orderId, venueId]);

  const handleGoBackToCollectionPage = useCallback(() => {
    history.push(`/collection/${venueId}/${orderId}`);
  }, [venueId, orderId, history]);

  const handleAcceptOrder = async () => {
    try {
      const response = await dispatch(acceptOrder({ params: { orderId, venueId } })).unwrap();

      setIsNotificationVisible(true);

      return response;
    } catch (error) {
      console.error(error);

      const { message } = getHumanReadableErrorMessage(error);
      notify({ heading: message, type: toast.TYPE.ERROR });

      return error;
    }
  };

  const handleGoToConfirmationPage = useCallback(() => {
    history.push(`/confirmation/${venueId}/${orderId}?isCollection=true`);
  }, [venueId, orderId, history]);

  useEffect(() => {
    handleFetchOrder();
  }, [handleFetchOrder]);

  return orderData ? (
    <div className="collection-confirmation">
      <div className="collection-confirmation__title">
        <h1>Show this screen to the bartender</h1>
      </div>
      <p>DO NOT continue until you have your order</p>
      <div className="collection-confirmation__receipt">
        <CartItems items={orderCart} vendors={vendors} priorities={priorities} />
        <ConfirmationDetails isCollapsable={false} />
      </div>
      <div className="collection-confirmation__footer">
        <p className={helperTextClasses}>Bar staff please swipe this to the right</p>
        <div className="collection-confirmation__footer__ctas">
          <Swiper onSwiped={handleAcceptOrder} setSwipingStatus={setIsSwiping} />
          <Button type="button" white textCenter onClick={handleGoBackToCollectionPage}>
            Go back
          </Button>
        </div>
      </div>
      <Suspense fallback={<LoadingSpinner center />}>
        <FullScreenNotification
          type={types.INFO}
          text="Enjoy!"
          isVisible={isNotificationVisible}
          handleOnDismiss={handleGoToConfirmationPage}
        />
      </Suspense>
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(CollectionConfirmation);
