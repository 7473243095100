import React, { useContext } from 'react';
import { useFormContext } from 'react-hook-form';

import CheckoutContext from '../CheckoutContext';

import { ReactComponent as LockIcon } from '../../../assets/images/lock.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/images/chevron-right.svg';

import cySelectors from '../../../tests/cySelectors';

const CheckoutPaidUpUpsell = () => {
  const { isUserPaying, isBankedRequestInitiated, handleBankedOnSubmit } =
    useContext(CheckoutContext);
  const {
    formState: { isValid },
  } = useFormContext();

  return (
    <button
      type="button"
      className="checkout-paid-up-upsell"
      disabled={isUserPaying || !isValid || isBankedRequestInitiated}
      onClick={handleBankedOnSubmit}
      data-cy={cySelectors.CHECKOUT_PAID_UP_UPSELL}
    >
      <div className="checkout-paid-up-upsell__icon">
        <LockIcon />
      </div>
      <p className="checkout-paid-up-upsell__title">Securely pay via your bank app</p>
      <p>Keep your card safe and pay instantly with your bank app!</p>
      <ArrowRightIcon className="checkout-paid-up-upsell__arrow" />
    </button>
  );
};

export default CheckoutPaidUpUpsell;
