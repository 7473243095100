import getNewUserPreferencesString from './getNewUserPreferencesString';

const upsertUserPreferences = (payload) => {
  const userPreferences = JSON.parse(window.localStorage.getItem('user_preferences'));
  const newUserPreferences = getNewUserPreferencesString(userPreferences, payload);

  window.localStorage.setItem('user_preferences', newUserPreferences);
};

export default upsertUserPreferences;
