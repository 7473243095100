import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'vouchers',
  ENDPOINT: 'vouchers',
};

const fetchVouchers = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchVouchers];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchVouchers };
export default reducer;
