import React from 'react';

import { ReactComponent as AllergensIcon } from '../../assets/images/allergens-icon.svg';

import cySelectors from '../../tests/cySelectors';

const ItemAllergens = ({ allergensContained }) => (
  <div className="item-allergens" data-cy={cySelectors.ITEM_ALLERGENS}>
    <AllergensIcon />
    <strong>Contains:</strong>
    <div className="item-allergens__list">
      {allergensContained.map(({ name, requirementId }) => (
        <span key={requirementId}>{name}</span>
      ))}
    </div>
  </div>
);

export default ItemAllergens;
