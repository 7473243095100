import React from 'react';
import { Link } from 'react-router-dom';

import formatCurrency from '../../../utils/formatCurrency';

const VouchersListTable = ({ vouchers }) => (
  <table className="vouchers-list__table">
    <thead>
      <tr>
        <th>Code</th>
        <th>Amount</th>
        <th>Balance</th>
      </tr>
    </thead>
    <tbody>
      {vouchers.map(({ voucherCode, amount, balance }) => {
        const formattedAmount = formatCurrency(amount);
        const formattedBalance = formatCurrency(balance);
        return (
          <tr key={voucherCode}>
            <td>
              <Link to={(location) => `${location.pathname}/${voucherCode}`}>{voucherCode}</Link>
            </td>
            <td>{formattedAmount}</td>
            <td>{formattedBalance}</td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

export default VouchersListTable;
