const highlightedProvidersList = [
  '4ff5b295-391d-4e41-a4b8-d85db4d91c28', // dev - Mock Bank
  'd984cf2e-61a0-4100-bb48-08fff0799bcc', // Monzo
  '81be4774-5a42-47e0-b6e2-9e6bdcb92b11', // Revolut
  'cbf2e4fb-ca33-4276-b5dc-2b9ed00fd0aa', // Barclays Personal
  '1aa5afd8-aaf6-4aaa-bc46-983b777a9cda', // HSBC Personal
  'bb0d5b15-9597-448a-b98d-a6f49df16039', // Lloyds Personal
  'b7ddab23-528f-43c0-a380-12b77108925b', // RBS
  '72326f11-e10b-4ff0-a7e9-1e27b82d7f64', // NatWest Personal
  '8218d338-8769-4ccc-bad0-623e66c0adbc', // Starling
];
const excludeProvidersList = [];

const filterProviders = (providerBrands = []) => {
  const allProviders = providerBrands.flatMap(({ providers, logo }) =>
    providers.map((provider) => ({ ...provider, logo })),
  );
  const highlightedProviders = allProviders.filter(
    ({ id }) => highlightedProvidersList.includes(id) && !excludeProvidersList.includes(id),
  );
  const otherProviders = allProviders.filter(
    ({ id }) => !highlightedProvidersList.includes(id) && !excludeProvidersList.includes(id),
  );
  return { highlightedProviders, otherProviders, allProviders };
};

export default filterProviders;
