import React, {
  useCallback,
  useEffect,
  useState,
  lazy,
  Suspense,
  useMemo,
  useContext,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import flatten from 'lodash.flatten';
import qs from 'query-string';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useQuery from '../../hooks/useQuery';

import MenuNavigationCategories from './MenuNavigationCategories';
import MenuFilters from './MenuFilters';
import MenuFiltersBar from './MenuFiltersBar';
import MenuPageHeader from './MenuPageHeader';
import MenuModal from './MenuModal';
import MenuPageHeaderDesktop from './MenuPageHeaderDesktop';
import getFilteredCategoryItems from './MenuFilters/getFilteredCategoryItems';
import getFilteredCategoryItemsNumber from './MenuFilters/getFilteredCategoryItemsNumber';
import MenuEmptyResults from './MenuEmptyResults';

import VenueTakeAwayWarning from '../../components/VenueTakeAwayWarning';
import ServiceStatus from '../../components/ServiceStatus';
import HeroBanner from '../../components/HeroBanner';
import LoadingSpinner from '../../components/LoadingSpinner';
import FooterCta from '../../components/FooterCta';
import MenuCategories from '../../components/MenuCategories';
import VoucherCTA from '../../components/VoucherCTA';
import MenuItemsSearch from '../../components/MenuItemsSearch';
import AppContext from '../../components/App/AppContext';
import HeroBannerDesktop from '../../components/HeroBannerDesktop';
import OpeningTimesModal from '../../components/OpeningTimesModal';

import { fetchMenu } from '../../store/slices/menu';
import { fetchUpsells, resetUpsells } from '../../store/slices/upsells';
import { addToCart, changeUpsellQuantity } from '../../store/slices/order';

import getAvailableCategoryMenuItems from '../../utils/getAvailableCategoryMenuItems';
import getCurrentMenuVenueId from '../../utils/getCurrentMenuVenueId';
import formatCurrency from '../../utils/formatCurrency';
import getCategoryItems from '../../utils/getCategoryItems';

import getFinalMenu from './getFinalMenu';
import getAllergensContainedPerItem from './getAllergensContainedPerItem';
import getCurrentMenuPriorities from './getCurrentMenuPriorities';

import cySelectors from '../../tests/cySelectors';
import getItemCount from './getItemCount';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';
import getUpsellsFromMenuData from '../../components/ItemUpsells/getUpsellsFromMenuData';
import UpsellModal from './UpsellModal';
import sendGAPromotionClicked from '../../utils/tracking/GA/sendGAPromotionClicked';
import sendGAItemListViewed from '../../utils/tracking/GA/sendGAItemListViewed';
import sendGAItemAddToCart from '../../utils/tracking/GA/sendGAItemAddToCart';

const MenuCampaignSlider = lazy(() => import('./MenuCampaignsSlider'));

const Menu = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentUpsellItem, setCurrentUpsellItem] = useState(null);
  const history = useHistory();
  const queries = useQuery();
  const dispatch = useDispatch();
  const { search } = history.location;
  const { orderId, venueId } = useParams();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: menuData, loading: menuLoading } = useSelector((state) => state.menu);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { data: cartData } = useSelector((state) => state.cart);
  const { data: upsellsData, loading: upsellsLoading } = useSelector((state) => state.upsells);
  const { upsells } = upsellsData || {};
  const { data: dietaryRequirementsData } = useSelector((state) => state.dietaryRequirements);
  const { total } = orderData || {};
  const {
    isOpen,
    isTakeawayEnabled,
    name: venueName,
    vouchersEnabled,
    isMultiMenuVenue,
    vendors,
    personalisation,
    closeTime,
    isClosedToday,
    openTime,
    openings,
    priorities,
    currency,
    upsells: upsellsStatus = {},
  } = venueData || {};
  const isItemUpsellsEnabled = upsellsStatus.item;
  const { bannerImgUrl } = personalisation || {};
  const { campaigns, serviceStatus, menu = [], menuName, venueId: childVenueId } = menuData || {};
  const upsellsMenuItems = getUpsellsFromMenuData(upsells, menu);
  const [currentUpsellMenuItems, setCurrentUpsellMenuItems] = useState([]);
  const { drinksDelay, foodDelay, isDrinksServiceOn, isFoodServiceOn } = serviceStatus || {};
  const { itemId: currentItemId, categoryId: currentItemCategoryId } = currentItem || {};
  const items = menuData && flatten(menuData.menu.map(({ categoryItems }) => categoryItems));

  const {
    setAvailableCategoryMenuItems,
    searchedCategoryMenuItems,
    isSearchOpen,
    setIsSearchOpen,
    isFiltersOpen,
    setIsFiltersOpen,
    filters,
    setFilters,
    searchQuery,
    setSearchQuery,
    isIframeBannerVisible,
    isIframeMode,
    viewport,
    hasFiltersSelected,
    isOpeningTimesOpen,
    setIsOpeningTimesOpen,
    displayUniversalConfirmation,
    displayUniversalLoading,
    hideUniversalConfirmation,
    hasMvBannerImg,
  } = useContext(AppContext);
  const { isTablet, isDesktop } = viewport;
  const isTabletOrDesktop = isTablet || isDesktop;
  const hasBanner = isIframeMode ? isIframeBannerVisible : !!bannerImgUrl || hasMvBannerImg;
  const availableCategoryMenuItems = useMemo(() => getCategoryItems(menu), [menu]);
  const filteredCategoryItems = useMemo(
    () => getFilteredCategoryItems(menu, filters.dietary),
    [filters.dietary, menu],
  );
  const filteredCategoryItemsNumber = getFilteredCategoryItemsNumber(filteredCategoryItems);
  const hasSearchResults = !!searchedCategoryMenuItems.length;
  const hasSearchedWithNoResults = !hasSearchResults && !!searchQuery;
  const finalMenu = getFinalMenu(
    searchedCategoryMenuItems,
    filteredCategoryItems,
    hasFiltersSelected,
    menu,
  );
  const hasNoFilteredResults = hasFiltersSelected && !filteredCategoryItems.length;
  const isMenuCategoryItemsVisible =
    !!finalMenu.length && !hasSearchedWithNoResults && !hasNoFilteredResults;
  const formattedTotal = formatCurrency(total);
  const isCartEmpty = !orderData?.cart?.length;
  const hasDrinkServiceAndIsDelayed = !!drinksDelay && isDrinksServiceOn;
  const hasFoodServiceAndIsDelayed = !!foodDelay && isFoodServiceOn;
  const hasServiceAndISDelayed = hasDrinkServiceAndIsDelayed || hasFoodServiceAndIsDelayed;
  const hasService = isDrinksServiceOn || isFoodServiceOn;
  const isServiceStatusVisible = isOpen && (hasServiceAndISDelayed || !hasService);
  const availableNavigationCategories = getAvailableCategoryMenuItems(menu);
  const isBrandupEnabled = !!campaigns?.length;
  const itemIdQuery = queries.get('itemId');
  const categoryIdQuery = queries.get('categoryId');
  const modalOpenQuery = queries.get('modalOpen');
  const isViewOnly = queries.get('isMenuView');
  const vendorIdQuery = parseInt(queries.get('vendorId'), 10) || null;
  const isModalQueryAvailable = itemIdQuery && modalOpenQuery;
  const isTakeAwayBannerVisible = !isOpen && isTakeawayEnabled && !isViewOnly;
  const isInfoBannersVisible = isTakeAwayBannerVisible || isServiceStatusVisible;
  const finalMenuVenueId = getCurrentMenuVenueId(vendors, vendorIdQuery, venueId, isMultiMenuVenue);
  const vendorName = vendors?.find((vendor) => vendor.id === vendorIdQuery)?.venueName;
  const finalVenueName = vendorName || venueData?.name || 'ServedUp';
  const isHeroBannerVisibile = isIframeMode
    ? isIframeBannerVisible
    : !isSearchOpen && (bannerImgUrl || hasMvBannerImg);
  const hasVendors = !!vendors?.length;
  const isVouchersCtaVisible =
    vouchersEnabled && !hasSearchedWithNoResults && !hasNoFilteredResults;
  const isBackButtonVisible = vendorIdQuery && hasVendors;
  const currentItemAllergens = currentItem?.dietaryRequirements?.filter(
    ({ type }) => type === 'allergen',
  );
  const allergensContained = getAllergensContainedPerItem(filters.allergens, currentItemAllergens);
  const isCampaignSliderVisible = isBrandupEnabled && !isSearchOpen;
  const finalPriorities = getCurrentMenuPriorities(vendors, vendorIdQuery, priorities);
  const numberOfShownUpsells = Number(window.sessionStorage.getItem('numberOfShownUpsells')) || 0;
  const maximumUpsellsToShow = 2;
  const throwError = useAsyncServedUpError();
  const classes = clsx('menu', {
    'menu--search-open': isSearchOpen,
  });

  const handleScrollToCategory = (categoryId) => {
    const targetCategoryElement = document.querySelector(
      `[data-servedup-category-id="${categoryId}"]`,
    );

    if (targetCategoryElement) {
      const { offsetTop } = targetCategoryElement;
      const menuNavigationHeight = document.querySelector(
        '.menu__navigation__categories',
      )?.clientHeight;
      const headerElementHeight = document.querySelector('.menu-page-header')?.clientHeight;
      const headerElementDesktopHeight = document.querySelector(
        '.menu-page-header__deskop',
      )?.clientHeight;
      const customOffset =
        menuNavigationHeight + (headerElementHeight || headerElementDesktopHeight) + 25;
      const top = offsetTop - customOffset;

      window.scrollTo({ top, behavior: 'smooth' });
    }
  };

  const handleAppendItemQueryParam = useCallback(
    ({ itemId, categoryId }) => {
      const itemQueryParams = qs.stringify({
        modalOpen: true,
        itemId,
        categoryId,
      });

      history.push({
        search: `${search}&${itemQueryParams}`,
      });
    },
    [history, search],
  );

  const handleResetItemQueryParam = useCallback(() => {
    queries.delete('itemId');
    queries.delete('categoryId');
    queries.delete('modalOpen');

    const finalResetQuery = queries.toString();

    history.replace({
      search: finalResetQuery ? `?${finalResetQuery}` : null,
    });
  }, [history, queries]);

  const resetSearchQuery = useCallback(() => {
    setIsSearchOpen(false);
    setSearchQuery('');
  }, [setIsSearchOpen, setSearchQuery]);

  const handleModalToggle = useCallback(
    (item) => {
      const { itemId, categoryId } = item;

      setCurrentItem(item);
      setIsModalOpen(!isModalOpen);
      handleAppendItemQueryParam({ itemId, categoryId });
      resetSearchQuery();
    },
    [handleAppendItemQueryParam, isModalOpen, resetSearchQuery],
  );

  const getItemFromItemIdAndCategoryId = useCallback(
    ({ itemId, categoryId }) => {
      if (!categoryId) {
        return items.find((item) => item.itemId === itemId);
      }

      const category = menu.find(({ categoryId: menuCategoryId }) => menuCategoryId === categoryId);
      const { categoryItems } = category;
      const targetItem = categoryItems.find((item) => item.itemId === itemId);

      return targetItem;
    },
    [items, menu],
  );

  const handleGetItemCount = useCallback(
    ({ item }) => getItemCount({ item, cart: cartData, venueId: finalMenuVenueId }),
    [cartData, finalMenuVenueId],
  );

  const handleOpenModalFromQuery = useCallback(() => {
    const item = getItemFromItemIdAndCategoryId({
      itemId: itemIdQuery,
      categoryId: categoryIdQuery,
    });

    setCurrentItem(item);
    setIsModalOpen(true);
  }, [getItemFromItemIdAndCategoryId, itemIdQuery, categoryIdQuery]);

  const handleModalToggleFromItemId = useCallback((item) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  }, []);

  const handleNavigateToReviewPage = useCallback(() => {
    history.push(`/review/${venueId}/${orderId}`);
  }, [history, orderId, venueId]);

  const handleCampaignBannerOnClick = useCallback(
    (itemId, campaignBannerLink, campaignId) => {
      if (campaignBannerLink) {
        window.open(`${campaignBannerLink}?origin=${encodeURIComponent(window.location.href)}`);

        return;
      }

      sendGAPromotionClicked(campaignId, itemId);

      handleAppendItemQueryParam({ itemId });
      handleModalToggleFromItemId(itemId);
    },
    [handleAppendItemQueryParam, handleModalToggleFromItemId],
  );

  const handleCloseModal = useCallback(() => {
    handleResetItemQueryParam();
    setIsModalOpen(false);

    dispatch(resetUpsells());
  }, [handleResetItemQueryParam, dispatch]);

  const handleCloseUpsellModal = useCallback(() => {
    setCurrentUpsellMenuItems([]);
    setCurrentUpsellItem(null);
  }, []);

  const handleToggleFiltersModal = () => {
    setIsFiltersOpen(!isFiltersOpen);
  };

  const handleOpenOpeningHoursModal = () => {
    setIsOpeningTimesOpen(true);
  };

  const handleResetFilters = () => {
    setFilters({
      allergens: [],
      dietary: [],
    });
  };

  const handleFetchUpsells = useCallback(() => {
    dispatch(fetchUpsells({ params: { orderId, itemId: currentItemId }, query: { menuName } }));
  }, [currentItemId, orderId, menuName, dispatch]);

  const handleModalSubmit = async (itemQuantity, values) => {
    const { itemOption, modifiers: checkModifiers, priority, notes } = values;
    const itemModifiers =
      (checkModifiers && Object.keys(checkModifiers).map((key) => checkModifiers[key])) || [];
    const newItem = {
      itemId: currentItemId,
      itemOption,
      modifiers: itemModifiers,
      priority,
      categoryId: currentItemCategoryId,
      notes,
    };
    const payload = {
      venueId,
      childVenueId,
      newItem,
      itemQuantity,
      cart: cartData,
    };

    try {
      displayUniversalLoading();
      await dispatch(addToCart({ params: orderId, payload })).unwrap();
      await displayUniversalConfirmation();

      const fullItem = items.find((item) => item.itemId === currentItemId);
      sendGAItemAddToCart({ cart: [fullItem], currency, venueId });

      handleCloseModal();
      setCurrentUpsellMenuItems(currentUpsellMenuItems.length > 0 ? [] : upsellsMenuItems);
      setCurrentUpsellItem(currentItem);
      if (numberOfShownUpsells < maximumUpsellsToShow) setIsUpsellModalOpen(true);

      window.sessionStorage.setItem('numberOfShownUpsells', numberOfShownUpsells + 1);
    } catch (error) {
      hideUniversalConfirmation();
      handleCloseModal();
      throwError({ error, venueId, orderId });
    }
  };

  const handleChangeUpsellQuantity = useCallback(
    async ({ quantityChange, item }) => {
      try {
        dispatch(
          changeUpsellQuantity({
            params: orderId,
            payload: {
              venueId,
              cart: cartData,
              item,
              quantityChange,
              upsoldFrom: 'ITEM',
            },
          }),
        );
      } catch (error) {
        throwError({ error, venueId, orderId });
      }
    },
    [cartData, dispatch, orderId, throwError, venueId],
  );

  useEffect(() => {
    if (menuData && currency && venueId) {
      sendGAItemListViewed({ cart: items, currency, venueId });
    }
  }, [currency, items, menuData, venueId]);

  useEffect(() => {
    if (isModalOpen && isItemUpsellsEnabled) {
      handleFetchUpsells(currentItemId);
    }
  }, [isModalOpen, isItemUpsellsEnabled, handleFetchUpsells, currentItemId]);

  useEffect(() => {
    if (!isModalQueryAvailable && isModalOpen) {
      handleCloseModal();
    }
  }, [handleCloseModal, isModalOpen, isModalQueryAvailable]);

  useEffect(() => {
    if (availableCategoryMenuItems.length)
      setAvailableCategoryMenuItems(availableCategoryMenuItems);
  }, [availableCategoryMenuItems, setAvailableCategoryMenuItems]);

  useEffect(() => {
    if (menuData && isModalQueryAvailable) {
      handleOpenModalFromQuery();
    }
  }, [isModalQueryAvailable, handleOpenModalFromQuery, menuData]);

  useEffect(() => {
    if (vendorIdQuery && isMultiMenuVenue) {
      dispatch(fetchMenu({ params: finalMenuVenueId }));
    }
  }, [dispatch, finalMenuVenueId, isMultiMenuVenue, vendorIdQuery]);

  return (
    <>
      <MenuItemsSearch />
      {!isTabletOrDesktop ? (
        <MenuPageHeader
          title={finalVenueName}
          hasBanner={hasBanner}
          hasBackBtn={isBackButtonVisible}
        />
      ) : (
        <MenuPageHeaderDesktop
          title={finalVenueName}
          hasBannerImg={hasBanner}
          isSearchOpen={isSearchOpen}
          venueId={venueId}
          hasVoucherCta={vouchersEnabled}
          hasBackBtn={isBackButtonVisible}
        />
      )}
      {venueData && menuData && orderData && !menuLoading ? (
        <div className={classes}>
          {isInfoBannersVisible && (
            <div className="menu__info-banners">
              {isTakeAwayBannerVisible && <VenueTakeAwayWarning />}
              {isServiceStatusVisible && <ServiceStatus />}
            </div>
          )}

          {isHeroBannerVisibile &&
            (!isTabletOrDesktop ? (
              <HeroBanner text={finalVenueName} />
            ) : (
              <HeroBannerDesktop
                text={finalVenueName}
                isOpen={isOpen}
                isClosedToday={isClosedToday}
                closeTime={closeTime}
                openTime={openTime}
                handleOpenOpeningHoursModal={handleOpenOpeningHoursModal}
              />
            ))}
          {!isSearchOpen && (
            <div className="menu__navigation">
              {dietaryRequirementsData && (
                <MenuFilters
                  hasFiltersSelected={hasFiltersSelected}
                  setFilters={setFilters}
                  isFiltersOpen={isFiltersOpen}
                  handleToggleFiltersModal={handleToggleFiltersModal}
                  filteredCategoryItemsNumber={filteredCategoryItemsNumber}
                  dietaryRequirementsData={dietaryRequirementsData}
                />
              )}
              <MenuNavigationCategories
                handleScrollToCategory={handleScrollToCategory}
                categories={availableNavigationCategories}
              />
            </div>
          )}
          {hasFiltersSelected && (
            <MenuFiltersBar
              filters={filters}
              handleToggleModal={handleToggleFiltersModal}
              handleResetFilters={handleResetFilters}
              dietaryRequirementsData={dietaryRequirementsData}
            />
          )}
          <Suspense fallback={<LoadingSpinner />}>
            {isCampaignSliderVisible && (
              <MenuCampaignSlider
                campaigns={campaigns}
                handleCampaignBannerOnClick={handleCampaignBannerOnClick}
              />
            )}
          </Suspense>
          {isMenuCategoryItemsVisible && (
            <MenuCategories
              menuCategoryItems={finalMenu}
              handleGetItemCount={handleGetItemCount}
              handleModalToggle={handleModalToggle}
              allergensSelected={filters.allergens}
            />
          )}
          {hasSearchedWithNoResults && (
            <MenuEmptyResults
              title="No items match"
              text="Try changing your search"
              btnText="Reset search"
              handleResetQuery={resetSearchQuery}
              dataCy={cySelectors.MENU_ITEMS_SEARCH_NO_RESULTS}
            />
          )}
          {hasNoFilteredResults && (
            <MenuEmptyResults
              title="No items match"
              text="Try changing or removing some of your filters"
              btnText="Clear filters"
              handleResetQuery={handleResetFilters}
              dataCy={cySelectors.MENU_ITEMS_FILTERS_NO_RESULTS}
            />
          )}
          {!isCartEmpty && (
            <FooterCta
              onClick={handleNavigateToReviewPage}
              data-cy={cySelectors.SUBMIT_BTN}
              loading={orderLoading}
            >
              <span>Go to basket</span>
              <span>{formattedTotal}</span>
            </FooterCta>
          )}
          {isModalOpen && currentItem && (
            <MenuModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              handleCloseModal={handleCloseModal}
              handleOnSubmit={handleModalSubmit}
              currentItem={currentItem}
              isViewOnly={isViewOnly}
              allergensContained={allergensContained}
              priorities={finalPriorities}
            />
          )}
          {isUpsellModalOpen && (
            <UpsellModal
              isModalOpen={isUpsellModalOpen}
              setIsModalOpen={setIsUpsellModalOpen}
              handleCloseModal={handleCloseUpsellModal}
              allergensSelected={filters.allergens}
              upsellsLoading={upsellsLoading}
              upsells={currentUpsellMenuItems}
              item={currentUpsellItem}
              handleGetItemCount={handleGetItemCount}
              handleUpsellModalToggle={handleModalToggle}
              handleChangeUpsellQuantity={handleChangeUpsellQuantity}
            />
          )}
          {isVouchersCtaVisible && <VoucherCTA venueName={venueName} venueId={venueId} />}
          {isOpeningTimesOpen && (
            <OpeningTimesModal
              isModalOpen={isOpeningTimesOpen}
              setIsModalOpen={setIsOpeningTimesOpen}
              openingTimes={openings}
            />
          )}
        </div>
      ) : (
        <LoadingSpinner center />
      )}
    </>
  );
};

export default withHandleRoutesDispatch(Menu);
