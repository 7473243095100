import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import createGAConfig from '../../utils/tracking/GA/createGAConfig';
import createGAUserId from '../../utils/tracking/GA/createGAUserId';
import sendUserMetadata from '../../utils/tracking/hotjar/sendUserMetadata';
import isDev from '../../utils/isDev';

const HeadTracking = () => {
  const { data: orderData } = useSelector((state) => state.order);
  const { phone: orderPhone, orderId, venueId } = orderData || {};
  const localStorageDetailsForm = window.localStorage.getItem('detailsForm');
  // Wait for the orderData's (orderPhone) to be loaded before setting the GA user id,
  // fallback on local storage and ultimately
  // let GA handle it by not setting it.
  const userId = orderPhone ? createGAUserId(orderPhone, localStorageDetailsForm) : null;
  const gaConfig = JSON.stringify(createGAConfig(userId, isDev));

  useEffect(() => {
    sendUserMetadata(userId, venueId, orderId);
  }, [orderId, userId, venueId]);

  return (
    <Helmet>
      <script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}`}
      />
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_ID}', ${gaConfig});
        `}
      </script>
      <script>
        {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1939244,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
        `}
      </script>
    </Helmet>
  );
};

export default HeadTracking;
