import qs from 'query-string';

const getVersionedQueryStrings = (version, queries) => {
  const isEmptyQuery = !version && !queries;

  if (isEmptyQuery) {
    return '';
  }

  const finalVersionQuery = version ? { version } : '';
  const finalQuery = queries || '';
  const finalQueries =
    finalVersionQuery || finalQuery
      ? `?${qs.stringify(
          { ...queries, ...{ version } },
          {
            skipNull: true,
          },
        )}`
      : '';

  return finalQueries;
};

export default getVersionedQueryStrings;
