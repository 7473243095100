import React from 'react';
import { useSelector } from 'react-redux';

import formatCurrency from '../../../utils/formatCurrency';

import { ReactComponent as ChevronDown } from '../../../assets/images/chevron-down.svg';
import { ReactComponent as ChevronUp } from '../../../assets/images/chevron-up.svg';

import cySelectors from '../../../tests/cySelectors';

const ConfirmationDetails = ({ isCollapsed, isCollapsable = true, handleToggleCollapsed }) => {
  const { data: venueData } = useSelector((state) => state.venue);
  const { serviceChargeLabel, additionalChargeLabel } = venueData || {};
  const { data: orderData } = useSelector((state) => state.order);
  const {
    name,
    email,
    tableName,
    notes,
    subtotal,
    serviceSubtotal,
    total,
    paymentMethod,
    paymentService,
    pickupDay,
    payServiceCharge,
    payAdditionalCharge,
    additionalChargeSubtotal,
    pickupTime,
    systemCustomerNotes,
    totalDiscount,
    vouchers = [],
    orderDisplayId,
  } = orderData || {};

  const formattedSubtotal = formatCurrency(subtotal);
  const formattedTotal = formatCurrency(total);
  const formattedServiceCharge = formatCurrency(serviceSubtotal);
  const formattedAdditionalCharge = formatCurrency(additionalChargeSubtotal);
  const formattedPickupTime = new Date(pickupDay).toLocaleString('en-GB', {
    weekday: 'long',
  });
  const pickupTimeText = `Your order will be delivered: ${formattedPickupTime} between: ${pickupTime}`;
  const paidViaText =
    paymentMethod === 'OPEN_BANKING'
      ? 'Paid by Bank :'
      : `Paid via ${paymentMethod}, ${paymentService}`;
  const hasDiscount = !!totalDiscount;
  const hasVoucher = vouchers && vouchers.length !== 0;
  const formattedDiscount = formatCurrency(totalDiscount);
  const finalServiceChargeLabel = serviceChargeLabel || 'Service charge';
  const finalAdditionalChargeLabel = additionalChargeLabel || 'Additional charge';
  const formattedVoucher = hasVoucher ? formatCurrency(vouchers[0].amountUsed) : 0;

  return (
    <div className="confirmation__details">
      {!isCollapsed && (
        <>
          <div
            className='confirmation__details__field--bold'
          >
           Order: {orderDisplayId}
          </div>
          <div
            className='confirmation__details__field--bold'
            data-cy={cySelectors.CONFIRMATION_NAME}
          >
            {name}
          </div>
          <div
            className='confirmation__details__field--black'
            data-cy={cySelectors.CONFIRMATION_EMAIL}
          >
            {email}
          </div>
          {(payServiceCharge || hasDiscount) && (
            <div
              data-cy={cySelectors.CONFIRMATION_SUBTOTAL}
              className='confirmation__details__field--double'
            >
              <span>Subtotal:</span>
              <span>{formattedSubtotal}</span>
            </div>
          )}
          {payServiceCharge && (
            <div
              className='confirmation__details__field--double'
              data-cy={cySelectors.CONFIRMATION_SERVICE_CHARGE}
            >
              <span>{finalServiceChargeLabel}:</span>
              <span>{formattedServiceCharge}</span>
            </div>
          )}
          {payAdditionalCharge && (
            <div
              className='confirmation__details__field--double'
              data-cy={cySelectors.CONFIRMATION_ADDITIONAL_CHARGE}
            >
              <span>{finalAdditionalChargeLabel}:</span>
              <span>{formattedAdditionalCharge}</span>
            </div>
          )}
          {hasDiscount && (
            <div className='confirmation__details__field--double'>
              <span>Offer:</span>
              <span>-{formattedDiscount}</span>
            </div>
          )}
          {hasVoucher && (
            <div className='confirmation__details__field--double'>
              <span>Voucher Used:</span>
              <span>-{formattedVoucher}</span>
            </div>
          )}
          <div
            className='confirmation__details__field--double'
            data-cy={cySelectors.CONFIRMATION_TOTAL}
          >
            <span>Total:</span>
            <span>{formattedTotal}</span>
          </div>
          <div
            className='confirmation__details__field--double'
            data-cy={cySelectors.CONFIRMATION_TABLE}
          >
            <span>Table: {tableName}</span>
            {paymentMethod && paymentService && (
              <span data-cy={cySelectors.CONFIRMATION_PAID_VIA}>{paidViaText}</span>
            )}
          </div>
          {pickupTime && (
            <div
              className='confirmation__details__field'
              data-cy={cySelectors.CONFIRMATION_TAKEAWAY_TIME}
            >
              {pickupTimeText}
            </div>
          )}
          {notes && (
            <div className='confirmation__details__field' data-cy={cySelectors.CONFIRMATION_NOTES}>
              Notes: {notes}
            </div>
          )}
          {systemCustomerNotes && (
            <div className='confirmation__details__field'>{systemCustomerNotes}</div>
          )}
        </>
      )}
      {isCollapsable && (
        <button
          data-cy={cySelectors.CONFIRMATION_DETAILS_TOGGLE}
          className='confirmation__details__button'
          type="button"
          onClick={handleToggleCollapsed}
        >
          <span> {!isCollapsed ? 'show less' : 'transaction details'}</span>
          {!isCollapsed ? <ChevronUp /> : <ChevronDown />}
        </button>
      )}
    </div>
  );
};

export default ConfirmationDetails;
