const getDetailsFormFromLocalStorage = () => {
  try {
    const localStorageDetailsForm = localStorage.getItem('detailsForm');
    const sessionStorageDetailsForm = sessionStorage.getItem('detailsForm');
    const localParsed = localStorageDetailsForm && JSON.parse(localStorageDetailsForm);
    const sessionParsed = sessionStorageDetailsForm && JSON.parse(sessionStorageDetailsForm);

    return {
      ...localParsed,
      ...sessionParsed,
    };
  } catch (error) {
    console.error(error);

    return {};
  }
};

export default getDetailsFormFromLocalStorage;
