import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements, useStripe, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useFormContext } from 'react-hook-form';

import AppContext from '../../../components/App/AppContext';
import LoadingSpinner from '../../../components/LoadingSpinner';

import CheckoutContext from '../CheckoutContext';

import {
  createVouchersCheckoutRequest,
  createCheckoutRequest,
  createVouchersCheckoutConfirmRequest,
  createCheckoutConfirmRequest,
} from '../../../store/slices/checkout';
import { safeMultiply } from '../../../utils/safeArithmetics';
import Logger from '../../../utils/Logger';

import useAsyncServedUpError from '../../../hooks/useAsyncServedUpError';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_TOKEN);

const CheckoutStripePayment = ({ setIsPaymentRequestPossible }) => (
  <Elements stripe={stripePromise}>
    <CheckoutStripeForm setIsPaymentRequestPossible={setIsPaymentRequestPossible} />
  </Elements>
);

const CheckoutStripeForm = ({ setIsPaymentRequestPossible }) => {
  const stripe = useStripe();
  const { getValues } = useFormContext();
  const { data: orderData } = useSelector((state) => state.order);
  const { notes, orderId, venueId, venueName } = orderData || {};
  const dispatch = useDispatch();
  const {
    total,
    finalTotal,
    isTotalZero,
    handlePaymentError,
    isQrPayment,
    isUserPaying,
    setIsUserPaying,
    isVouchers,
  } = useContext(CheckoutContext);
  const { isMarketingEnabled, isPayServiceCharge, isPayAdditionalCharge } = useContext(AppContext);
  const [paymentRequest, setPaymentRequest] = useState(null);

  const subunitFinalTotal = safeMultiply(finalTotal, 100);
  const subunitTotal = safeMultiply(total, 100);
  const throwError = useAsyncServedUpError();
  const [isThisPaymentMethodClicked, setIsThisPaymentMethodClicked] = useState(false);

  const handleUpdatePaymentRequest = useCallback(() => {
    paymentRequest.update({
      total: {
        label: venueName,
        amount: subunitFinalTotal,
      },
    });
  }, [paymentRequest, subunitFinalTotal, venueName]);

  const handleCreateCheckoutConfirmRequest = useCallback(async () => {
    try {
      const finalCreateCheckoutConfirmRequest = isVouchers
        ? createVouchersCheckoutConfirmRequest
        : createCheckoutConfirmRequest;

      await dispatch(
        finalCreateCheckoutConfirmRequest({ params: orderId, payload: { venueId } }),
      ).unwrap();
    } catch (error) {
      throwError({ error, venueId, orderId });
      setIsUserPaying(false);
      setIsThisPaymentMethodClicked(false);
    }
  }, [isVouchers, dispatch, orderId, venueId, throwError, setIsUserPaying]);

  const handleCardPaymentFailure = useCallback(
    (error) => {
      handlePaymentError({ error });
    },
    [handlePaymentError],
  );

  const handlePaymentRequestButtonOnClick = useCallback(() => {
    if (!isUserPaying) {
      setIsUserPaying(true);
      setIsThisPaymentMethodClicked(true);
      handleUpdatePaymentRequest();
    }
  }, [handleUpdatePaymentRequest, isUserPaying, setIsUserPaying]);

  const handlePaymentFailure = useCallback(async (event) => {
    event.complete('fail');
  }, []);

  const handlePaymentConfirmation = useCallback(
    async ({ clientSecret, event, isActionRequired }) => {
      try {
        const { id } = event?.paymentMethod;
        const data = { payment_method: id };
        const options = { handleActions: isActionRequired };

        const cardPaymentConfirmation = await stripe.confirmCardPayment(
          clientSecret,
          data,
          options,
        );
        const { error } = cardPaymentConfirmation;

        if (error) {
          handleCardPaymentFailure(error);
        }

        handleCreateCheckoutConfirmRequest();
      } catch (error) {
        Logger.error(error);
        handlePaymentFailure(event);
        handlePaymentError({ error });
      }
    },
    [
      handleCardPaymentFailure,
      handleCreateCheckoutConfirmRequest,
      handlePaymentError,
      handlePaymentFailure,
      stripe,
    ],
  );

  const handlePaymentMethod = useCallback(
    async (event) => {
      try {
        setIsUserPaying(true);
        setIsThisPaymentMethodClicked(true);
        const { pickupDay, pickupTime } = getValues();
        const payload = {
          venueId,
          notes,
          payServiceCharge: isPayServiceCharge,
          payAdditionalCharge: isPayAdditionalCharge,
          allowMarketing: isMarketingEnabled,
          pickupDay,
          pickupTime,
          isQrPayment,
        };
        const finalCreateCheckoutRequest = isVouchers
          ? createVouchersCheckoutRequest
          : createCheckoutRequest;

        const { clientSecret, paymentIntent } = await dispatch(
          finalCreateCheckoutRequest({ params: orderId, payload }),
        ).unwrap();
        const isActionRequired = paymentIntent?.status === 'requires_action';

        handlePaymentConfirmation({ clientSecret, event, isActionRequired });
        event.complete('success');
      } catch (error) {
        Logger.error(error);
        handlePaymentFailure(event);
        handlePaymentError({ error });
      }
    },
    [
      setIsUserPaying,
      getValues,
      venueId,
      notes,
      isPayServiceCharge,
      isPayAdditionalCharge,
      isMarketingEnabled,
      isQrPayment,
      isVouchers,
      dispatch,
      orderId,
      handlePaymentConfirmation,
      handlePaymentFailure,
      handlePaymentError,
    ],
  );

  const handleInitPaymentRequest = useCallback(async () => {
    const stripePaymentRequest = await stripe.paymentRequest({
      country: 'GB',
      currency: 'gbp',
      total: {
        label: venueName,
        amount: subunitTotal,
      },
    });

    const canMakePayment = await stripePaymentRequest.canMakePayment();

    if (canMakePayment) {
      setPaymentRequest(stripePaymentRequest);
      setIsPaymentRequestPossible(true);
    } else {
      setIsPaymentRequestPossible(false);
    }
  }, [setIsPaymentRequestPossible, stripe, subunitTotal, venueName]);

  const handleCancelPaymentMethod = useCallback(() => {
    setIsUserPaying(false);
    setIsThisPaymentMethodClicked(false);
  }, [setIsUserPaying]);

  useEffect(() => {
    if (paymentRequest) {
      // Apparently if you don't removeAllListeners() it will
      // trigger whatever function you pass it as many times as
      // paymentRequest changes its values...
      paymentRequest.removeAllListeners();
      paymentRequest.on('paymentmethod', handlePaymentMethod);
      paymentRequest.on('cancel', handleCancelPaymentMethod);
    }
  }, [handleCancelPaymentMethod, handlePaymentMethod, paymentRequest]);

  useEffect(() => {
    if (stripe) {
      handleInitPaymentRequest();
    }
  }, [handleInitPaymentRequest, stripe]);

  useEffect(() => {
    if (paymentRequest) {
      handleUpdatePaymentRequest();
    }
  }, [handleUpdatePaymentRequest, paymentRequest]);

  return (
    <>
      {paymentRequest &&
        !isTotalZero &&
        (!isUserPaying || !isThisPaymentMethodClicked ? (
          <div className="checkout__stripe">
            <PaymentRequestButtonElement
              onClick={handlePaymentRequestButtonOnClick}
              options={{ paymentRequest }}
            />
          </div>
        ) : (
          <div className="checkout__stripe">
            <LoadingSpinner small />
          </div>
        ))}
    </>
  );
};

export default CheckoutStripePayment;
