import * as yup from 'yup';

const VouchersFormSchema = yup.object().shape({
  amount: yup
    .number('Amount must be a number')
    .positive()
    .typeError('Amount must be a number')
    .min(10, 'Minimum voucher value is 10')
    .required('Please fill in a voucher value'),
  customExpiry: yup.bool().required(),
  description: yup.string().max(160, 'Description must be 160 characters or less'),
  startDate: yup.date().when('customExpiry', {
    is: true,
    then: yup.date().required('Please fill in a start date'),
    otherwise: yup.date().notRequired(),
  }),
  endDate: yup.date().when('customExpiry', {
    is: true,
    then: yup.date().required('Please fill in a end date'),
    otherwise: yup.date().notRequired(),
  }),
});

export default VouchersFormSchema;
