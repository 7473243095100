import getMenuItemFromId from './getMenuItemFromId';

const getUpsellsFromMenuData = (upsellsIds = [], menu = []) => {
  const upsells = upsellsIds.map((id) => {
    const item = getMenuItemFromId(id, menu);

    return item;
  });

  return upsells;
};

export default getUpsellsFromMenuData;
