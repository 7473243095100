const getCurrentMenuPriorities = (vendors, vendorIdQuery, priorities) => {
  const hasVendors = !!vendors?.length;

  if (hasVendors) {
    const vendorPriorities =
      vendors.find(({ id }) => parseInt(vendorIdQuery, 10) === id)?.priorities || [];

    return vendorPriorities;
  }

  return priorities;
};

export default getCurrentMenuPriorities;
