import React from 'react';

import Button from '../Button';

const FooterCta = ({ children, ...extraProps }) => (
  <div className="footer-cta">
    <div className="footer-cta__wrapper">
      <Button fullWidth {...extraProps}>
        {children}
      </Button>
    </div>
  </div>
);

export default FooterCta;
