import * as yup from 'yup';

const DetailsFormSchema = yup.object().shape({
  tableName: yup.object().typeError('Please select a table').required(),
  name: yup.string().required('Name is required'),
  email: yup.string().trim().email('Must be a valid email').required('Email is required'),
  phone: yup
    .string()
    .matches(new RegExp(/(\d|\s)+/))
    .required(),
});

export default DetailsFormSchema;
