import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';

import getUniqueItemId from './getUniqueItemId';

// TODO update backend to be able to collate quantities in BE
const getAddToCartVoucherPayload = (cart, newItem, newItemQuantity) => {
  const updatedCart = [...cart];
  const sanitizedNewItem = omitBy(newItem, (prop) => isNil(prop) || prop === '');

  const newUniqItemId = getUniqueItemId({ ...newItem });
  const targetItemIndex = updatedCart.findIndex(({ uniqItemId }) => uniqItemId === newUniqItemId);
  const targetItem = updatedCart[targetItemIndex];

  if (targetItem) {
    updatedCart[targetItemIndex] = {
      ...targetItem,
      quantity: targetItem.quantity + newItemQuantity,
    };

    return updatedCart;
  }

  const newItemPayload = {
    quantity: newItemQuantity,
    uniqItemId: newUniqItemId,
    ...sanitizedNewItem,
  };

  return [...cart, newItemPayload];
};

export default getAddToCartVoucherPayload;
