import React from 'react';

import FullscreenModal from '../../../components/FullscreenModal';
import Header from '../../../components/Header';
import CheckoutPaidUpUpsell from '../CheckoutPaidUpUpsell';

const CheckoutPayWithCardModal = ({
  isModalOpen,
  handleCloseModal,
  setIsModalOpen,
  isPaidUpVisible,
  isAmexEnabled,
  children,
}) => (
  <FullscreenModal
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
    onCloseModal={handleCloseModal}
  >
    <Header title="Card payment" />
    <div className="checkout-pay-with-card-modal">
      <strong>Credit / Debit card</strong>
      {!isAmexEnabled && (
        <p className="checkout-pay-with-card-modal__helper-text">Amex is not accepted</p>
      )}
      {children}
      {isPaidUpVisible && (
        <>
          <p className="checkout-pay-with-card-modal__divider">or</p>
          <CheckoutPaidUpUpsell />
        </>
      )}
    </div>
  </FullscreenModal>
);

export default CheckoutPayWithCardModal;
