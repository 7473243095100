import * as yup from 'yup';

const DetailsFormSchemaVouchers = yup.object().shape({
  name: yup.string().required('Name is required'),
  email: yup.string().trim().email().required('Email is required'),
  phone: yup
    .string()
    .matches(new RegExp(/(\d|\s)+/))
    .required(),
});

export default DetailsFormSchemaVouchers;
