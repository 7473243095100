import React, { useState } from 'react';
import clsx from 'clsx';
import cySelectors from '../../tests/cySelectors';

const infoBannerLevels = {
  INFO: 'info',
  WARN: 'warn',
  ERROR: 'error',
  SUCCESS: 'success',
};

const InfoBanner = ({ Icon, title, level = infoBannerLevels.INFO, children, ...extraProps }) => {
  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const { 'data-cy': dataCy } = extraProps;
  const finalDataCy = dataCy || cySelectors.MENU_INFO_BANNER;

  const classes = clsx('info-banner', {
    'info-banner--info': level === 'info',
    'info-banner--warn': level === 'warn',
    'info-banner--error': level === 'error',
    'info-banner--success': level === 'success',
    'info-banner--has-icon': Icon,
  });

  const handleOnClick = () => {
    setIsBannerClosed(true);
  };

  return (
    !isBannerClosed && (
      <div className={classes} {...extraProps} data-cy={finalDataCy}>
        {Icon && (
          <div className="info-banner__icon">
            <Icon />
          </div>
        )}
        <div className="info-banner__text">
          <p className="info-banner__text__title">{title}</p>
          {children}
        </div>
        <div className="info-banner__close">
          <button type="button" className="info-banner__close-btn" onClick={handleOnClick}>
            CLOSE
          </button>
        </div>
      </div>
    )
  );
};

export { InfoBanner as default, infoBannerLevels };
