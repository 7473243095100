import { createSlice } from '@reduxjs/toolkit';

const sliceHandler = ({ sliceName, reducers = [], extraReducers = [], extraState }) => {
  const combinedExtraReducers = {};
  const updatedReducers = {
    ...reducers,
    appendToStateData: (state, action) => {
      const { payload } = action;

      state.data = {
        ...state.data,
        ...payload,
      };
    },
    replaceStateData: (state, action) => {
      const { payload } = action;

      state.data = payload;
    },
  };

  for (const extraReducer of extraReducers) {
    combinedExtraReducers[extraReducer.fulfilled] = (state, action) => {
      const { payload } = action;
      state.data = payload;
      state.loading = false;
      state.error = false;
    };

    combinedExtraReducers[extraReducer.pending] = (state, _action) => {
      state.loading = true;
    };

    combinedExtraReducers[extraReducer.rejected] = (state, action) => {
      const { payload } = action;
      state.error = payload;
      state.loading = false;
    };
  }

  return createSlice({
    name: sliceName,
    initialState: {
      data: null,
      loading: false,
      error: false,
      ...extraState,
    },
    reducers: updatedReducers,
    extraReducers: combinedExtraReducers,
  });
};

export default sliceHandler;
