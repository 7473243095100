const ratingIcons = [
  {
    value: 1,
    icon: '😫',
    text: 'Bad',
  },
  {
    value: 2,
    icon: '😕',
    text: 'OK',
  },
  {
    value: 3,
    icon: '😐',
    text: 'Average',
  },
  {
    value: 4,
    icon: '🙂',
    text: 'Good',
  },
  {
    value: 5,
    icon: '😍',
    text: 'Great!',
  },
];

export default ratingIcons;
