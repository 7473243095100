import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'dietary-requirements',
  ENDPOINT: 'dietaryRequirements',
};

const sliceName = sliceArgs.SLICE_NAME;

const fetchDietaryRequirements = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const extraReducers = [fetchDietaryRequirements];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchDietaryRequirements };
export default reducer;
