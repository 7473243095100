import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CheckoutContext from '../CheckoutContext';
import PaymentSystems from '../PaymentSystems';
import CheckoutFormTakeAwaySchema from './CheckoutFormTakeAwaySchema';
import CheckoutCodeField from '../CheckoutCodeField';
import CheckoutFormFooter from '../CheckoutFormFooter';
import checkIfUserPaid from './checkIfUserPaid';
import CheckoutPaidUpModal from '../CheckoutPaidUpModal';

import Form from '../../../components/Form';
import FormFieldSelect from '../../../components/FormFieldSelect';
import AppContext from '../../../components/App/AppContext';

import useAsyncServedUpError from '../../../hooks/useAsyncServedUpError';

import { createPaidUpRequest } from '../../../store/slices/checkout';
import { fetchBankedSession } from '../../../store/slices/banked';
import { toggleServiceCharge } from '../../../store/slices/order';
import updateOrderPaidUpWithBank from '../../../api/banked.com';

import calculateFinalTotal from './calculateFinalTotal';

import getHumanReadableErrorMessage from '../../../utils/getHumanReadableErrorMessage';
import { errorCodes } from '../../../utils/errorCodes';
import sendGAPurchase from '../../../utils/tracking/GA/sendGAPurchase';
import sendGAPaymentInfo from '../../../utils/tracking/GA/sendGAPaymentInfo';
import Logger from '../../../utils/Logger';

import cySelectors from '../../../tests/cySelectors';
import { isMacLike } from '../../../utils/isMacLike';

const CheckoutForm = ({ isQrPayment, isVouchers, errorMessage }) => {
  const [pickupTimesOptionsTimeSlots, setPickupTimesOptionsTimeSlots] = useState(null);
  const [paymentError, setPaymentError] = useState(null);
  const [isUserPaying, setIsUserPaying] = useState(false);
  const [isCheckoutCardModalOpen, setIsCheckoutCardModalOpen] = useState(false);
  const [isApplePayDisabled, setIsApplePayDisabled] = useState(false);
  const [formInstance, setFormInstance] = useState({});
  const [checkoutCodeFormInstance, setCheckoutCodeFormInstance] = useState({});
  const [checkoutCodeSuccessResponse, setCheckoutCodeSuccessResponse] = useState(null);
  const [isBankedShowing, setIsBankedShowing] = useState(false);
  const [isBankedRequestInitiated, setIsBankedRequestInitiated] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isMarketingEnabled,
    isAdditionalChargeChecked,
    setIsAdditionalChargeChecked,
    isServiceChargeChecked,
    setIsServiceChargeChecked,
    setIsPayServiceCharge,
    setIsPayAdditionalCharge,
  } = useContext(AppContext);
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: pickupTimesData } = useSelector((state) => state.pickupTimes);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: checkoutData } = useSelector((state) => state.checkout);
  const { data: bankedData } = useSelector((state) => state.banked);
  const { state: bankedState, id: bankedId } = bankedData || {};
  const {
    tableName,
    total,
    serviceSubtotal,
    subtotal,
    totalDiscount,
    venueId,
    vouchers,
    orderId,
    notes,
    name,
    email,
    payServiceCharge,
    additionalCharge,
    isUserDetailsEditable,
    additionalChargeSubtotal,
  } = orderData || {};
  const { status: checkoutStatus, payment } = checkoutData || {};
  const { paymentSystems, serviceCharge, isAmexEnabled } = venueData || {};
  const { reset, getValues } = formInstance || {};
  const isDeclined = checkoutStatus === 'declined';
  const isUserPaid = checkIfUserPaid(checkoutStatus);
  const vouchersAmountUsed = vouchers?.reduce((acc, { amountUsed }) => acc + amountUsed, 0) || 0;
  const isServiceChargeDisabled = serviceCharge === 'DISABLED';
  const isAdditionalChargeDisabled = additionalCharge === 'DISABLED';
  const isServiceChargeMandatory = serviceCharge === 'MANDATORY';
  const isAdditionalChargeMandatory = additionalCharge === 'MANDATORY';

  const isPayServiceCharge =
    isServiceChargeMandatory || (isServiceChargeChecked && !isServiceChargeDisabled);
  const isPayAdditionalCharge =
    isAdditionalChargeMandatory || (isAdditionalChargeChecked && !isAdditionalChargeDisabled);

  const finalServiceSubtotal = isServiceChargeDisabled ? 0 : serviceSubtotal;
  const hasVouchers = !!vouchers?.length;
  const finalTotal = calculateFinalTotal(
    isPayServiceCharge,
    hasVouchers,
    total,
    subtotal,
    totalDiscount,
    finalServiceSubtotal,
    vouchersAmountUsed,
    isPayAdditionalCharge,
    additionalChargeSubtotal,
  );
  const hasServiceChargePreference = payServiceCharge !== undefined;
  const isTotalZero = finalTotal === 0;
  const isTakeAwayTable = tableName ? tableName.toLowerCase().includes('takeaway') : false;
  const ValidationSchema = isTakeAwayTable ? CheckoutFormTakeAwaySchema : false;
  const pickupTimesOptionsDays = useMemo(
    () =>
      pickupTimesData?.pickupTimes.map(({ dayLabel, day }) => ({
        value: day,
        label: dayLabel,
      })),
    [pickupTimesData],
  );
  const finalPickupDay = pickupTimesOptionsDays?.[0] || null;
  const finalPickupTime = pickupTimesOptionsTimeSlots?.[0] || null;
  const isPaymentErrorVisible = paymentError && !isCheckoutCardModalOpen;
  const isPickupVisible = isTakeAwayTable && !isVouchers && isUserDetailsEditable;
  const throwError = useAsyncServedUpError();

  const defaultValues = useMemo(
    () => ({
      pickupDay: finalPickupDay,
      pickupTime: finalPickupTime,
    }),
    [finalPickupDay, finalPickupTime],
  );

  const setDefaultPickupTimes = useCallback(() => {
    const { pickupTimes } = pickupTimesData || {};
    const defaultPickupTimes = pickupTimes[0].pickupTimes.map((time) => ({
      value: time,
      label: time,
    }));
    setPickupTimesOptionsTimeSlots(defaultPickupTimes);
  }, [pickupTimesData, setPickupTimesOptionsTimeSlots]);

  const handlePaymentError = useCallback(
    ({ error, enforcedErrorCode, message }) => {
      const isSoldOutITems = error?.errorCode === errorCodes.SOME_ITEMS_ARE_SOLDOUT;
      const isApplePayProblem =
        error?.message === errorCodes.APPLEPAY_ALREADY_PAYMENT_SESSION ||
        error?.message === errorCodes.APPLEPAY_VISA_NOT_SUPPORTED ||
        error?.message === errorCodes.APPLEPAY_UNSUPPORTED_ERROR;

      if (isSoldOutITems) {
        throwError({ error, venueId, orderId });
        return;
      }
      if (isApplePayProblem) {
        setIsApplePayDisabled(true);
      }
      const errorData = payment?.response_summary;
      const errorCode =
        enforcedErrorCode || error?.message || error?.errorCode || error?.error?.errorCode;
      const { message: humanReadableError } = getHumanReadableErrorMessage(
        { message: errorCode, errorData },
        venueId,
        orderId,
      );

      setPaymentError(message || humanReadableError);
    },
    [throwError, payment?.response_summary, venueId, orderId],
  );

  const handleDeclinedPayment = useCallback(() => {
    if (isDeclined) {
      handlePaymentError({ enforcedErrorCode: errorCodes.PAYMENT_DECLINED });
    } else {
      setPaymentError(null);
    }
  }, [handlePaymentError, isDeclined]);

  const handleUserPaid = useCallback(() => {
    const confirmationPageRoute = isVouchers
      ? `/vouchers/confirmation/${venueId}/${orderId}`
      : `/confirmation/${venueId}/${orderId}`;

    if (!isMacLike) window.navigator.vibrate([100, 100, 100]);

    history.push(confirmationPageRoute);
  }, [isVouchers, venueId, orderId, history]);

  const handleBankedOnSubmit = async () => {
    try {
      setIsUserPaying(true);

      const { pickupDay, pickupTime } = getValues();

      const payload = {
        venueId,
        notes,
        payServiceCharge: isPayServiceCharge,
        payAdditionalCharge: isPayAdditionalCharge,
        allowMarketing: isMarketingEnabled,
        pickupDay,
        pickupTime,
        isQrPayment,
      };

      const { id } = await dispatch(createPaidUpRequest({ params: orderId, payload })).unwrap();
      await dispatch(
        fetchBankedSession({
          params: id,
          query: { api_key: process.env.REACT_APP_BANKED_API_KEY },
        }),
      ).unwrap();
    } catch (error) {
      Logger.error(error);

      handlePaymentError({ error });
    }

    setIsBankedRequestInitiated(true);
    if (bankedState && bankedState === 'awaiting_provider') {
      setIsBankedShowing(bankedState && bankedState === 'awaiting_provider');
    }
  };

  const handleBankedChooseProvider = useCallback(
    async (provider) => {
      const session = await updateOrderPaidUpWithBank(provider, bankedId, name, email);
      const { redirect_url: redirectUrl } = session;

      window.location.href = redirectUrl;
    },
    [bankedId, email, name],
  );

  const handleToggleBankedModal = useCallback(() => {
    setIsBankedShowing(!isBankedShowing);
    setIsUserPaying(false);
    setIsBankedRequestInitiated(false);
  }, [isBankedShowing, setIsBankedShowing, setIsUserPaying, setIsBankedRequestInitiated]);

  const handleToggleAdditionalCharge = (additionalChargeEnabled) => {
    setIsAdditionalChargeChecked(additionalChargeEnabled);
  };

  const handlePatchServiceCharge = useCallback(
    async (serviceChargeEnabled) => {
      try {
        await dispatch(
          toggleServiceCharge({
            params: orderId,
            payload: { venueId, payServiceCharge: serviceChargeEnabled },
          }),
        ).unwrap();
      } catch (error) {
        Logger.error(error);
        const { message } = getHumanReadableErrorMessage(error, venueId, orderId);

        setPaymentError(message);
      }
    },
    [dispatch, orderId, venueId],
  );

  useEffect(() => {
    if (isUserPaying && isBankedRequestInitiated)
      setIsBankedShowing(bankedState && bankedState === 'awaiting_provider');
  }, [isUserPaying, bankedState, isBankedRequestInitiated, setIsBankedShowing]);

  const sendGACheckoutEvents = useCallback(() => {
    sendGAPurchase(checkoutData);
    sendGAPaymentInfo(checkoutData);
  }, [checkoutData]);

  useEffect(() => {
    if (isUserPaid) {
      sendGACheckoutEvents();
      handleUserPaid();
    }
  }, [handleUserPaid, isUserPaid, sendGACheckoutEvents]);

  useEffect(() => {
    handleDeclinedPayment();
  }, [handleDeclinedPayment]);

  useEffect(() => {
    if (isTakeAwayTable && pickupTimesData) {
      setDefaultPickupTimes();
    }
  }, [setDefaultPickupTimes, isTakeAwayTable, pickupTimesData]);

  useEffect(() => {
    if (paymentError) {
      setIsUserPaying(false);
    }
  }, [paymentError]);

  useEffect(() => {
    if (reset) {
      reset(
        { pickupDay: finalPickupDay, pickupTime: finalPickupTime },
        { keepErrors: true, keepDirty: true },
      );
    }
  }, [finalPickupDay, finalPickupTime, reset]);

  useEffect(() => {
    if (errorMessage) setPaymentError(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (!hasServiceChargePreference) {
      handlePatchServiceCharge(true);
    }
  }, [handlePatchServiceCharge, hasServiceChargePreference, isServiceChargeChecked]);

  useEffect(() => {
    setIsServiceChargeChecked(payServiceCharge);
  }, [payServiceCharge, setIsServiceChargeChecked]);

  useEffect(() => {
    setIsPayServiceCharge(isPayServiceCharge);
  }, [setIsPayServiceCharge, isPayServiceCharge]);

  useEffect(() => {
    setIsPayAdditionalCharge(isPayAdditionalCharge);
  }, [setIsPayAdditionalCharge, isPayAdditionalCharge]);

  return (
    <CheckoutContext.Provider
      value={{
        total,
        finalTotal,
        isTotalZero,
        isDeclined,
        isUserPaid,
        isAdditionalChargeChecked,
        paymentError,
        setPaymentError,
        handlePaymentError,
        isQrPayment,
        isUserPaying,
        setIsUserPaying,
        isVouchers,
        checkoutCodeSuccessResponse,
        setCheckoutCodeSuccessResponse,
        checkoutCodeFormInstance,
        setCheckoutCodeFormInstance,
        isBankedShowing,
        setIsBankedShowing,
        setIsBankedRequestInitiated,
        isBankedRequestInitiated,
        handleBankedOnSubmit,
        handleBankedChooseProvider,
        handleToggleBankedModal,
        isCheckoutCardModalOpen,
        setIsCheckoutCardModalOpen,
      }}
    >
      <div className="checkout__form">
        {!isVouchers && <CheckoutCodeField />}
        <Form
          validationSchema={ValidationSchema}
          validateOnLoad
          defaultValues={defaultValues}
          setFormInstance={setFormInstance}
        >
          {isPickupVisible && (
            <div className="checkout__form__takeaway">
              <FormFieldSelect
                name="pickupDay"
                options={pickupTimesOptionsDays}
                label="Takeaway time"
                data-cy={cySelectors.CHECKOUT_TAKEAWAY_DAY}
              />
              <FormFieldSelect
                name="pickupTime"
                options={pickupTimesOptionsTimeSlots}
                data-cy={cySelectors.CHECKOUT_TAKEAWAY_TIME}
              />
            </div>
          )}

          <CheckoutFormFooter
            handleToggleServiceCharge={handlePatchServiceCharge}
            handleToggleAdditionalCharge={handleToggleAdditionalCharge}
          >
            {isPaymentErrorVisible && (
              <div className="checkout__error" data-cy={cySelectors.CHECKOUT_FORM_ERROR}>
                {paymentError}
              </div>
            )}
          </CheckoutFormFooter>

          {!isUserPaid && (
            <div className="checkout__form__payment-systems">
              <PaymentSystems
                systems={paymentSystems}
                disableApplePay={isApplePayDisabled}
                isVouchers={isVouchers}
                isAmexEnabled={isAmexEnabled}
              />
            </div>
          )}
        </Form>

        <CheckoutPaidUpModal
          isModalOpen={isBankedShowing}
          handleToggleModal={handleToggleBankedModal}
          chooseProvider={handleBankedChooseProvider}
        />
      </div>
    </CheckoutContext.Provider>
  );
};

export default CheckoutForm;
