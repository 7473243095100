import { useEffect } from 'react';
import { toast } from 'react-toastify';

import useQuery from '../../hooks/useQuery';
import { notify } from '../../utils/notify';

const Notifications = () => {
  const queries = useQuery();
  const isNotification = queries.get('isNotification') === 'true';
  const type = queries.get('notificationType') || toast.TYPE.DEFAULT;
  const heading = queries.get('notificationHeading');
  const text = queries.get('notificationText');

  useEffect(() => {
    if (isNotification) {
      notify({ text, heading, type });
    }
  }, [heading, isNotification, text, type]);

  return null;
};

export default Notifications;
