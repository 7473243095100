const sendUserMetadata = (userId, venueId, orderId) => {
  if (window.hj) {
    window.hj('identify', userId, {
      venueId,
      orderId,
    });
  }
};

export default sendUserMetadata;
