import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import ratingIcons from './ratingIcons';

import cySelectors from '../../tests/cySelectors';

const LeaveReviewCTA = ({
  venueName = 'this venue',
  prefilledRating,
  handleOnSetRating = () => {},
}) => {
  const [selectedRating, setSelectedRating] = useState(null);
  const hasPrefilledRating = prefilledRating !== undefined;
  const isPrefilledRatingValid = ratingIcons.find(({ value }) => value === selectedRating);
  const isHelperTextVisible = !isPrefilledRatingValid && hasPrefilledRating;

  useEffect(() => {
    if (prefilledRating) {
      setSelectedRating(parseInt(prefilledRating, 10));
    }
  }, [prefilledRating]);

  const handleOnChange = (value) => {
    handleOnSetRating(value);
    setSelectedRating(value);
  };

  return (
    <div className="leave-review-cta" data-cy={cySelectors.LEAVE_REVIEW_CTA}>
      <p>
        How would you rate your experience at <br />
        {venueName}?
      </p>
      {isHelperTextVisible && (
        <p className="leave-review-cta__helper-text">Please select a rating</p>
      )}
      <div className="leave-review-cta__ratings">
        <fieldset className="leave-review-cta__radios" id="rating">
          {ratingIcons.map(({ value, icon, text }) => {
            const isSelected = selectedRating === value;

            return (
              <div
                key={value}
                className={clsx('leave-review-cta__rating', {
                  'leave-review-cta__rating--selected': isSelected,
                })}
              >
                <input
                  checked={isSelected}
                  type="radio"
                  name="rating"
                  id={value}
                  value={value}
                  onChange={() => handleOnChange(value)}
                />
                <label htmlFor={value} className="leave-review-cta__rating__label">
                  <span
                    role="img"
                    aria-labelledby={text}
                    className="leave-review-cta__rating__label__icon"
                  >
                    {icon}
                  </span>
                  <span className="leave-review-cta__rating__label__text">{text}</span>
                </label>
              </div>
            );
          })}
        </fieldset>
      </div>
    </div>
  );
};

export default LeaveReviewCTA;
