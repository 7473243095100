import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import throttle from 'lodash.throttle';
import { useParams } from 'react-router-dom';

import BackButton from '../../../components/BackButton';

const MenuPageHeader = ({ title, hasBanner, hasBackBtn }) => {
  const [isHeaderOpaque, setIsHeaderOpaque] = useState(false);
  const headerRef = useRef(null);
  const { venueId, orderId } = useParams();
  const vendorsPath = `/vendors/${venueId}/${orderId}`;
  const classes = clsx('menu-page-header', {
    'menu-page-header--has-banner': hasBanner,
    'menu-page-header--opaque': isHeaderOpaque || !hasBanner,
    'menu-page-header--has-back-btn': hasBackBtn,
  });

  const handleFadeHeader = () => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      const windowScroll = window.scrollY;

      setIsHeaderOpaque(windowScroll > headerHeight);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleFadeHeader = useCallback(throttle(handleFadeHeader, 200), [headerRef]);

  useEffect(() => {
    if (hasBanner && headerRef.current) {
      window.addEventListener('scroll', throttledHandleFadeHeader);

      return () => {
        window.removeEventListener('scroll', throttledHandleFadeHeader);
      };
    }

    return null;
  }, [throttledHandleFadeHeader, hasBanner]);

  return (
    <header className={classes} ref={headerRef}>
      {hasBackBtn && (
        <div className="menu-page-header__back">
          <BackButton to={vendorsPath} />
        </div>
      )}
      <div className="menu-page-header__bar">
        <div className="menu-page-header__bar__title__wrapper">
          <p className="menu-page-header__bar__title">{title}</p>
        </div>
      </div>
    </header>
  );
};

export default MenuPageHeader;
