import pickBy from 'lodash.pickby';

import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';
import getAddToCartPayload from './getAddToCartPayload';
import getChangeCartItemQuantityPayload from './getChangeCartItemQuantityPayload';
import getChangeUpsellQuantityPayload from './getChangeUpsellQuantityPayload';
import getChangeCartVoucherQuantityPayload from './getChangeCartVoucherQuantityPayload';
import getAddToCartVoucherPayload from './getAddToCartVoucherPayload';

const sliceArgs = {
  SLICE_NAME: 'order',
  ENDPOINT: 'orders',
};

const sliceName = sliceArgs.SLICE_NAME;

const createOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'POST',
});

const fetchOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const addToCartPayloadTransformer = ({ venueId, childVenueId, cart, newItem, itemQuantity }) => {
  const updatedCart = getAddToCartPayload(cart, newItem, itemQuantity, childVenueId);

  return {
    venueId,
    cart: updatedCart,
  };
};

const addToCart = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: addToCartPayloadTransformer,
});

const addToCartVoucherPayloadTransformer = ({ venueId, cart, newItem, itemQuantity }) => {
  const updatedCart = getAddToCartVoucherPayload(cart, newItem, itemQuantity);

  return {
    venueId,
    cart: updatedCart,
  };
};

const addToCartVoucher = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: 'vouchers',
  method: 'PATCH',
  payloadTransformer: addToCartVoucherPayloadTransformer,
});

const changeCartItemQuantityPayloadTransformer = ({
  venueId,
  cart,
  uniqItemId,
  quantityChange,
  isDelete,
}) => {
  const updatedCart = getChangeCartItemQuantityPayload(cart, uniqItemId, quantityChange, isDelete);

  return {
    venueId,
    cart: updatedCart,
  };
};

const changeUpsellQuantityPayloadTransformer = ({
  venueId,
  cart,
  item,
  quantityChange,
  upsoldFrom,
}) => {
  const updatedCart = getChangeUpsellQuantityPayload(cart, item, quantityChange, upsoldFrom);

  return {
    venueId,
    cart: updatedCart,
  };
};

const changeCartVoucherQuantityPayloadTransformer = ({
  venueId,
  cart,
  uniqItemId,
  quantityChange,
  isDelete,
  amount,
}) => {
  const updatedCart = getChangeCartVoucherQuantityPayload(
    cart,
    uniqItemId,
    quantityChange,
    isDelete,
    amount,
  );

  return {
    venueId,
    cart: updatedCart,
  };
};

const changeCartItemQuantity = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: changeCartItemQuantityPayloadTransformer,
});

const changeUpsellQuantity = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: changeUpsellQuantityPayloadTransformer,
});

const changeCartVoucherQuantity = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `vouchers`,
  method: 'PATCH',
  payloadTransformer: changeCartVoucherQuantityPayloadTransformer,
});

const patchOrderPayloadTransformer = (payload) => {
  const { countryCode, phone, name, email, notes, venueId, tableName } = payload;
  const filteredPayload = { venueId, notes, name, email };
  const sanitizedCountryCode = countryCode ? countryCode.value : undefined;
  const sanitizedPhone = phone ? phone.replace(new RegExp(/^0/), '') : undefined;
  const sanitizedPhoneWithCountryCode = sanitizedPhone
    ? `${sanitizedCountryCode}${sanitizedPhone}`
    : undefined;
  const sanitizedTableName = tableName ? tableName.value : undefined;

  const sanitisedPayload = pickBy({
    ...filteredPayload,
    phone: sanitizedPhoneWithCountryCode,
    tableName: sanitizedTableName,
  });

  return sanitisedPayload;
};

const patchOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
  payloadTransformer: patchOrderPayloadTransformer,
});

const toggleServiceCharge = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
});

const patchVouchersOrderPayloadTransformer = (payload) => {
  const { countryCode, phone, name, email, venueId } = payload;
  const filteredPayload = { venueId, name, email };
  const sanitizedCountryCode = countryCode ? countryCode.value : undefined;
  const sanitizedPhone = phone ? phone.replace(new RegExp(/^0/), '') : undefined;
  const sanitizedPhoneWithCountryCode = sanitizedPhone
    ? `${sanitizedCountryCode}${sanitizedPhone}`
    : undefined;

  const sanitisedPayload = pickBy({
    ...filteredPayload,
    phone: sanitizedPhoneWithCountryCode,
  });

  return sanitisedPayload;
};

const patchVouchersOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `vouchers`,
  method: 'PATCH',
  payloadTransformer: patchVouchersOrderPayloadTransformer,
});

const addCheckoutCode = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'PATCH',
});

const deletePromoCode = createEnhancedAsynThunk({
  name: `${sliceArgs.SLICE_NAME}/promo`,
  endpoint: `${sliceArgs.ENDPOINT}`,
  method: 'DELETE',
  resource: 'promo',
});

const acceptOrderPramsTransformer = ({ venueId, orderId }) => `${orderId}/${venueId}`;

const acceptOrder = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: `${sliceArgs.ENDPOINT}`,
  resource: 'accept',
  method: 'PATCH',
  paramsTransformer: acceptOrderPramsTransformer,
});

const extraReducers = [
  createOrder,
  fetchOrder,
  addToCart,
  changeCartItemQuantity,
  changeCartVoucherQuantity,
  patchOrder,
  patchVouchersOrder,
  addCheckoutCode,
  addToCartVoucher,
  deletePromoCode,
  acceptOrder,
];

const reducers = {
  resetOrderData: (state) => {
    state.data = null;
    state.loading = false;
    state.error = null;
  },
};

const slice = sliceHandler({ sliceName, extraReducers, reducers });
const { actions, reducer } = slice;

export const { resetOrderData } = actions;
export {
  createOrder,
  fetchOrder,
  addToCart,
  changeUpsellQuantity,
  changeCartItemQuantity,
  changeCartVoucherQuantity,
  patchOrder,
  patchVouchersOrder,
  addCheckoutCode,
  addToCartVoucher,
  deletePromoCode,
  acceptOrder,
  toggleServiceCharge,
};
export default reducer;
