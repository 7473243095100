import { inlineErrorCodes } from './errorCodes';

const isInlineError = (errorPayload) => {
  const { error, message } = errorPayload;
  const errorCode = error ? error.errorCode : message;
  const isInline = Object.keys(inlineErrorCodes).includes(errorCode);

  return isInline;
};

export default isInlineError;
