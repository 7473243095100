/* eslint-disable react/button-has-type */
import React from 'react';
import clsx from 'clsx';

import LoadingSpinner from '../LoadingSpinner';

const Button = ({
  fullWidth = false,
  white = false,
  secondary = false,
  type = 'button',
  loading,
  onClick,
  disabled = false,
  moreRounded = false,
  textCenter,
  extraClasses,
  small,
  dataCy,
  Icon,
  children,
  ...extraProps
}) => {
  const classes = clsx('btn', {
    'btn--fullwidth': fullWidth,
    'btn--secondary': secondary,
    'btn--more-rounded': moreRounded,
    'btn--white': white,
    'btn--blue': !white,
    'btn--loading': loading,
    'btn--text-center': textCenter,
    'btn--small': small,
    'btn--has-icon': Icon,
    [extraClasses]: extraClasses,
  });

  return (
    <button
      className={classes}
      type={type}
      onClick={onClick}
      disabled={disabled}
      data-cy={dataCy}
      {...extraProps}
    >
      {Icon && <Icon className="btn__icon" />}

      {loading ? <LoadingSpinner /> : children}
    </button>
  );
};

export default Button;
