const getItemCount = ({ item, cart, venueId, isVoucher = false }) => {
  const { itemId, categoryId } = item || {};
  const filteredItems = cart?.filter(({ uniqItemId }) => {
    const cartItem = JSON.parse(window.atob(uniqItemId));
    const itemBelongsToCurrentVenue = cartItem.venueId === venueId;
    const itemHasSameId = cartItem.itemId === itemId;

    if (isVoucher) {
      return itemHasSameId;
    }

    const itemHasSameCategoryId = cartItem.categoryId === categoryId;

    return itemBelongsToCurrentVenue && itemHasSameId && itemHasSameCategoryId;
  });

  return filteredItems?.reduce((acc, _current, index) => acc + filteredItems[index].quantity, 0);
};

export default getItemCount;
