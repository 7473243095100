import React from 'react';
import FullscreenModal from '../../../components/FullscreenModal';
import ItemUpsells from '../../../components/ItemUpsells';
import LoadingSpinner from '../../../components/LoadingSpinner';

const UpsellModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  allergensSelected,
  upsellsLoading,
  upsells,
  handleGetItemCount,
  handleUpsellModalToggle,
  handleChangeUpsellQuantity,
  item,
}) => (
  <FullscreenModal
    isModalOpen={isModalOpen && upsells?.length > 0}
    setIsModalOpen={setIsModalOpen}
    onCloseModal={handleCloseModal}
    extraClasses="item__modal--is-upsell"
  >
    {upsellsLoading ? (
      <LoadingSpinner />
    ) : (
      <>
        <ItemUpsells
          upsells={upsells}
          itemAdded={item}
          allergensSelected={allergensSelected}
          handleCloseModal={handleCloseModal}
          handleGetItemCount={handleGetItemCount}
          handleUpsellModalToggle={handleUpsellModalToggle}
          handleChangeQuantity={handleChangeUpsellQuantity}
        />
      </>
    )}
  </FullscreenModal>
);

export default UpsellModal;
