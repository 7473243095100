import React, { useCallback, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useAsyncServedUpError from '../../hooks/useAsyncServedUpError';

import LoadingSpinner from '../../components/LoadingSpinner';
import AppContext from '../../components/App/AppContext';
import Header from '../../components/Header';

import DetailsForm from './DetailsForm';
import saveDetailsFormToLocalStorage from './DetailsForm/saveDetailsFormToLocalStorage';

import { patchOrder, patchVouchersOrder } from '../../store/slices/order';

import getHumanReadableErrorMessage from '../../utils/getHumanReadableErrorMessage';
import pageNames from '../../utils/pageNames';
import { inlineErrorCodes } from '../../utils/errorCodes';
import setGAUserId from '../../utils/tracking/GA/setGAUserId';

const Details = ({ isVouchers = false }) => {
  const { isCookieConsentAgreed } = useContext(AppContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: orderData } = useSelector((state) => state.order);
  const { venueId, orderId } = useParams();
  const throwError = useAsyncServedUpError();

  const handleNavigateToCheckoutPage = useCallback(() => {
    const checkoutPageRoute = isVouchers
      ? `/vouchers/checkout/${venueId}/${orderId}`
      : `/checkout/${venueId}/${orderId}`;

    history.push(checkoutPageRoute);
  }, [isVouchers, venueId, orderId, history]);

  const handlePatchDetails = useCallback(
    async (values, setPatchDetailsError, setError) => {
      const payload = {
        venueId,
        ...values,
      };
      const {
        phone,
        countryCode: { value: countryCodeValue },
      } = values;

      try {
        const finalPatchOrder = isVouchers ? patchVouchersOrder : patchOrder;

        await dispatch(finalPatchOrder({ params: orderId, payload })).unwrap();

        setPatchDetailsError(false);

        if (isCookieConsentAgreed) {
          saveDetailsFormToLocalStorage(values);
          setGAUserId(phone, countryCodeValue);
        }

        handleNavigateToCheckoutPage(venueId, orderId);
      } catch (error) {
        const { errorCode } = error;
        const isPhoneError = errorCode === inlineErrorCodes.INVALID_PHONE;
        const isEmailError = errorCode === inlineErrorCodes.INVALID_EMAIL;
        const { message: humanReadableError } = getHumanReadableErrorMessage(error);

        switch (true) {
          case isPhoneError:
            setError('phone', {
              type: 'manual',
              message: humanReadableError,
            });
            break;
          case isEmailError:
            setError('email', {
              type: 'manual',
              message: humanReadableError,
            });
            break;
          default:
            throwError({ error, venueId, orderId });
            break;
        }

        setPatchDetailsError(true);
      }
    },
    [
      venueId,
      isVouchers,
      isCookieConsentAgreed,
      handleNavigateToCheckoutPage,
      orderId,
      dispatch,
      throwError,
    ],
  );

  return (
    <>
      <Header hasBackBtn title={pageNames.DETAILS} />
      {orderData ? (
        <div className="details">
          <DetailsForm handlePatchDetails={handlePatchDetails} isVouchers={isVouchers} />
        </div>
      ) : (
        <LoadingSpinner center />
      )}
    </>
  );
};

export default withHandleRoutesDispatch(Details);
