import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';

const normaliseCheckoutCodes = (checkoutCodes) =>
  checkoutCodes.map((checkoutCode) =>
    omitBy(
      {
        code: checkoutCode.voucherCode || checkoutCode.promoCode,
        discount: checkoutCode.discount,
        occurences: checkoutCode.occurences,
        offer: checkoutCode.offer,
        amountUsed: checkoutCode.amountUsed,
      },
      isNil,
    ),
  );

export default normaliseCheckoutCodes;
