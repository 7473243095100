import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import FooterCta from '../../../components/FooterCta';
import Form from '../../../components/Form';
import FormField from '../../../components/FormField';
import LeaveReviewCTA from '../../../components/LeaveReviewCTA';

import RatingFormValidationSchema from './RatingFormValidationSchema';

const RatingForm = ({ handlePostRating, prefilledRating, postRatingError, venueName }) => {
  const [formInstance, setFormInstance] = useState(null);
  const { reset, getValues } = formInstance || {};

  const defaultValues = {
    rating: prefilledRating,
    review: '',
  };

  const handleOnSetRating = (value) => {
    reset({ ...getValues(), rating: value });
  };

  return (
    <div className="rating__form">
      <Form
        handleOnSubmit={handlePostRating}
        validationSchema={RatingFormValidationSchema}
        mode="onChange"
        defaultValues={defaultValues}
        setFormInstance={setFormInstance}
      >
        <LeaveReviewCTA
          venueName={venueName}
          prefilledRating={prefilledRating}
          handleOnSetRating={handleOnSetRating}
        />
        <FormField
          type="textarea"
          name="review"
          label="Write feedback"
          helpText="(Optional)"
          extraClasses="rating__form__review"
        />
        <ReviewFieldCharacterCount />
        <RatingSubmitButton postRatingError={postRatingError} />
      </Form>
    </div>
  );
};

const ReviewFieldCharacterCount = () => {
  const { watch } = useFormContext();
  const reviewCharCount = watch('review').length;

  return <div className="rating__form__char-count">{reviewCharCount} of 200 characters</div>;
};

const RatingSubmitButton = ({ postRatingError }) => {
  const {
    formState: { isValid, isSubmitting },
  } = useFormContext();

  return (
    <FooterCta type="submit" loading={isSubmitting} disabled={!isValid} textCenter>
      {postRatingError || 'Send feedback'}
    </FooterCta>
  );
};

export default RatingForm;
