import React from 'react';
import FooterCta from '../../../components/FooterCta';

import FullscreenModal from '../../../components/FullscreenModal';
import Header from '../../../components/Header';
import ItemUpsells from '../../../components/ItemUpsells';

const ReviewUpsellsModal = ({
  upsells,
  isModalOpen,
  setIsModalOpen,
  handlePatchNotes,
  handleCloseModal,
  handleGetItemCount,
  handleChangeQuantity,
  handleUpsellModalToggle,
  hasUpsold,
}) => (
  <FullscreenModal
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
    handleCloseModal={handleCloseModal}
    extraClasses="review-upsells-modal"
  >
    <Header title="You may also like..." />
    <ItemUpsells
      upsells={upsells}
      isNoTitle
      handleGetItemCount={handleGetItemCount}
      handleUpsellModalToggle={handleUpsellModalToggle}
      handleChangeQuantity={handleChangeQuantity}
    />
    {hasUpsold ? (
      <FooterCta textCenter onClick={handleCloseModal}>
        <span>Review order</span>
      </FooterCta>
    ) : (
      <FooterCta textCenter onClick={handlePatchNotes}>
        <span>Continue to checkout</span>
      </FooterCta>
    )}
  </FullscreenModal>
);

export default ReviewUpsellsModal;
