import React from 'react';

import MenuCategoryButton from '../MenuNavigationCategoryButton';

const MenuNavigationCategories = ({ handleScrollToCategory, categories }) => {
  const handleSetScrollToCurrentCategory = (element) => {
    const offsetLeft = element.getBoundingClientRect().left;
    const menuFiltersElWidth = document.querySelector('.menu__filters')?.clientWidth;
    const menuNavigationEl = document.querySelector('.menu__navigation');
    const halfWidth = menuNavigationEl.offsetWidth / 2;

    menuNavigationEl.scrollBy({
      top: 0,
      left: menuFiltersElWidth + offsetLeft - halfWidth,
      behavior: 'smooth',
    });
  };

  return (
    <nav className="menu__navigation__categories">
      <ul>
        {categories.map((category) => (
          <MenuCategoryButton
            key={category.categoryId}
            category={category}
            handleSetScrollToCurrentCategory={handleSetScrollToCurrentCategory}
            handleScrollToCategory={handleScrollToCategory}
          />
        ))}
      </ul>
    </nav>
  );
};

export default MenuNavigationCategories;
