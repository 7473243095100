import React, { useState, useEffect, createContext } from 'react';

import detectWebpSupport from '../../../utils/detectWebpSupport';
import getUserPreferences from '../../../utils/getUserPreferences';

const AppContext = createContext();
const appContextValues = {
  isWebpSupported: detectWebpSupport(),
};

const AppContextProvider = ({ children }) => {
  const [isMarketingEnabled, setIsMarketingEnabled] = useState(null);
  const [isCookieConsentAgreed, setIsCookieConsentAgreed] = useState(null);
  const [currentViewedCategory, setCurrentViewedCategory] = useState('');
  const [availableCategoryMenuItems, setAvailableCategoryMenuItems] = useState([]);
  const [searchedCategoryMenuItems, setSearchedCategoryMenuItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isOpeningTimesOpen, setIsOpeningTimesOpen] = useState(false);
  const [filters, setFilters] = useState({
    allergens: [],
    dietary: [],
  });
  const [isPromotionModalOpen, setIsPromotionModalOpen] = useState(false);
  const [isIframeBannerVisible, setIsIframeBannerVisible] = useState(false);
  const [isServiceChargeChecked, setIsServiceChargeChecked] = useState(true);
  const [isPayServiceCharge, setIsPayServiceCharge] = useState(false);
  const [isPayAdditionalCharge, setIsPayAdditionalCharge] = useState(false);
  const [isAdditionalChargeChecked, setIsAdditionalChargeChecked] = useState(false);
  const [isIframeMode, setIframeMode] = useState(false);
  const [viewport, setViewport] = useState(false);
  const [isContainedAppLayout, setIsContainedAppLayout] = useState(false);
  const [showUniversalLoading, setShowUniversalLoading] = useState(false);
  const [showUniversalConfirmation, setShowUniversalConfirmation] = useState(false);
  const [hasMvBannerImg, setHasMvBannerImg] = useState(false);

  const hasFiltersSelected = !![...filters.allergens, ...filters.dietary]?.filter((value) => value)
    ?.length;

  const displayUniversalLoading = async () => {
    setShowUniversalLoading(true);
  };

  const hideUniversalConfirmation = () => {
    setShowUniversalConfirmation(false);
    setShowUniversalLoading(false);
  };

  const displayUniversalConfirmation = async () => {
    setShowUniversalLoading(false);
    setShowUniversalConfirmation(true);
    await new Promise((resolve) => setTimeout(resolve, 900));
    setShowUniversalConfirmation(false);
  };

  const propValues = {
    ...appContextValues,
    isMarketingEnabled,
    setIsMarketingEnabled,
    isCookieConsentAgreed,
    setIsCookieConsentAgreed,
    currentViewedCategory,
    setCurrentViewedCategory,
    availableCategoryMenuItems,
    setAvailableCategoryMenuItems,
    searchedCategoryMenuItems,
    setSearchedCategoryMenuItems,
    isPromotionModalOpen,
    setIsPromotionModalOpen,
    isServiceChargeChecked,
    setIsServiceChargeChecked,
    isAdditionalChargeChecked,
    isPayServiceCharge,
    setIsPayServiceCharge,
    isPayAdditionalCharge,
    setIsPayAdditionalCharge,
    setIsAdditionalChargeChecked,
    searchQuery,
    setSearchQuery,
    isSearchOpen,
    setIsSearchOpen,
    isIframeBannerVisible,
    setIsIframeBannerVisible,
    isIframeMode,
    setIframeMode,
    setViewport,
    viewport,
    isContainedAppLayout,
    setIsContainedAppLayout,
    isFiltersOpen,
    setIsFiltersOpen,
    filters,
    hasFiltersSelected,
    setFilters,
    setIsOpeningTimesOpen,
    isOpeningTimesOpen,
    setShowUniversalConfirmation,
    showUniversalConfirmation,
    displayUniversalConfirmation,
    displayUniversalLoading,
    showUniversalLoading,
    setShowUniversalLoading,
    hideUniversalConfirmation,
    hasMvBannerImg,
    setHasMvBannerImg,
  };

  useEffect(() => {
    const cookieConsentAgreed = getUserPreferences('cookieConsentAgreed');

    setIsCookieConsentAgreed(cookieConsentAgreed);
  }, [setIsCookieConsentAgreed]);

  return <AppContext.Provider value={propValues}>{children}</AppContext.Provider>;
};

export { AppContext as default, appContextValues, AppContextProvider };
