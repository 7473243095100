import React, { useState, useEffect, useMemo, useContext } from 'react';
import { useSelector } from 'react-redux';

import FooterCta from '../../../components/FooterCta';
import Form from '../../../components/Form';
import FormField from '../../../components/FormField';
import FormFieldSelect from '../../../components/FormFieldSelect';
import AppContext from '../../../components/App/AppContext';

import DetailsFormSchema from './DetailsFormSchema';
import DetailsFormSchemaVouchers from './DetailsFormSchemaVouchers';
import DetailsFormPhoneField from './DetailsFormPhoneField';
import MarketingOptin from './MarketingOptin';

import formatCurrency from '../../../utils/formatCurrency';
import getDetailsFormFromLocalStorage from '../../../utils/getDetailsFormFromLocalStorage';
import countryCodes from './countryCodes';

import cySelectors from '../../../tests/cySelectors';

const moveTakeawayToStart = (array = []) => {
  if (!array) return array;
  const index = array?.findIndex((obj) => obj.tableName === 'Takeaway');
  if (index !== -1) {
    array?.unshift(array?.splice(index, 1)[0]);
  }
  return array;
};

const DetailsForm = ({ handlePatchDetails, isVouchers = false }) => {
  const [patchDetailsError, setPatchDetailsError] = useState(false);
  const [formInstance, setFormInstance] = useState();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: userData } = useSelector((state) => state.user);
  const { allowMarketing } = userData || {};
  const { isMarketingEnabled, setIsMarketingEnabled } = useContext(AppContext);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { data: tablesData, loading: tablesLoading } = useSelector((state) => state.tables);
  const { total } = orderData || {};
  const { isTakeawayEnabled, isOpen } = venueData || {};
  const isTakeAwayOnly = isTakeawayEnabled && !isOpen;

  const sortedTables =
    tablesData && isTakeawayEnabled ? moveTakeawayToStart(tablesData?.slice()) : tablesData;

  const tablesOption = sortedTables?.map((table) => ({
    value: table.tableName,
    label: table.tableName,
  }));

  const formattedTotal = formatCurrency(total);
  const { email, name, phone, tableName, countryCode } = getDetailsFormFromLocalStorage();
  const validTableName = tablesOption?.find(
    (table) => table.value.toLowerCase() === tableName?.value.toLowerCase(),
  );
  const FinalDetailsFormSchema = isVouchers ? DetailsFormSchemaVouchers : DetailsFormSchema;
  const takeawayTableName = useMemo(
    () => (isTakeAwayOnly ? { value: 'Takeaway', label: 'Takeaway' } : validTableName || null),
    [isTakeAwayOnly, validTableName],
  );
  const finalTableName = tablesOption?.length === 1 ? tablesOption[0] : takeawayTableName;
  const finalCountryCode = useMemo(
    () => countryCode || { label: '+44 United Kingdom', value: '+44' },
    [countryCode],
  );

  const { isValid, reset, getValues } = formInstance || {};

  const defaultValues = useMemo(
    () => ({
      tableName: finalTableName,
      name,
      email,
      countryCode: finalCountryCode,
      phone,
    }),
    [email, finalCountryCode, finalTableName, name, phone],
  );

  const vouchersDefaultValues = {
    name,
    email,
    countryCode: finalCountryCode,
    phone,
  };

  const finalDefaultValues = isVouchers ? vouchersDefaultValues : defaultValues;

  useEffect(() => {
    const { countryCode: countryCodeFromForm, tableName: tableNameFromForm } = getValues
      ? getValues() || {}
      : {};
    if (reset && !isVouchers && finalTableName) {
      reset(
        {
          tableName: tableNameFromForm || finalTableName,
          countryCode: countryCodeFromForm || finalCountryCode,
        },
        { keepErrors: true, keepDirty: true },
      );
    }
  }, [finalDefaultValues, finalTableName, isVouchers, reset, getValues, finalCountryCode]);

  const handleOnSubmit = (values) => {
    const { setError } = formInstance;

    handlePatchDetails(values, setPatchDetailsError, setError);
  };

  return (
    <div className="details__form" data-cy={cySelectors.DETAILS_FORM}>
      <Form
        handleOnSubmit={handleOnSubmit}
        validationSchema={FinalDetailsFormSchema}
        setFormInstance={setFormInstance}
        validateOnLoad
        defaultValues={finalDefaultValues}
        mode="onChange"
      >
        {!isVouchers && (
          <FormFieldSelect
            name="tableName"
            options={tablesOption}
            placeholder="Your table number"
            helpText="So we know where to bring your order"
            label="Your table number"
            readOnly={isTakeAwayOnly}
            data-cy={cySelectors.DETAILS_FORM_TABLE}
          />
        )}
        <FormField
          name="name"
          type="text"
          placeholder="Your name"
          label="Your name"
          helpText="We'll use this to find you at your table"
          autocomplete="name"
          data-cy={cySelectors.DETAILS_FORM_NAME}
        />
        <FormField
          name="email"
          type="text"
          placeholder="Your email"
          label="Your email"
          helpText="We'll use this to send you order updates"
          autocomplete="email"
          data-cy={cySelectors.DETAILS_FORM_EMAIL}
        />
        <DetailsFormPhoneField countryCodes={countryCodes} />

        {!isVouchers && (
          <MarketingOptin
            isUserOptedIn={allowMarketing}
            isMarketingEnabled={isMarketingEnabled}
            setIsMarketingEnabled={setIsMarketingEnabled}
          />
        )}
        <FooterCta
          type="submit"
          disabled={!isValid}
          loading={orderLoading || tablesLoading}
          data-cy={cySelectors.SUBMIT_BTN}
        >
          <span>{patchDetailsError ? 'Please try again' : 'Continue'}</span>
          <span>{formattedTotal}</span>
        </FooterCta>
      </Form>
    </div>
  );
};

export default DetailsForm;
