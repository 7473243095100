import getSanitisedCartMenuItem from './getSanitisedCartMenuItem';
import getSanitisedCartVoucher from './getSanitisedCartVoucher';

const getUpdatedSanitisedCart = (payload = []) =>
  payload.map((item) => {
    const { type } = item;
    const isVoucher = type === 'VOUCHER';

    return isVoucher ? getSanitisedCartVoucher(item) : getSanitisedCartMenuItem(item);
  });

export default getUpdatedSanitisedCart;
