import React, { useState, lazy, Suspense } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import postRating from '../../api/rating';

import useQuery from '../../hooks/useQuery';
import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import types from '../../components/FullscreenNotification/types';
import LoadingSpinner from '../../components/LoadingSpinner';

import RatingForm from './RatingForm';

import getHumanReadableErrorMessage from '../../utils/getHumanReadableErrorMessage';

const FullScreenNotification = lazy(() => import('../../components/FullscreenNotification'));

const Rating = () => {
  const [isNotificationVisible, setIsNotificationVisible] = useState(false);
  const [postRatingError, setPostRatingError] = useState(false);
  const [showGoogleReview, setShowGoogleReview] = useState(false);
  const { data: venueData } = useSelector((state) => state.venue);
  const { name: venueName, googlePlaceId } = venueData || {};
  const history = useHistory();
  const queries = useQuery();
  const rating = queries.get('rating');
  const { orderId, venueId } = useParams();

  const handlePostRating = async (values) => {
    try {
      const { rating: submittingRating } = values;
      const payload = {
        ...values,
        venueId,
      };

      const ratingRes = await postRating({ orderId, payload });

      setIsNotificationVisible(true);

      if (submittingRating === '5' && googlePlaceId) setShowGoogleReview(true);

      return ratingRes;
    } catch (error) {
      const finalPostRatingError = getHumanReadableErrorMessage(error.response.data).message;

      setPostRatingError(finalPostRatingError);

      return null;
    }
  };

  const handleRedirectToConfirmationPage = () => {
    history.push(`/confirmation/${venueId}/${orderId}?isLeaveReviewCtaVisible=false`);
    if (showGoogleReview) {
      document.location.href = `https://search.google.com/local/writereview?placeid=${googlePlaceId}`;
    }
  };

  return (
    <div className="rating">
      <RatingForm
        venueName={venueName}
        handlePostRating={handlePostRating}
        prefilledRating={rating}
        postRatingError={postRatingError}
      />
      <Suspense fallback={<LoadingSpinner center />}>
        <FullScreenNotification
          type={types.SUCCESS}
          text="Thank you!"
          isVisible={isNotificationVisible}
          handleOnDismiss={handleRedirectToConfirmationPage}
        />
      </Suspense>
    </div>
  );
};

export default withHandleRoutesDispatch(Rating);
