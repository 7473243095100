const getUserPreferences = (key) => {
  try {
    const userPreferences = JSON.parse(localStorage.getItem('user_preferences')) || {};
    return userPreferences[key];
  } catch (e) {
    return null;
  }
};

export default getUserPreferences;
