const fuseOptions = {
  keys: [
    {
      name: 'itemName',
      weight: 0.4,
    },
    {
      name: 'category',
      weight: 0.5,
    },
    {
      name: 'description',
      weight: 0.1,
    },
  ],
  threshold: 0.2,
};

export default fuseOptions;
