const getFinalFiltersCtaText = (filteredCategoryItemsNumber) => {
  const isSingleItem = filteredCategoryItemsNumber === 1;
  const isMoreThanOne = filteredCategoryItemsNumber >= 1;
  const isMoreThanFifty = filteredCategoryItemsNumber >= 51;
  const hasNoItems = filteredCategoryItemsNumber === 0;

  switch (true) {
    case isSingleItem:
      return 'Show 1 item';
    case hasNoItems:
      return 'Sorry, no items match';
    case isMoreThanOne:
      return `Show ${filteredCategoryItemsNumber} items`;
    case isMoreThanFifty >= 51:
      return 'Show 50+ items';
    default:
      return 'Show items';
  }
};

export default getFinalFiltersCtaText;
