import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'venue',
  ENDPOINT: 'venues',
};

const fetchVenue = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchVenue];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchVenue };
export default reducer;
