import { combineReducers, configureStore } from '@reduxjs/toolkit';

import venue from './slices/venue';
import order from './slices/order';
import menu from './slices/menu';
import cart from './slices/cart';
import tables from './slices/tables';
import user from './slices/user';
import checkout from './slices/checkout';
import pickupTimes from './slices/pickup-times';
import promotions from './slices/promotions';
import vouchers from './slices/vouchers';
import banked from './slices/banked';
import paidup from './slices/paidup';
import dietaryRequirements from './slices/dietary-requirements';
import upsells from './slices/upsells';

const reducers = combineReducers({
  venue,
  order,
  menu,
  cart,
  tables,
  user,
  checkout,
  pickupTimes,
  promotions,
  vouchers,
  banked,
  paidup,
  dietaryRequirements,
  upsells,
});

const store = configureStore({
  reducer: reducers,
});

export { store, reducers };
