import React, { useContext } from 'react';
import clsx from 'clsx';

import AppContext from '../App/AppContext';

import FullscreenModal from '../FullscreenModal';
import ItemModalAllergens from '../ItemAllergens';

import imgItemPlaceholder from '../../assets/images/item-placeholder.svg';

import cySelectors from '../../tests/cySelectors';

const ItemModal = ({
  isModalOpen,
  setIsModalOpen,
  handleCloseModal,
  currentItem,
  shouldSupportWebp = true,
  extraClasses = '',
  allergensContained,
  isVouchersModal,
  children,
}) => {
  const { itemName, imgUrl, discount } = currentItem;
  const discountAmount = discount?.discountAmount;
  const { isWebpSupported } = useContext(AppContext);
  const hasAllergens = !!allergensContained?.length;
  const webpUrl = imgUrl?.replace(/\.jpg$/, '.webp');
  const imgSrc = isWebpSupported && shouldSupportWebp ? webpUrl : imgUrl;
  const classes = clsx('item__modal', {
    [`${extraClasses}`]: extraClasses,
    'item__modal--has-allergens': hasAllergens,
    vouchers__modal: isVouchersModal,
  });

  return (
    <FullscreenModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      onCloseModal={handleCloseModal}
      extraClasses={classes}
    >
      <div className="item__modal__image__wrapper" data-cy={cySelectors.MENU_ITEMS_MODAL}>
        {hasAllergens && <ItemModalAllergens allergensContained={allergensContained} />}
        {imgUrl ? (
          <div className="item__modal__image">
            <img alt={itemName} src={imgSrc} />
          </div>
        ) : (
          <div className="item__modal__image__placeholder">
            <img alt={itemName} src={imgItemPlaceholder} />
          </div>
        )}
        {discount && (
          <div data-cy={cySelectors.MENU_ITEMS_DISCOUNT} className="item__modal__image__discount">
            - {discountAmount}%
          </div>
        )}
      </div>
      {children}
    </FullscreenModal>
  );
};

export default ItemModal;
