import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import Charge from '../Charge';
import CheckoutCodes from '../CheckoutCodes';
import normaliseCheckoutCodes from '../CheckoutCodes/normaliseCheckoutCodes';
import CheckoutContext from '../CheckoutContext';

import LoadingSpinner from '../../../components/LoadingSpinner';

import formatCurrency from '../../../utils/formatCurrency';

import AppContext from '../../../components/App/AppContext';

import cySelectors from '../../../tests/cySelectors';

const CheckoutFormFooter = ({
  children,
  handleToggleServiceCharge,
  handleToggleAdditionalCharge,
}) => {
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const {
    subtotal,
    additionalChargeSubtotal,
    discounts,
    vouchers,
    serviceCharge,
    additionalCharge,
    serviceSubtotal,
    serviceRate,
    additionalChargeRate,
    additionalChargeLabel,
    additionalChargeType,
  } = orderData || {};
  const { data: venueData } = useSelector((state) => state.venue);
  const { serviceChargeLabel } = venueData || {};
  const { finalTotal, isVouchers } = useContext(CheckoutContext);
  const { isAdditionalChargeChecked, isServiceChargeChecked } = useContext(AppContext);
  const serviceChargeData = {
    subtotal: serviceSubtotal,
    rate: serviceRate,
    label: serviceChargeLabel,
    charge: serviceCharge,
  };

  const additionalChargeData = {
    subtotal: additionalChargeSubtotal,
    rate: additionalChargeRate,
    label: additionalChargeLabel,
    charge: additionalCharge,
    type: additionalChargeType,
  };
  const isServiceChargeVisible = serviceCharge !== 'DISABLED' && !isVouchers;
  const isAdditionalChargeVisibile = additionalCharge !== 'DISABLED' && !isVouchers;
  const promoCodeDiscounts = discounts?.filter(({ promoCode }) => promoCode) || [];
  const voucherCodes = vouchers?.filter(({ voucherCode }) => voucherCode) || [];
  const checkoutCodes = [...promoCodeDiscounts, ...voucherCodes];
  const hasCheckoutCodes = !!checkoutCodes?.length;
  const formattedSubtotal = formatCurrency(subtotal);
  const formattedTotal = formatCurrency(finalTotal);
  const normalisedCheckoutCodes = normaliseCheckoutCodes(checkoutCodes);

  return (
    <div className="checkout__form__footer">
      <div className="checkout__form__footer__item">
        <span>Subtotal:</span>
        <span>{formattedSubtotal}</span>
      </div>
      {hasCheckoutCodes && <CheckoutCodes checkoutCodes={normalisedCheckoutCodes} />}
      {isServiceChargeVisible && (
        <Charge
          handleToggleCharge={handleToggleServiceCharge}
          chargeData={serviceChargeData}
          isChargeEnabled={isServiceChargeChecked}
          dataCy={cySelectors.CHECKOUT_SERVICE_CHARGE}
        />
      )}
      {isAdditionalChargeVisibile && (
        <Charge
          handleToggleCharge={handleToggleAdditionalCharge}
          chargeData={additionalChargeData}
          isChargeEnabled={isAdditionalChargeChecked}
          dataCy={cySelectors.CHECKOUT_ADDITIONAL_CHARGE}
        />
      )}
      <div className="checkout__form__footer__total">
        <p className="checkout__form__footer__total__text">Total</p>
        {orderLoading ? (
          <LoadingSpinner />
        ) : (
          <p className="checkout__form__footer__total__amount" data-cy={cySelectors.CHECKOUT_TOTAL}>
            {formattedTotal}
          </p>
        )}
      </div>
      {children}
    </div>
  );
};

export default CheckoutFormFooter;
