import React from 'react';

const ItemUpsellQuantityChanger = ({ item, itemCount, handleOpenModal, handleChangeQuantity }) => {
  const hasItemInCart = itemCount > 0;

  const handleIncreaseQuantity = () => handleChangeQuantity({ item, quantityChange: 1 });

  const handleDecreaseQuantity = () => handleChangeQuantity({ item, quantityChange: -1 });

  return (
    <div className="item-upsell-quantity-changer">
      {!hasItemInCart ? (
        <button
          type="button"
          className="item-upsell-quantity-changer__cart-btn"
          onClick={handleOpenModal}
        >
          +
        </button>
      ) : (
        <div className="item-upsell-quantity-changer__quantity-control">
          <button
            type="button"
            className="item-upsell-quantity-changer__quantity-control__btn"
            onClick={handleDecreaseQuantity}
          >
            -
          </button>
          <span className="item-upsell-quantity-changer__quantity-control__number">
            {itemCount}
          </span>
          <button
            type="button"
            className="item-upsell-quantity-changer__quantity-control__btn"
            onClick={handleIncreaseQuantity}
          >
            +
          </button>
        </div>
      )}
    </div>
  );
};

export default ItemUpsellQuantityChanger;
