import React, { useEffect, useCallback } from 'react';
import qs from 'query-string';

import isSid from '../../../utils/isSid';

import { ReactComponent as CrossIcon } from '../../../assets/images/cross-circle.svg';

const ChallengeFailure = () => {
  const handlePostSidMessage = useCallback((sid) => {
    window.parent.postMessage({ sid }, '*');
  }, []);

  useEffect(() => {
    const sid = qs.parse(window.location.search)['cko-session-id'];
    const isValidSid = isSid(sid);

    if (sid && isValidSid) {
      handlePostSidMessage(sid);
    }
  }, [handlePostSidMessage]);

  return (
    <div className="challenge--failure">
      <CrossIcon />
    </div>
  );
};

export default ChallengeFailure;
