import React from 'react';

import FormFieldSelect from '../../../components/FormFieldSelect';
import cySelectors from '../../../tests/cySelectors';

const MenuPriorities = ({ priorities, assignedPriorityId, isViewOnly }) => {
  const prioritiesOptions = priorities.map(({ name, priorityId }) => ({
    label: name,
    value: priorityId,
  }));
  const assignedPriorityName = priorities.find(
    ({ priorityId }) => priorityId === assignedPriorityId,
  ).name;
  const assignedPriority = { label: assignedPriorityName, value: assignedPriorityId };

  return (
    <div className="item__modal__priorities" data-cy={cySelectors.MENU_MODAL_PRIORITIES}>
      <FormFieldSelect
        name="priority"
        label="Course"
        helpText="Change when you want this to arrive"
        options={prioritiesOptions}
        defaultValue={assignedPriority}
        disabled={isViewOnly}
      />
    </div>
  );
};

export default MenuPriorities;
