import React from 'react';

import OverlayModal from '../OverlayModal';
import Header from '../Header';
import cySelectors from '../../tests/cySelectors';

const OpeningTimesModal = ({ isModalOpen, setIsModalOpen, openingTimes }) => (
  <OverlayModal
    appElement={document.getElementById('root')}
    isModalOpen={isModalOpen}
    setIsModalOpen={setIsModalOpen}
    extraClasses="opening-times-modal"
    data-cy={cySelectors.OPENING_TIMES_MODAL}
  >
    <Header title="Opening hours" />
    <div className="opening-times-modal__list">
      <ul>
        {openingTimes.map((openingTime, index) => {
          const key = `${openingTime}-${index}`;

          return <li key={key}>{openingTime}</li>;
        })}
      </ul>
    </div>
  </OverlayModal>
);

export default OpeningTimesModal;
