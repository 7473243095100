const createGAConfig = (userId, isDev) => {
  const config = {
    ...(isDev && { debug_mode: true }),
    ...(userId && { user_id: userId }),
  };

  return config;
};

export default createGAConfig;
