import React, { useCallback } from 'react';
import Modal from 'react-modal';

import Button from '../Button';

import cySelectors from '../../tests/cySelectors';

const OverlayModal = ({
  isModalOpen,
  setIsModalOpen,
  onCloseModal = () => {},
  extraClasses = '',
  children,
  title,
  buttonText = 'Close',
  onButtonClick = () => {},
  imgSrc,
  ...extraProps
}) => {
  const classes = `overlay-modal ${extraClasses}`;

  const handleCloseModal = useCallback(() => {
    onCloseModal();
    setIsModalOpen(false);
  }, [onCloseModal, setIsModalOpen]);

  const handleOnButtonClick = useCallback(() => {
    onButtonClick();
    handleCloseModal();
  }, [handleCloseModal, onButtonClick]);

  return (
    isModalOpen && (
      <Modal
        appElement={document.getElementById('root')}
        isOpen={isModalOpen}
        portalClassName="overlay-modal__portal"
        overlayClassName="overlay-modal__overlay"
        className={classes}
        contentLabel={title}
        onRequestClose={handleCloseModal}
        shouldCloseOnOverlayClick
      >
        <div {...extraProps} className="overlay-modal__body">
          {title && <header className="overlay-modal__title">{title}</header>}
          {imgSrc && <img src={imgSrc} className="overlay-modal__img" alt={title} />}
          <div className="overlay-modal__content">{children}</div>
          <footer className="overlay-modal__footer">
            <Button
              fullWidth
              textCenter
              type="button"
              extraClasses="overlay-modal__footer__cta"
              onClick={handleOnButtonClick}
              data-cy={cySelectors.OVERLAY_MODAL_BTN}
            >
              {buttonText}
            </Button>
          </footer>
        </div>
      </Modal>
    )
  );
};

export default OverlayModal;
