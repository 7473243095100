import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import withHandleRoutesDispatch from '../../../hoc/withHandleRoutesDispatch';

import LoadingSpinner from '../../../components/LoadingSpinner';
import Vendor from '../Vendor';
import ExternalVendorLink from '../ExternalVendorLink';

const ViewOnlyVendors = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const { venueId } = useParams();

  const { vendors, name, multiVendorCollection, externalLinks } = venueData || {};
  const hasVendors = !!vendors?.length;
  const hasExternalLinks = !!externalLinks?.length;

  return venueData ? (
    <div className="vendors">
      <div className="vendors__header">
        <h1>{name}</h1>
        {multiVendorCollection && (
          <p>
            Please choose from a venue below to view their menu. Please note, you can only view the
            menus below.
          </p>
        )}
      </div>

      <div className="vendors__list">
        {hasExternalLinks &&
          externalLinks.map((externalLink) => (
            <ExternalVendorLink key={externalLink.name} externalVendorData={externalLink} />
          ))}
        {hasVendors ? (
          vendors.map((vendorData) => (
            <Vendor key={vendorData.id} vendorData={vendorData} venueId={venueId} viewOnly />
          ))
        ) : (
          <p>No vendors found for this venue.</p>
        )}
      </div>
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(ViewOnlyVendors);
