import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CheckoutForm from './CheckoutForm';

import LoadingSpinner from '../../components/LoadingSpinner';
import PromotionsModal from '../../components/PromotionsModal';
import AppContext from '../../components/App/AppContext';
import Header from '../../components/Header';
import CartItems from '../../components/CartItems';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';

import pageNames from '../../utils/pageNames';

const Checkout = ({ isQrPayment, isVouchers = false, isDashboardVoucher = false, location }) => {
  const { data: orderData } = useSelector((state) => state.order);
  const { data: venueData } = useSelector((state) => state.venue);
  const { cart } = orderData || {};
  const { priorities, vendors } = venueData || {};
  const { data: promotionsData } = useSelector((state) => state.promotions);
  const { isPromotionModalOpen, setIsPromotionModalOpen } = useContext(AppContext);

  const hasPromotionsData = !!promotionsData?.length;

  useEffect(() => {
    if (hasPromotionsData) {
      setIsPromotionModalOpen(true);
    }
  }, [hasPromotionsData, setIsPromotionModalOpen]);

  return (
    <>
      <Header hasBackBtn={!isDashboardVoucher} title={pageNames.CHECKOUT} />
      {orderData ? (
        <div className="checkout">
          {(isQrPayment || isDashboardVoucher) && (
            <CartItems items={cart} vendors={vendors} priorities={priorities} />
          )}
          <CheckoutForm
            isQrPayment={isQrPayment}
            isVouchers={isVouchers}
            errorMessage={location?.state?.errorMessage}
          />
          {hasPromotionsData && (
            <PromotionsModal
              title="Your redeemed promotions"
              promotions={promotionsData}
              isModalOpen={isPromotionModalOpen}
              setIsModalOpen={setIsPromotionModalOpen}
            />
          )}
        </div>
      ) : (
        <LoadingSpinner center />
      )}
    </>
  );
};

export default withHandleRoutesDispatch(Checkout);
