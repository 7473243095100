import React from 'react';
import { useSelector } from 'react-redux';

import Form from '../../../components/Form';
import FormField from '../../../components/FormField';

import ReviewFormValidationSchema from './ReviewFormValidationSchema';

import cySelectors from '../../../tests/cySelectors';

const ReviewForm = ({ handleOnSubmit, setFormInstance, children }) => {
  const { data: orderData } = useSelector((state) => state.order);
  const { notes } = orderData || {};

  const defaultValues = {
    notes,
  };

  return (
    <div className="review__form">
      <Form
        handleOnSubmit={handleOnSubmit}
        validationSchema={ReviewFormValidationSchema}
        mode="onChange"
        defaultValues={defaultValues}
        setFormInstance={setFormInstance}
      >
        <FormField
          type="textarea"
          name="notes"
          placeholder="Notes"
          helpText="Let us know if you have any additional requests"
          label="Notes"
          data-cy={cySelectors.REVIEW_FORM_NEW_NOTES}
        />
        {children}
      </Form>
    </div>
  );
};

export default ReviewForm;
