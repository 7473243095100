import React, { useCallback, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, generatePath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import throttle from 'lodash.throttle';
import clsx from 'clsx';
import qs from 'query-string';

import Venue from '../../../pages/Venue';
import Menu from '../../../pages/Menu';
import Review from '../../../pages/Review';
import Details from '../../../pages/Details';
import Checkout from '../../../pages/Checkout';
import Confirmation from '../../../pages/Confirmation';
import Rating from '../../../pages/Rating';
import Vendors from '../../../pages/Vendors';
import ViewOnlyVendors from '../../../pages/Vendors/ViewOnlyVendors';
import Vouchers from '../../../pages/Vouchers';
import VouchersList from '../../../pages/VouchersList';
import VouchersItem from '../../../pages/VouchersItem';
import Collection from '../../../pages/Collection';
import CollectionConfirmation from '../../../pages/CollectionConfirmation';

import ChallengeSuccess from '../../../pages/Challenge/ChallengeSuccess';
import ChallengeFailure from '../../../pages/Challenge/ChallengeFailure';

import Head from '../../Head';
import CookieConsentModal from '../../CookieConsentModal';
import Notifications from '../../Notifications';

import PaidUpSuccess from '../../../pages/PaidUp/PaidUpSuccess';

import getHumanReadableErrorMessage from '../../../utils/getHumanReadableErrorMessage';
import setPersonalisationCssVariables from '../../../utils/setPersonalisationCssVariables';
import { errorCodes } from '../../../utils/errorCodes';

import AppContext from '../AppContext';
import initPersonalisationIframeListener from '../../../utils/initPersonalisationIframeListener';
import viewports from '../../../utils/viewports';
import UniversalConfirmation from '../../UniversalConfirmation';
import loadFonts from '../../../utils/loadFonts';

const AppManager = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const {
    isCookieConsentAgreed,
    setIsCookieConsentAgreed,
    setIsIframeBannerVisible,
    setIframeMode,
    setViewport,
    isContainedAppLayout,
    setHasMvBannerImg,
  } = useContext(AppContext);
  const { vendors, personalisation } = venueData || {};
  const queries = new URLSearchParams(window.location.search);
  const vendorIdQuery = parseInt(queries.get('vendorId'), 10) || null;
  const vendorId = vendors?.find((vendor) => vendor.id === vendorIdQuery)?.venueId;
  const classes = clsx('app', {
    'app--is-contained': isContainedAppLayout,
  });
  const { isDashboardVoucher } = qs.parse(window.location.search);

  const handleSetViewport = useCallback(() => {
    const isMobile = window.innerWidth <= viewports.MOBILE;
    const isTablet = window.innerWidth >= viewports.MOBILE && window.innerWidth <= viewports.TABLET;
    const isDesktop = window.innerWidth >= viewports.DESKTOP;

    setViewport({
      isMobile,
      isTablet,
      isDesktop,
    });
  }, [setViewport]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledhandleSetViewport = useCallback(throttle(handleSetViewport, 250), []);

  useEffect(() => {
    window.addEventListener('resize', throttledhandleSetViewport);
    window.addEventListener('load', handleSetViewport);

    return () => {
      window.removeEventListener('resize', throttledhandleSetViewport);
      window.removeEventListener('load', handleSetViewport);
    };
  }, [throttledhandleSetViewport, handleSetViewport]);

  useEffect(() => {
    if (personalisation) {
      setPersonalisationCssVariables(personalisation, vendorId).then(
        (isMultiVendorBannerImgAvailable) => setHasMvBannerImg(isMultiVendorBannerImgAvailable),
      );
    }
  }, [personalisation, setHasMvBannerImg, vendorId]);

  useEffect(() => {
    initPersonalisationIframeListener(setIframeMode, setIsIframeBannerVisible);
  }, [setIframeMode, setIsIframeBannerVisible]);

  useEffect(() => {
    if (personalisation?.font) {
      loadFonts(personalisation.font);
    }
  }, [personalisation?.font]);

  return (
    <div className={classes}>
      <Head />
      <Router>
        <Switch>
          <Route
            exact
            path="/collection-confirmation/:venueId/:orderId?"
            component={CollectionConfirmation}
          />
          <Route exact path="/collection/:venueId/:orderId?" component={Collection} />
          <Route exact path="/vendors/:venueId/menu-view" component={ViewOnlyVendors} />
          <Route exact path="/vendors/:venueId/:orderId?" component={Vendors} />
          <Route exact path="/vouchers/list/:orderId/:voucherCode" component={VouchersItem} />
          <Route exact path="/vouchers/list/:orderId" component={VouchersList} />
          <Route exact path="/vouchers/:venueId/:orderId?" component={Vouchers} />
          <Route
            exact
            path="/vouchers/review/:venueId/:orderId?"
            render={() => <Review isVouchers />}
          />
          <Route
            exact
            path="/vouchers/details/:venueId/:orderId?"
            render={() => <Details isVouchers />}
          />
          <Route
            exact
            path="/vouchers/checkout/:venueId/:orderId?"
            render={() => <Checkout isVouchers isDashboardVoucher={isDashboardVoucher} />}
          />
          <Route
            exact
            path="/vouchers/confirmation/:venueId/:orderId?"
            render={() => <Confirmation isVouchers />}
          />
          <Route exact path="/rating/:venueId/:orderId?" component={Rating} />
          <Route exact path="/challenge_success" component={ChallengeSuccess} />
          <Route exact path="/challenge_failure" component={ChallengeFailure} />
          <Route exact path="/menu/:venueId/:orderId?" component={Menu} />
          <Route exact path="/:venuePath/menu-view" component={Menu} />
          <Route exact path="/:venuePath/:tableName?" component={Venue} />

          <Route exact path="/review/:venueId/:orderId" component={Review} />
          <Route exact path="/details/:venueId/:orderId" component={Details} />
          <Route exact path="/checkout/:venueId/:orderId" component={Checkout} />
          <Route
            exact
            path="/checkout-epos/:venueId/:orderId"
            component={(props) => <Checkout isQrPayment {...props} />}
          />
          <Route exact path="/confirmation/:venueId/:orderId" component={Confirmation} />
          <Route exact path="/confirmation/paidup/:venueId/:orderId" component={PaidUpSuccess} />
          <Route
            exact
            path="/error/paidup/:venueId/:orderId"
            render={({ match }) => (
              <Redirect
                to={{
                  pathname: generatePath('/checkout/:venueId/:orderId', {
                    venueId: match.params.venueId,
                    orderId: match.params.orderId,
                  }),
                  state: {
                    errorMessage: getHumanReadableErrorMessage({
                      errorCode: errorCodes.PAIDUP_FAILED,
                    }).message,
                  },
                }}
              />
            )}
          />
        </Switch>
        <Notifications />
      </Router>
      <CookieConsentModal
        isCookieConsentAgreed={isCookieConsentAgreed}
        setIsCookieConsentAgreed={setIsCookieConsentAgreed}
      />
      <UniversalConfirmation />
    </div>
  );
};

export default AppManager;
