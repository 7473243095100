import React, { useContext, useCallback, useEffect, useMemo, createRef } from 'react';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import AppContext from '../App/AppContext';
import fuseOptions from './fuseOptions';

import getFuse from '../../utils/getFuse';

import { ReactComponent as SearchIcon } from '../../assets/images/search.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/cross.svg';

import cySelectors from '../../tests/cySelectors';

const MenuItemsSearch = () => {
  const {
    availableCategoryMenuItems,
    setSearchedCategoryMenuItems,
    isSearchOpen,
    setIsSearchOpen,
    searchQuery,
    setSearchQuery,
  } = useContext(AppContext);

  const [firstSelected, setFirstSelected] = React.useState(false);
  const searchInputRef = createRef();
  const fuse = useMemo(
    () => getFuse(availableCategoryMenuItems, fuseOptions),
    [availableCategoryMenuItems],
  );

  const searchedMenuCategoryItems = useMemo(() => {
    const pluckedSearchResults = fuse.search(searchQuery).map(({ item }) => item);

    return pluckedSearchResults;
  }, [fuse, searchQuery]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetQuery = useCallback(debounce(setSearchQuery, 333), []);
  const classes = clsx('menu-items-search', {
    'menu-items--search-open': isSearchOpen,
  });

  const handleOnChange = useCallback(
    (event) => {
      const { value } = event.target;

      debouncedSetQuery(value);
    },
    [debouncedSetQuery],
  );

  const checkForEnterKey = useCallback((event) => {
    const key = event.code || event.keyCode;
    if (key === 'Enter' || key === 13) {
      document.activeElement.blur();
    }
  }, []);

  const resetSearchQuery = useCallback(() => {
    setSearchQuery('');
  }, [setSearchQuery]);

  const focusSearchInput = useCallback(() => {
    searchInputRef.current.focus();
  }, [searchInputRef]);

  const handleToggleSearchInput = useCallback(() => {
    if (!isSearchOpen) setFirstSelected(false);
    setIsSearchOpen(!isSearchOpen);
    resetSearchQuery();
  }, [isSearchOpen, resetSearchQuery, setIsSearchOpen]);

  useEffect(() => {
    setSearchedCategoryMenuItems(searchedMenuCategoryItems);
  }, [searchedMenuCategoryItems, setSearchedCategoryMenuItems]);

  useEffect(() => {
    if (searchInputRef.current && !firstSelected) {
      focusSearchInput();
      setFirstSelected(true);
    }
  }, [focusSearchInput, searchInputRef, firstSelected]);

  return (
    <div className={classes} data-cy={cySelectors.MENU_ITEMS_SEARCH}>
      {isSearchOpen && (
        <div className="menu-items-search__input">
          <input
            ref={searchInputRef}
            placeholder="Search"
            type="search"
            enterKeyHint="search"
            onChange={handleOnChange}
            onKeyUp={checkForEnterKey}
          />
        </div>
      )}
      <div className="menu-items-search__button">
        <button type="button" onClick={handleToggleSearchInput}>
          {isSearchOpen ? <CrossIcon /> : <SearchIcon />}
        </button>
      </div>
    </div>
  );
};

export default MenuItemsSearch;
