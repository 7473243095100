import React from 'react';

import Button from '../Button';

import formatCurrency from '../../utils/formatCurrency';

import { ReactComponent as BinIcon } from '../../assets/images/bin.svg';

import cySelectors from '../../tests/cySelectors';

const CartItem = ({ cartItem, handleChangeQuantity, isOrderLoading, isEditable }) => {
  const {
    price,
    itemName,
    itemLabel,
    quantity,
    modifierLabels,
    uniqItemId,
    itemOption,
    type,
    description,
    unitPrice,
    discountedPrice,
    discountedUnitPrice,
    notes,
  } = cartItem;

  const formattedPrice = formatCurrency(price);
  const formattedFinalPrice = formatCurrency(
    discountedPrice !== undefined ? discountedPrice : price,
  );
  const hasModifiers = !!modifierLabels?.length;
  const isVoucher = type === 'VOUCHER';
  const finalItemName = isVoucher ? `${formatCurrency(unitPrice)} Voucher` : itemLabel || itemName;

  const handleRemoveItem = () =>
    handleChangeQuantity({ uniqItemId, quantityChange: 0, isDelete: true, amount: 0 });

  const handleIncreaseQuantity = () =>
    handleChangeQuantity({ uniqItemId, quantityChange: 1, amount: unitPrice });

  const handleDecreaseQuantity = () =>
    handleChangeQuantity({ uniqItemId, quantityChange: -1, amount: unitPrice });

  return (
    <div className="cart__item" data-cy={cySelectors.CART_ITEM_ROW}>
      <div className="cart__item__row" data-cy={cySelectors.CART_ITEM}>
        <div className="cart__item__info">
          <div className="cart__item__title">
            <p className="cart__item__name">{finalItemName}</p>
          </div>
          {itemOption && (
            <div>
              <p className="cart__item__option">{itemOption}</p>
            </div>
          )}
        </div>

        <div className="cart__item__prices">
          <strong
            className="cart__item__prices__price"
            data-cy={cySelectors.CART_ITEM_PRICES_PRICE}
          >
            {formattedFinalPrice}
          </strong>
          {discountedUnitPrice !== undefined && (
            <s
              className="cart__item__prices__pre-discount-price"
              data-cy={cySelectors.CART_ITEM_PRICES_PRE_DISCOUNT_PRICE}
            >
              {formattedPrice}
            </s>
          )}
        </div>
      </div>

      <div className="cart__item__modifiers">
        {hasModifiers &&
          modifierLabels.map((modifier) => (
            <p key={modifier} className="cart__item__modifier">
              {modifier}
            </p>
          ))}
        {isVoucher && description && typeof description === 'string' && (
          <p className="cart__item__modifier">{description}</p>
        )}
      </div>
      {notes && (
        <div className="cart__item__notes" data-cy={cySelectors.CART_ITEM_NOTES}>
          <strong>Note</strong>: {notes}
        </div>
      )}
      <div className="cart__item__row">
        <div className="cart__item__quantity">
          {isEditable && (
            <Button
              disabled={isOrderLoading}
              small
              moreRounded
              onClick={handleDecreaseQuantity}
              data-cy={cySelectors.CART_ITEM_DECREASE_BTN}
            >
              -
            </Button>
          )}
          <span className="cart__item__count" data-cy={cySelectors.CART_ITEM_COUNT}>
            {quantity}
          </span>
          {isEditable && (
            <Button
              disabled={isOrderLoading}
              small
              moreRounded
              onClick={handleIncreaseQuantity}
              data-cy={cySelectors.CART_ITEM_INCREASE_BTN}
            >
              +
            </Button>
          )}
        </div>
        {isEditable && (
          <div className="cart__item__remove">
            <Button
              white
              disabled={isOrderLoading}
              onClick={handleRemoveItem}
              extraClasses="cart__item__remove__btn"
              data-cy={cySelectors.CART_REMOVE_BTN}
            >
              <BinIcon title="Remove Item" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartItem;
