const errorCodes = {
  CAN_T_CHECKOUT_IN_THIS_STATUS: 'CAN_T_CHECKOUT_IN_THIS_STATUS',
  TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY: 'TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY',
  PAYMENT_FAILED: 'PAYMENT_FAILED',
  PAYMENT_DECLINED: 'PAYMENT_DECLINED',
  CARD_TOKENIZED_FAILURE: 'CARD_TOKENIZED_FAILURE',
  CLOSED: 'CLOSED',
  CLOSED_OPENING_LATER_TODAY: 'CLOSED_OPENING_LATER_TODAY',
  VENUE_NOT_FOUND: 'VENUE_NOT_FOUND',
  ORDER_NOT_FOUND: 'ORDER_NOT_FOUND',
  MENU_NOT_FOUND: 'MENU_NOT_FOUND',
  TABLES_NOT_FOUND: 'TABLES_NOT_FOUND',
  PROBLEM_ENCODING_ITEM: 'PROBLEM_ENCODING_ITEM',
  NO_ACTIVE_MENUS: 'NO_ACTIVE_MENUS',
  TABLE_NOT_FOUND: 'TABLE_NOT_FOUND',
  YOUR_CART_IS_EMPTY: 'YOUR_CART_IS_EMPTY',
  VERIFICATION_CONFIRMATION_ISSUE: 'VERIFICATION_CONFIRMATION_ISSUE',
  VERIFICATION_ISSUE: 'VERIFICATION_ISSUE',
  INVALID_ORDER_STATUS: 'INVALID_ORDER_STATUS',
  SOME_ITEMS_ARE_SOLDOUT: 'SOME_ITEMS_ARE_SOLDOUT',
  VOUCHERS_NOT_ENABLED: 'VOUCHERS_NOT_ENABLED',
  APPLEPAY_ALREADY_PAYMENT_SESSION: 'There is already has an active payment session.',
  APPLEPAY_VISA_NOT_SUPPORTED: '"visa" is not a valid payment network.',
  APPLEPAY_UNSUPPORTED_ERROR: 'The object does not support the operation or argument.',
  PAIDUP_FAILED: 'PAIDUP_FAILED',
  QR_DISABLED: 'QR_DISABLED',
  AMEX_INTERNATIONAL_CARD_NOT_SUPPORTED: 'This card is not supported',
  FAILED_TO_SEND_EMAIL: 'FAILED_TO_SEND_EMAIL',
};

const inlineErrorCodes = {
  PROMO_CODE_WILL_EXCEED_THE_DAILY_LIMIT:'PROMO_CODE_WILL_EXCEED_THE_DAILY_LIMIT',
  INVALID_PHONE: 'INVALID_PHONE',
  INVALID_EMAIL: 'INVALID_EMAIL',
  INVALID_PAYMENT: 'INVALID_PAYMENT',
  PAYMENT_FAILED_PLEASE_TRY_AGAIN: 'PAYMENT_FAILED_PLEASE_TRY_AGAIN',
  ORDER_HAS_TOTAL_OF_0: 'ORDER_HAS_TOTAL_OF_0',
  VOUCHER_EXPIRED: 'VOUCHER_EXPIRED',
  VOUCHER_NOT_STARTED_YET: 'VOUCHER_NOT_STARTED_YET',
  VOUCHER_HAS_NO_REMAINING_BALANCE: 'VOUCHER_HAS_NO_REMAINING_BALANCE',
  VOUCHER_BALANCE_INSUFFICIENT: 'VOUCHER_BALANCE_INSUFFICIENT',
  VOUCHER_IS_DISABLED: 'VOUCHER_IS_DISABLED',
  VOUCHER_ALREADY_USED_IN_THIS_ORDER: 'VOUCHER_ALREADY_USED_IN_THIS_ORDER',
  VOUCHER_NOT_FOUND: 'VOUCHER_NOT_FOUND',
  PROMO_CODE_NOT_FOUND: 'PROMO_CODE_NOT_FOUND',
  CANNOT_USE_MULTIPLE_VOUCHERS: 'CANNOT_USE_MULTIPLE_VOUCHERS',
  CODE_APPLIED_SUCCESSFULLY: 'CODE_APPLIED_SUCCESSFULLY',
  CODE_REMOVED_SUCCESSFULLY: 'CODE_REMOVED_SUCCESSFULLY',
  CODE_COULD_NOT_BE_REMOVED: 'CODE_COULD_NOT_BE_REMOVED',
  RATING_ALREADY_GIVEN: 'RATING_ALREADY_GIVEN',
  RATING_ON_UNPAID_ORDER: 'RATING_ON_UNPAID_ORDER',
  CAN_T_ACCEPT_ORDER_WITH_THIS_STATUS: 'CAN_T_ACCEPT_ORDER_WITH_THIS_STATUS',
  INVALID_PROMO_CODE: 'INVALID_PROMO_CODE',
  PROMO_ALREADY_REDEEMED: 'PROMO_ALREADY_REDEEMED',
  PROMO_CODE_LIMIT_REACHED: 'PROMO_CODE_LIMIT_REACHED',
  SORRY_IT_LOOKS_LIKE_ALL_PRODUCTS_HAVE_BEEN_REDEEMED_IN_THIS_VENUE:
    'SORRY_IT_LOOKS_LIKE_ALL_PRODUCTS_HAVE_BEEN_REDEEMED_IN_THIS_VENUE',
  CANNOT_USE_PROMOCODE_WITH_VOUCHER_CODE: 'CANNOT_USE_PROMOCODE_WITH_VOUCHER_CODE',
  PROMO_CODE_ALREADY_APPLIED: 'PROMO_CODE_ALREADY_APPLIED',
  PROMO_EXPIRED: 'PROMO_EXPIRED',
  CAMPAIGN_HAS_NOT_STARTED_YET: 'CAMPAIGN_HAS_NOT_STARTED_YET',
  CAMPAIGN_EXPIRED: 'CAMPAIGN_EXPIRED',
  CART_DOES_NOT_CONTAIN_PROMO_ITEM: 'CART_DOES_NOT_CONTAIN_PROMO_ITEM',
  PROMO_CODE_NOT_VALID_AT_THIS_DAY_TIME: 'PROMO_CODE_NOT_VALID_AT_THIS_DAY_TIME',
  AMEX_NOT_SUPPORTED_VALIDATION: 'AMEX_NOT_SUPPORTED_VALIDATION',
};

export { inlineErrorCodes, errorCodes };
