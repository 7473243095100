import groupMenuCategoryItemsByCategoryName from '../../utils/groupMenuCategoryItemsByCategoryName';

const getFinalMenu = (
  searchedCategoryMenuItems,
  filteredCategoryMenuItems,
  hasFiltersSelected,
  menu,
) => {
  const hasSearchResults = !!searchedCategoryMenuItems.length;
  const hasFilteredResults = !!filteredCategoryMenuItems.length;
  const hasNoMenuItems = Object.keys(menu).length;

  switch (true) {
    case hasSearchResults:
      return groupMenuCategoryItemsByCategoryName(searchedCategoryMenuItems);
    case hasFilteredResults && hasFiltersSelected:
      return filteredCategoryMenuItems;
    case hasNoMenuItems:
      return false;
    default:
      return menu;
  }
};

export default getFinalMenu;
