import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import clsx from 'clsx';
import 'react-datepicker/dist/react-datepicker.css';
import { add } from 'date-fns';
import isValidDate from '../../utils/isValidDate';

const offset = new Date().getTimezoneOffset();
const FormFieldDatePicker = ({ name, label, helpText, datePickerProps, extraClasses }) => {
  const { startDate } = datePickerProps;
  const {
    formState: { errors },
  } = useFormContext();
  const classes = clsx(
    {
      [extraClasses]: extraClasses,
    },
    'form__field__date-picker',
  );

  return (
    <div className={classes}>
      {label && (
        <label aria-label={label} htmlFor={name} className="form__field__label">
          {label}
        </label>
      )}
      {helpText && <small className="form__field__help-text">{helpText}</small>}
      <Controller
        defaultValue={startDate || new Date()}
        id={name}
        name={name}
        render={({ field }) => {
          const selectedValue = isValidDate(field.value)
            ? add(field.value, { minutes: -offset })
            : field.value;
          return (
            <>
              <DatePicker
                id={name}
                placeholderText="Select a date"
                {...field}
                onChange={field.onChange}
                selected={selectedValue}
                {...datePickerProps}
                dateFormat="dd/MM/yyyy"
              />
            </>
          );
        }}
      />
      {errors[name] && <div className="form__field__error">{errors[name].message}</div>}
    </div>
  );
};

export default FormFieldDatePicker;
