import React from 'react';

import MenuFiltersDietaryRequirement from '../MenuFiltersDietaryRequirement';

const MenuFiltersDietaryRequirements = ({
  requirements,
  type,
  heading,
  subHeading,
  register,
  selectedRequirements,
}) => {
  const getRequirementSelectedValue = (requirementId) =>
    selectedRequirements?.includes(requirementId);

  return (
    <fieldset className="menu-filters-dietary-requirements">
      <legend>{heading}</legend>
      {subHeading && <p className="menu-filters-dietary-requirements__sub-heading">{subHeading}</p>}
      {requirements?.map((requirement) => (
        <MenuFiltersDietaryRequirement
          key={requirement.requirementId}
          requirement={requirement}
          register={register}
          type={type}
          getRequirementSelectedValue={getRequirementSelectedValue}
        />
      ))}
    </fieldset>
  );
};

export default MenuFiltersDietaryRequirements;
