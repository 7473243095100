import React from 'react';

import { ReactComponent as ChevronRightIcon } from '../../../assets/images/chevron-right.svg';

import cySelectors from '../../../tests/cySelectors';

const ExternalVendorLink = ({ externalVendorData }) => {
  const { image, name, url } = externalVendorData;

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className="external-vendor"
      data-cy={cySelectors.EXTERNAL_VENDOR_LINK}
    >
      <div className="external-vendor__wrapper">
        {image && (
          <div className="external-vendor__image">
            <img src={image} alt={name} />
          </div>
        )}
        <div className="external-vendor__cta">
          <div className="external-vendor__cta__text">
            <strong className="external-vendor__cta__text__name">{name}</strong>
          </div>
          <ChevronRightIcon className="external-vendor__cta__icon" />
        </div>
      </div>
    </a>
  );
};

export default ExternalVendorLink;
