import VoucherImg from '../../assets/images/vouchers.png';
import VoucherImgThumb from '../../assets/images/vouchers-thumb.png';

const voucherTypes = {
  FLAT: 'FLAT',
  CUSTOM: 'CUSTOM',
};

const voucherPrices = [10, 25, 50, 100, 250];

const vouchersData = [
  {
    categoryItems: [
      {
        itemId: '0',
        itemName: 'Custom Voucher',
        description: 'Purchase a voucher to use at a later date',
        itemPrices: [voucherPrices[0], voucherPrices[1]],
        type: voucherTypes.CUSTOM,
        thumbUrl: VoucherImgThumb,
        imgUrl: VoucherImg,
      },
      {
        itemId: '1',
        itemName: '£25 Voucher',
        description: 'Purchase a voucher to use at a later date',
        itemPrices: [voucherPrices[1]],
        type: voucherTypes.FLAT,
        thumbUrl: VoucherImgThumb,
        imgUrl: VoucherImg,
      },
      {
        itemId: '2',
        itemName: '£50 Voucher',
        description: 'Purchase a voucher to use at a later date',
        itemPrices: [voucherPrices[2]],
        type: voucherTypes.FLAT,
        thumbUrl: VoucherImgThumb,
        imgUrl: VoucherImg,
      },
      {
        itemId: '3',
        itemName: '£100 Voucher',
        description: 'Purchase a voucher to use at a later date',
        itemPrices: [voucherPrices[3]],
        type: voucherTypes.FLAT,
        thumbUrl: VoucherImgThumb,
        imgUrl: VoucherImg,
      },
      {
        itemId: '4',
        itemName: '£250 Voucher',
        description: 'Purchase a voucher to use at a later date',
        itemPrices: [voucherPrices[4]],
        type: voucherTypes.FLAT,
        thumbUrl: VoucherImgThumb,
        imgUrl: VoucherImg,
      },
    ],
  },
];

export default vouchersData;
