import React, { useCallback, useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';

import Button from '../../../components/Button';

import MenuFiltersDietaryRequirements from '../MenuFiltersDietaryRequirements';
import getFinalFiltersCtaText from './getFinalFiltersCtaText';
import cySelectors from '../../../tests/cySelectors';

const MenuFiltersModal = ({
  isModalOpen,
  handleToggleModal,
  hasFiltersSelected,
  setFilters,
  filteredCategoryItemsNumber,
  dietaryRequirementsData,
}) => {
  const [isContentShown, setIsContentShown] = useState(false);
  const [isModalClosing, setIsModalClosing] = useState(false);
  const contentRef = useRef(null);
  const dietary = dietaryRequirementsData.filter(({ type }) => type === 'requirement');
  const allergens = dietaryRequirementsData.filter(({ type }) => type === 'allergen');
  const finalCtaText = getFinalFiltersCtaText(filteredCategoryItemsNumber);
  const { register, reset, getValues } = useForm({
    shouldUseNativeValidation: true,
    defaultValues: {
      allergens: [],
      dietary: [],
    },
  });
  const selectedDietaryRequirements = getValues('dietary');
  const selectedAllergens = getValues('allergens');

  const handleOnFormChange = useCallback(() => {
    const filterValues = getValues();

    setFilters(filterValues);
  }, [getValues, setFilters]);

  const classes = clsx('menu__filters__modal__content', {
    'menu__filters__modal__content--is-visible': isContentShown,
    'menu__filters__modal__content--is-closing': isModalClosing,
    'menu__filters__modal__content--has-filters': hasFiltersSelected,
  });

  const handletCloseModal = useCallback(() => {
    setIsModalClosing(true);
    handleToggleModal();
  }, [handleToggleModal]);

  const handleOnAfterCloseCleanup = () => {
    setIsModalClosing(false);
    setIsContentShown(false);
  };

  const handleResetFilters = useCallback(() => {
    reset({
      allergens: [],
      dietary: [],
    });
    setFilters({
      allergens: [],
      dietary: [],
    });
  }, [reset, setFilters]);

  const handleCloseModalFromOverlay = useCallback(
    (event) => {
      const { target } = event;
      const isContentRef = target === contentRef.current;

      if (isContentRef) {
        handletCloseModal();
      }
    },
    [handletCloseModal],
  );

  useEffect(() => {
    if (!hasFiltersSelected) {
      handleResetFilters();
    }
  }, [handleResetFilters, hasFiltersSelected]);

  return (
    // TODO: refactor into an animated slide up modal for <MenuFiltersModal /> and <CheckoutPaidUpModal />
    <Modal
      appElement={document.getElementById('root')}
      isOpen={isModalOpen}
      overlayClassName="menu__filters__modal__overlay"
      portalClassName="menu__filters__modal"
      className={classes}
      onAfterOpen={() => setIsContentShown(true)}
      onAfterClose={handleOnAfterCloseCleanup}
      closeTimeoutMS={500}
      contentElement={(props, contentElement) => (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <div
          {...props}
          onKeyDown={handleCloseModalFromOverlay}
          onClick={handleCloseModalFromOverlay}
          ref={contentRef}
        >
          {contentElement}
        </div>
      )}
    >
      <div className="menu__filters__modal__content__box">
        <div className="menu__filters__modal__content__box__header">
          <p className="menu__filters__modal__content__box__header__title">Preferences</p>
          <button
            onClick={handletCloseModal}
            type="button"
            data-cy={cySelectors.MENU_MODAL_FILTERS_CLOSE_BTN}
          >
            Close
          </button>
        </div>
        <form
          onChange={handleOnFormChange}
          onReset={handleResetFilters}
          className="menu__filters__modal__content__box__form"
        >
          <div
            className="menu__filters__modal__content__box__form__dietary"
            data-cy={cySelectors.MENU_MODAL_FILTERS_PREFERENCES}
          >
            <MenuFiltersDietaryRequirements
              type="dietary"
              requirements={dietary}
              heading="Dietary"
              register={register}
              selectedRequirements={selectedDietaryRequirements}
            />
          </div>
          <div
            className="menu__filters__modal__content__box__form__allergens"
            data-cy={cySelectors.MENU_MODAL_FILTERS_ALLERGENS}
          >
            <MenuFiltersDietaryRequirements
              type="allergens"
              requirements={allergens}
              heading="Do you have a food allergy?"
              subHeading="We’ll prevent you from ordering any food and drink with selected allergies"
              register={register}
              selectedRequirements={selectedAllergens}
            />
          </div>
          <div className="menu__filters__modal__content__box__form__footer">
            {hasFiltersSelected && (
              <button
                className="menu__filters__modal__content__box__form__footer__clear-all-btn"
                type="button"
                onClick={handleResetFilters}
                data-cy={cySelectors.MENU_MODAL_FILTERS_CLEAR}
              >
                Clear all
              </button>
            )}
            <Button
              type="button"
              onClick={handletCloseModal}
              dataCy={cySelectors.MENU_MODAL_FILTERS_SUBMIT}
            >
              {finalCtaText}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default MenuFiltersModal;
