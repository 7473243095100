import React, { useRef } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';

import MenuItemsSearch from '../../../components/MenuItemsSearch';
import ExternalLink from '../../../components/ExternalLink';

import cySelectors from '../../../tests/cySelectors';
import BackButton from '../../../components/BackButton';

const MenuPageHeaderDesktop = ({
  title,
  hasBanner,
  isSearchOpen,
  hasVoucherCta = true,
  hasSearch = true,
  venueId,
  hasBackBtn,
}) => {
  const { orderId } = useParams();
  const vendorsPath = `/vendors/${venueId}/${orderId}`;
  const isVoucherCtaVisible = !isSearchOpen && hasVoucherCta;
  const headerRef = useRef(null);
  const classes = clsx('menu-page-header__deskop', {
    'menu-page-header_deskop--has-banner': hasBanner,
    'menu-page-header_deskop--is-search-open': isSearchOpen,
  });

  return (
    <header className={classes} ref={headerRef} data-cy={cySelectors.MENU__PAGE__HEADER__DESKTOP}>
      <div className="menu-page-header__deskop__bar">
        {hasBackBtn && (
          <div className="menu-page-header__back">
            <BackButton to={vendorsPath} />
          </div>
        )}
        {!isSearchOpen && <p className="menu-page-header__deskop__bar__title">{title}</p>}
        <div className="menu-page-header__deskop__bar__ctas">
          {isVoucherCtaVisible && (
            <ExternalLink
              secondary
              isExternalLink
              to={`/vouchers/${venueId}`}
              extraClasses="menu-page-header__deskop__bar__cta"
            >
              Buy a gift voucher
            </ExternalLink>
          )}
          {hasSearch && <MenuItemsSearch />}
        </div>
      </div>
    </header>
  );
};

export default MenuPageHeaderDesktop;
