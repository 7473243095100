import axios from 'axios';

const fetchFontData = async (venueFont) => {
  const response = await axios.get(
    // eslint-disable-next-line max-len
    `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}&sort=popularity&capability=WOFF2`,
  );

  return response.data.items.filter((item) => item.family === venueFont);
};

export default fetchFontData;
