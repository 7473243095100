import getHumanReadableErrorMessage from './getHumanReadableErrorMessage';

/**
 * ServedUpError is a custom error class that extends the native Error and is used to handle errors that are
 * thrown by the ServedUp API or passing custom error enums from errorCodes.js
 *
 *
 * @param {Object} error - The error object that is returned by the ServedUp API
 * @param {String} venueId - The venueId of the venue that the error occurred in
 * @param {String} orderId - The orderId of the order that the error occurred in
 *
 * @returns {Object} - An instance of the ServedUpError class
 *
 * @example
 *
 * try {
 *  const response = await axios.get(process.env.ENDPOINT)
 * } catch (error) {
 *
 *  throw new ServedUpError({ error, venueId, orderId })
 * }
 *
 * */

class ServedUpError extends Error {
  constructor({ error, venueId, orderId }) {
    super();
    const { message, redirect, code, venuePath, venueName } = getHumanReadableErrorMessage(
      error,
      venueId,
      orderId,
    );
    this.code = code;
    this.message = message;
    this.redirect = redirect;
    this.venuePath = venuePath;
    this.venueName = venueName;
    this.name = 'ServedUpError';
  }
}

export default ServedUpError;
