import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CheckoutContext from '../CheckoutContext';

import { deletePromoCode } from '../../../store/slices/order';
import formatCurrency from '../../../utils/formatCurrency';

import cySelectors from '../../../tests/cySelectors';
import { inlineErrorCodes } from '../../../utils/errorCodes';
import getHumanReadableErrorMessage from '../../../utils/getHumanReadableErrorMessage';

const CheckoutCodes = ({ checkoutCodes }) => {
  const { setCheckoutCodeSuccessResponse, checkoutCodeFormInstance } = useContext(CheckoutContext);
  const { venueId, orderId } = useParams();
  const dispatch = useDispatch();

  const handleDeleteCheckoutCode = async () => {
    const payload = { venueId };

    try {
      await dispatch(deletePromoCode({ params: orderId, payload })).unwrap();

      const { message: successResponseMessage } = getHumanReadableErrorMessage({
        errorCode: inlineErrorCodes.CODE_REMOVED_SUCCESSFULLY,
      });

      setCheckoutCodeSuccessResponse(successResponseMessage);

      checkoutCodeFormInstance.clearErrors();
      checkoutCodeFormInstance.reset();
    } catch (error) {
      const errorResponseMessage = {
        type: 'manual',
        message: getHumanReadableErrorMessage(
          {
            errorCode: inlineErrorCodes.CODE_COULD_NOT_BE_REMOVED,
          }.message,
        ),
      };

      checkoutCodeFormInstance.setError('code', errorResponseMessage);
    }
  };
  return (
    <div className="checkout-codes">
      <p>Vouchers & Promo codes:</p>
      {checkoutCodes.map((checkoutCode) => (
        <CheckoutCode
          key={checkoutCode.code}
          checkoutCode={checkoutCode}
          handleDeleteCheckoutCode={handleDeleteCheckoutCode}
        />
      ))}
      <button
        type="button"
        className="checkout-codes__remove-btn"
        onClick={handleDeleteCheckoutCode}
        data-cy={cySelectors.CHECKOUTCODE_REMOVE_BTN}
      >
        Remove
      </button>
    </div>
  );
};

const CheckoutCode = ({ checkoutCode }) => {
  const { offer, code, discount, amountUsed } = checkoutCode;

  const formattedDiscount = formatCurrency(discount);
  const formattedAmountUsed = formatCurrency(amountUsed);

  return (
    <div key={code} className="checkout-code">
      <span>
        {offer} ({code})
      </span>
      {discount && <span>-{formattedDiscount}</span>}
      {amountUsed && <span>Used: {formattedAmountUsed}</span>}
    </div>
  );
};

export default CheckoutCodes;
