import axios from 'axios';
import ServedUpError from '../../utils/ServedUpError';

const updateOrderPaidUpWithBank = async (provider, bankedId, email, name) => {
  const { id } = provider;
  const body = {
    provider_id: id,
    payer_name: name,
    payer_email: email,
  };
  try {
    const { data: bankedCheckoutSession } = await axios.patch(
      `${process.env.REACT_APP_BANKED_API_TOKEN_ENDPOINT}/${bankedId}?api_key=${process.env.REACT_APP_BANKED_API_KEY}`,
      body,
    );
    return bankedCheckoutSession;
  } catch (error) {
    throw new ServedUpError({
      error,
    });
  }
};

export default updateOrderPaidUpWithBank;
