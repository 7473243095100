import React from 'react';

import cySelectors from '../../tests/cySelectors';
import ExternalLink from '../ExternalLink';

const VoucherCTA = ({ venueName = 'this venue', venueId }) => (
  <div className="voucher-cta" data-cy={cySelectors.VOUCHERS_BANNER}>
    <h2 className="voucher-cta__title">Gift vouchers</h2>
    <p>
      Surprise someone that loves <br />
      {venueName}!
    </p>

    <ExternalLink to={`/vouchers/${venueId}`} extraClasses="voucher-cta__btn">
      Buy a gift voucher
    </ExternalLink>
  </div>
);

export default VoucherCTA;
