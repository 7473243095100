import fetchFontData from '../api/googleFonts/index';

const createFontFace = (font) => `
      /* latin-ext */
      @font-face {
        font-family: '${font.family}';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${font.files.regular}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin */
      @font-face {
        font-family: '${font.family}';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(${font.files.regular}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
        U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      /* latin */
      @font-face {
        font-family: '${font.family}';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(${font.files[600] || font.files.regular}) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113,
        U+2C60-2C7F, U+A720-A7FF;
      }
      /* latin-ext */
      @font-face {
        font-family: '${font.family}';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url(${font.files[600] || font.files.regular}) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F,
        U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `;

const injectFontFaces = (fontFace) => {
  const style = document.createElement('style');
  style.innerHTML = fontFace;
  document.head.appendChild(style);
};

const loadFonts = async (font) => {
  const personalisedFont = await fetchFontData(font);

  if (personalisedFont.length > 0) {
    const fontFace = createFontFace(personalisedFont[0]);
    injectFontFaces(fontFace);
  }
};

export default loadFonts;
