const getNewUserPreferencesString = (userPreferences, payload) => {
  const newUserPreferences = JSON.stringify({
    ...userPreferences,
    ...payload,
  });

  return newUserPreferences;
};

export default getNewUserPreferencesString;
