import React from 'react';

import BackButton from '../BackButton';

const Header = ({ title, hasBackBtn }) => (
  <header className="header">
    <div className="header__bar">
      {hasBackBtn && (
        <div className="header__bar__back">
          <BackButton />
        </div>
      )}
      <div className="header__bar__title">{title}</div>
    </div>
  </header>
);

export default Header;
