import React from 'react';
import InfoBanner, { infoBannerLevels } from '../InfoBanner';

import { ReactComponent as ClockIcon } from '../../assets/images/clock.svg';

import cySelectors from '../../tests/cySelectors';

const VenueTakeAwayWarning = () => (
  <InfoBanner
    data-cy={cySelectors.BANNER_VENUE_TAKEAWAY}
    Icon={ClockIcon}
    title="We're closed!"
    level={infoBannerLevels.WARN}
  >
    <p>But you can still order a scheduled takeaway.</p>
  </InfoBanner>
);

export default VenueTakeAwayWarning;
