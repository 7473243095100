import { errorCodes } from '../../../utils/errorCodes';

const getUniqueItemId = (itemPayload) => {
  try {
    const encoded = window.btoa(unescape(encodeURIComponent(JSON.stringify(itemPayload))));

    return encoded;
  } catch (error) {
    throw new Error(errorCodes.PROBLEM_ENCODING_ITEM);
  }
};

export default getUniqueItemId;
