import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';

// TODO update backend to be able to collate quantities in BE
const getChangeCartVoucherQuantityPayload = (
  cart,
  uniqItemId,
  quantityChange,
  isDelete = false,
  amount,
) => {
  const updatedCart = [...cart];
  const targetItemIndex = updatedCart.findIndex((cartItem) => cartItem.uniqItemId === uniqItemId);
  const { quantity } = updatedCart[targetItemIndex];
  const updatedQuantity = quantity + quantityChange;

  const newItemPayload = omitBy(
    {
      ...updatedCart[targetItemIndex],
      quantity: updatedQuantity,
      isDelete,
      uniqItemId,
      amount,
    },
    (prop) => isNil(prop) || prop === '',
  );

  updatedCart[targetItemIndex] = newItemPayload;

  return updatedCart;
};

export default getChangeCartVoucherQuantityPayload;
