/* eslint-disable no-unused-vars */
import React, { lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import getVoucherFromVoucherCode from './getVoucherFromVoucherCode';

import LoadingSpinner from '../../components/LoadingSpinner';

import formatCurrency from '../../utils/formatCurrency';

import { ReactComponent as VoucherIcon } from '../../assets/images/voucher.svg';

import cySelectors from '../../tests/cySelectors';

const QRCode = lazy(() => import('qrcode.react'));

const VoucherItem = () => {
  const { voucherCode } = useParams();
  const { data: vouchersData } = useSelector((state) => state.vouchers);
  const voucher = getVoucherFromVoucherCode(vouchersData, voucherCode);
  const hasVoucher = !!voucher;
  const { startDate, endDate, amount, balance } = voucher || {};
  const spent = amount - balance;
  const finalValidFrom =
    startDate &&
    endDate &&
    `${format(new Date(startDate), 'dd/MM/yy')} - ${format(new Date(endDate), 'dd/MM/yy')}`;
  const formattedAmount = formatCurrency(amount);
  const formattedSpent = formatCurrency(spent);
  const formattedBalance = formatCurrency(balance);

  return vouchersData ? (
    <div className="voucher-item">
      <VoucherIcon alt="Voucher" className="voucher-item__icon" />
      <div className="voucher-item__title">
        <h1>Voucher Details</h1>
      </div>
      {!hasVoucher ? (
        <p>There was an issue in retrieving your voucher ({voucherCode}).</p>
      ) : (
        <div className="voucher-item__details">
          <Suspense fallback={<LoadingSpinner />}>
            <div className="voucher-item__details__qr">
              <QRCode value={voucherCode} />
            </div>
          </Suspense>
          <div className="voucher-item__details__field">
            <strong className="voucher-item__details__field__label">Valid from</strong>
            <div className="voucher-item__details__field__value">{finalValidFrom}</div>
          </div>
          <div className="voucher-item__details__field">
            <strong className="voucher-item__details__field__label">Code</strong>
            <div className="voucher-item__details__field__value">{voucherCode}</div>
          </div>

          <div className="voucher-item__details__field">
            <strong className="voucher-item__details__field__label">Amount</strong>
            <div className="voucher-item__details__field__value">{formattedAmount}</div>
          </div>

          <div className="voucher-item__details__field--spent">
            <strong className="voucher-item__details__field__label">Amount spent</strong>
            <div className="voucher-item__details__field__value">{formattedSpent}</div>
          </div>

          <div className="voucher-item__details__field--balance">
            <strong className="voucher-item__details__field__label">Current balance</strong>
            <div
              className="voucher-item__details__field__value"
              data-cy={cySelectors.VOUCHERS_CURRENT_BALANCE}
            >
              {formattedBalance}
            </div>
          </div>
        </div>
      )}
    </div>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(VoucherItem);
