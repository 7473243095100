import React from 'react';

import cySelectors from '../../tests/cySelectors';

const OpeningTimes = ({ handleOpenModal }) => (
  <button
    type="button"
    className="opening-times"
    onClick={handleOpenModal}
    data-cy={cySelectors.OPENING_TIMES}
  >
    Opening hours
  </button>
);

export default OpeningTimes;
