const getSanitisedPhoneWithCountryCode = (phone, countryCode) => {
  const isValidPhone = phone && countryCode;

  if (isValidPhone) {
    const sanitisedPhone = phone?.replace(new RegExp(/^0/), '');
    const phoneWithCountryCode = `${countryCode}${sanitisedPhone}`;
    return phoneWithCountryCode;
  }

  return null;
};

export default getSanitisedPhoneWithCountryCode;
