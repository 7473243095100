import React from 'react';

import { ReactComponent as MinusIcon } from '../../assets/images/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/plus.svg';

import cySelectors from '../../tests/cySelectors';

const QuantityChanger = ({ quantity, increaseQuantity, decreaseQuantity, disabled }) => (
  <div className="quantity-changers">
    <button
      className="quantity-changer"
      disabled={quantity === 1 || disabled}
      onClick={decreaseQuantity}
      type="button"
      data-cy={cySelectors.MODIFIERS_DECREASE_BTN}
    >
      <MinusIcon />
    </button>
    <span className="quantity-changer__count">{quantity}</span>
    <button
      disabled={disabled}
      className="quantity-changer"
      onClick={increaseQuantity}
      type="button"
      data-cy={cySelectors.MODIFIERS_INCREASE_BTN}
    >
      <PlusIcon />
    </button>
  </div>
);

export default QuantityChanger;
