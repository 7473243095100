import React, { useEffect, useState } from 'react';

import OverlayModal from '../OverlayModal';

import upsertUserPreferences from '../../utils/upsertUserPreferences';

import cookiesImg from '../../assets/images/cookies.png';

import cySelectors from '../../tests/cySelectors';

const CookieConsentModal = ({ isCookieConsentAgreed, setIsCookieConsentAgreed }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSavePreferences = () => {
    const payload = {
      cookieConsentAgreed: true,
    };

    upsertUserPreferences(payload);
    setIsCookieConsentAgreed(true);
  };

  useEffect(() => {
    setIsModalOpen(isCookieConsentAgreed !== null && !isCookieConsentAgreed);
  }, [isCookieConsentAgreed]);

  return (
    <OverlayModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      extraClasses="cookie-consent__modal"
      data-cy={cySelectors.COOKIE_CONSENT_MODAL}
      buttonText="Accept"
      onButtonClick={handleSavePreferences}
      imgSrc={cookiesImg}
    >
      <p>This website uses cookies to improve your experience ❤️</p>
      <a href="https://servedup.co/privacy" target="_blank" rel="noreferrer">
        Learn more
      </a>
    </OverlayModal>
  );
};

export default CookieConsentModal;
