import md5 from 'md5';

const setGAUserId = (phone, countryCodeValue) => {
  const noTrailingLocalStoragePHone = parseInt(phone, 10).toFixed(0);
  const hashedPhone = md5(`${countryCodeValue}${noTrailingLocalStoragePHone}`);

  if (window.gtag) {
    window.gtag('set', { user_id: hashedPhone });
  }
};

export default setGAUserId;
