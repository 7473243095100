import React from 'react';
import getFiltersNames from './getFiltersName';

const MenuFiltersBar = ({
  filters,
  handleResetFilters,
  dietaryRequirementsData,
  handleToggleModal,
}) => {
  const { allergens, dietary } = filters;
  const allergensNames = getFiltersNames(allergens, dietaryRequirementsData);
  const dietaryNames = getFiltersNames(dietary, dietaryRequirementsData);
  const hasBothDietaryRequirements = !!allergens.length && !!dietary.length;

  return (
    <div className="menu-filters-bar">
      <div
        className="menu-filters-bar__requirements"
        onClick={handleToggleModal}
        onKeyDown={handleToggleModal}
        role="button"
        tabIndex={0}
      >
        {dietaryNames.map((filter, index) => {
          const isLastDietaryItem = index < dietary.length - 1;

          return (
            <span className="menu-filters-bar__requirements__dietary" key={filter}>
              {filter}
              {isLastDietaryItem && ','}
            </span>
          );
        })}
        {hasBothDietaryRequirements && (
          <span className="menu-filters-bar__requirements__divider">•</span>
        )}
        {allergensNames.map((filter, index) => {
          const isLastAllergenItem = index < allergens.length - 1;

          return (
            <span className="menu-filters-bar__requirements__allergen" key={filter}>
              {filter}
              {isLastAllergenItem && ','}
            </span>
          );
        })}
      </div>
      <button
        type="button"
        onClick={handleResetFilters}
        className="menu-filters-bar__requirements__clear-btn"
      >
        Clear
      </button>
    </div>
  );
};

export default MenuFiltersBar;
