import { safeSubtract, safeSum } from '../../../utils/safeArithmetics';

const calculateFinalTotal = (
  isPayServiceCharge,
  hasVouchers,
  total,
  subtotal,
  totalDiscount,
  finalServiceSubtotal,
  vouchersAmountUsed,
  isPayAdditionalCharge,
  additionalChargeSubtotal,
) => {
  const hasServiceChargeAndVouchers = isPayServiceCharge && hasVouchers;
  const hasServiceChargeNoVouchers = isPayServiceCharge && !hasVouchers;

  const finalAdditionalChargeSubtotal = isPayAdditionalCharge ? additionalChargeSubtotal : 0;

  let finalTotal = 0;

  switch (true) {
    case hasServiceChargeAndVouchers:
      finalTotal = safeSubtract(
        safeSum(subtotal, finalServiceSubtotal),
        vouchersAmountUsed,
        totalDiscount,
      );
      break;
    case hasServiceChargeNoVouchers:
      finalTotal = safeSum(total, finalServiceSubtotal);
      break;
    default:
      finalTotal = safeSum(total);
      break;
  }

  return safeSum(finalTotal, finalAdditionalChargeSubtotal);
};

export default calculateFinalTotal;
