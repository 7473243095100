import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';

import ChoiceInput from '../../../components/ChoiceInput';
import choiceInputTypes from '../../../components/ChoiceInput/choiceInputTypes';

import formatCurrency from '../../../utils/formatCurrency';
import { safeMultiply } from '../../../utils/safeArithmetics';

import cySelectors from '../../../tests/cySelectors';

const MenuModalSize = ({ itemPrices, itemOptions, discount, handleSetSizeTotal }) => {
  const { register, setValue } = useFormContext();
  const [selected, setSelected] = useState({});
  const sizes = itemOptions.map((itemOption, i) => ({
    itemName: itemOption,
    itemPrice: itemPrices[i],
  }));
  const handleOnChange = (value) => {
    setSelected(value);
  };

  const preselectFirstOption = useCallback((options) => {
    const [firstOption] = options;

    setSelected(firstOption);
  }, []);

  const presetFirstOptionValue = useCallback(
    (options, values) => {
      const [firstOption] = options;
      const [firstValue] = values;

      handleSetSizeTotal(firstValue);
      setValue('itemOption', firstOption);
    },
    [handleSetSizeTotal, setValue],
  );

  const getCheckedItemStatus = (value, selectedValue) => value === selectedValue;

  useEffect(() => {
    preselectFirstOption(itemOptions);
    presetFirstOptionValue(itemOptions, itemPrices);
  }, [preselectFirstOption, presetFirstOptionValue, itemPrices, itemOptions]);

  return (
    <div
      className="item__modal__size"
      style={itemOptions.length <= 1 ? { display: 'none' } : { display: 'block' }}
      data-cy={cySelectors.MODIFIERS_SIZES}
    >
      <fieldset>
        <legend>Select size</legend>
        {sizes.map(({ itemName, itemPrice }) => {
          // Declaration needed beforehand as we want to run register's methods + custom ones
          const { onChange, ref: innerRef, onBlur } = register(`itemOption`);
          const formattedPrice = formatCurrency(itemPrice);
          const discountValue = discount?.discountAmount;
          const discountedAmount = safeMultiply(itemPrice / 100, discountValue);
          const finalFormattedDiscountedPrice = formatCurrency(itemPrice - discountedAmount);
          const classes = clsx('item__modal__size__choice', {
            'item__modal__size__choice--has-discount': discount,
          });
          return (
            <div key={itemName} className={classes} data-cy={cySelectors.MODIFIERS_SIZE}>
              <label htmlFor={itemName}>
                <span className="item__modal__size__choice__name">{itemName}</span>
                <div className="item__modal__size__choice__prices">
                  <span className="item__modal__size__choice__prices__price__amount">
                    {formattedPrice}
                  </span>
                  {discount && (
                    <span className="item__modal__size__choice__prices__price__discounted">
                      {finalFormattedDiscountedPrice}
                    </span>
                  )}
                </div>
                <ChoiceInput
                  type={choiceInputTypes.RADIO}
                  onChange={(event) => {
                    onChange(event);
                    handleSetSizeTotal(itemPrice);
                    handleOnChange(itemName);
                  }}
                  checked={getCheckedItemStatus(itemName, selected)}
                  onBlur={onBlur}
                  id={itemName}
                  name="itemOption"
                  value={itemName}
                  innerRef={innerRef}
                />
              </label>
            </div>
          );
        })}
      </fieldset>
    </div>
  );
};

export default MenuModalSize;
