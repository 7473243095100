import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ExternalLinkIcon } from '../../assets/images/external-link.svg';

const ExternalLink = ({ to, extraClasses, children }) => (
  <Link className={`external-link ${extraClasses}`} target="_blank" to={to}>
    {children}
    <span className="external-link__icon">
      <ExternalLinkIcon />
    </span>
  </Link>
);

export default ExternalLink;
