const sendGAPromotionSeen = (campaignId, itemId) => {
  if (window.gtag) {
    window.gtag('event', 'view_promotion', {
      promotion_id: campaignId,
      items: [{ item_id: itemId }],
    });
  }
};

export default sendGAPromotionSeen;
