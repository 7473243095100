import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import withHandleRoutesDispatch from '../../hoc/withHandleRoutesDispatch';
import useQuery from '../../hooks/useQuery';

import MenuCategories from '../../components/MenuCategories';
import VouchersModal from './VouchersModal';
import LoadingSpinner from '../../components/LoadingSpinner';
import FooterCta from '../../components/FooterCta';
import AppContext from '../../components/App/AppContext';

import formatCurrency from '../../utils/formatCurrency';
import getVoucherFromItemId from './getVoucherFromItemId';

import vouchersData from './vouchersData';

import MenuPageHeader from '../Menu/MenuPageHeader';
import MenuPageHeaderDesktop from '../Menu/MenuPageHeaderDesktop';

import cySelectors from '../../tests/cySelectors';
import HeroBannerDesktop from '../../components/HeroBannerDesktop';
import HeroBanner from '../../components/HeroBanner';
import getItemCount from '../Menu/getItemCount';

const Vouchers = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const history = useHistory();
  const queries = useQuery();
  const { orderId, venueId } = useParams();
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData, loading: orderLoading } = useSelector((state) => state.order);
  const { data: cartData } = useSelector((state) => state.cart);
  const { subtotal } = orderData || {};
  const { name: venueName, personalisation, closeTime } = venueData || {};
  const { bannerImgUrl } = personalisation || {};
  const { viewport, isIframeMode, isIframeBannerVisible, hasMvBannerImg } = useContext(AppContext);

  const hasBanner = isIframeMode ? isIframeBannerVisible : !!bannerImgUrl || hasMvBannerImg;
  const isHeroBannerVisibile = isIframeMode
    ? isIframeBannerVisible
    : bannerImgUrl || hasMvBannerImg;
  const { isTablet, isDesktop } = viewport;
  const isTabletOrDesktop = isTablet || isDesktop;

  const formattedSubtotal = formatCurrency(subtotal);
  const isCartEmpty = !orderData?.cart?.length;
  const itemIdQuery = queries.get('itemId');
  const modalOpenQuery = queries.get('modalOpen');
  const isModalQueryAvailable = itemIdQuery && modalOpenQuery;

  const handleAppendItemQueryParam = useCallback(
    (itemId) => {
      history.push({
        search: `?modalOpen=true&itemId=${itemId}`,
      });
    },
    [history],
  );

  const handleResetItemQueryParam = useCallback(() => {
    history.replace({
      search: null,
    });
  }, [history]);

  const handleModalToggle = useCallback(
    (item) => {
      const { itemId } = item;

      setCurrentItem(item);
      setIsModalOpen(!isModalOpen);
      handleAppendItemQueryParam(itemId);
    },
    [handleAppendItemQueryParam, isModalOpen],
  );

  const handleGetItemCount = useCallback(
    ({ item }) => getItemCount({ item, cart: cartData, venueId, isVoucher: true }),
    [cartData, venueId],
  );

  const handleOpenModalFromQuery = useCallback(() => {
    const item = getVoucherFromItemId(vouchersData, itemIdQuery);

    setCurrentItem(item);
    setIsModalOpen(true);
  }, [itemIdQuery]);

  const handleNavigateToReviewPage = useCallback(() => {
    history.push(`/vouchers/review/${venueId}/${orderId}`);
  }, [history, orderId, venueId]);

  useEffect(() => {
    if (vouchersData && isModalQueryAvailable) {
      handleOpenModalFromQuery();
    }
  }, [isModalQueryAvailable, handleOpenModalFromQuery]);

  const handleCloseModal = useCallback(() => {
    handleResetItemQueryParam();
    setIsModalOpen(false);
  }, [handleResetItemQueryParam]);

  useEffect(() => {
    if (!isModalQueryAvailable && isModalOpen) {
      handleCloseModal();
    }
  }, [handleCloseModal, isModalOpen, isModalQueryAvailable]);

  return venueData && orderData ? (
    <>
      {!isTabletOrDesktop ? (
        <MenuPageHeader title={venueName} hasBanner={hasBanner} />
      ) : (
        <MenuPageHeaderDesktop
          title={venueName}
          hasBannerImg={hasBanner}
          hasVoucherCta={false}
          hasSearch={false}
          venueId={venueId}
        />
      )}

      <div className="vouchers">
        {isHeroBannerVisibile &&
          (!isTabletOrDesktop ? (
            <HeroBanner text={venueName} />
          ) : (
            <HeroBannerDesktop text={venueName} closeTime={closeTime} />
          ))}
        <MenuCategories
          menuCategoryItems={vouchersData}
          handleGetItemCount={handleGetItemCount}
          handleModalToggle={handleModalToggle}
        />

        {!isCartEmpty && (
          <FooterCta
            onClick={handleNavigateToReviewPage}
            data-cy={cySelectors.SUBMIT_BTN}
            loading={orderLoading}
          >
            <span>Go to basket</span>
            <span>{formattedSubtotal}</span>
          </FooterCta>
        )}
        {isModalOpen && currentItem && (
          <VouchersModal
            isOpen={isModalOpen}
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            onCloseModal={handleCloseModal}
            currentItem={currentItem}
          />
        )}
      </div>
    </>
  ) : (
    <LoadingSpinner center />
  );
};

export default withHandleRoutesDispatch(Vouchers);
