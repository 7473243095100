const setPersonalisationIframeCssVariables = (iframePayload) => {
  const { colour, font, bannerImgUrl, logoImgUrl } = iframePayload;
  const documentStyle = document.documentElement.style;

  switch (true) {
    case !!colour:
      documentStyle.setProperty('--su-colour', colour);
      break;
    case !!font:
      documentStyle.setProperty('--su-font-family', font);
      break;
    case !!bannerImgUrl:
      documentStyle.setProperty('--su-banner-img-url', `url('${bannerImgUrl}')`);
      break;
    case !!logoImgUrl:
      documentStyle.setProperty('--su-logo-img-url', logoImgUrl);
      break;
    default:
      break;
  }
};

export default setPersonalisationIframeCssVariables;
