import getGroupedCartItemsByPriority from './getGroupedCartItemsByPriority';

const getGroupedCartItemsByVendorAndPriority = (groupedCartItemsWithVendorName) =>
  groupedCartItemsWithVendorName.map(({ vendorName, items, priorities }) => {
    const groupedCartItemsByPriority = getGroupedCartItemsByPriority(items, priorities);

    return {
      vendorName,
      priorities: groupedCartItemsByPriority,
    };
  });

export default getGroupedCartItemsByVendorAndPriority;
