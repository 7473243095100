import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'banked',
};

const fetchBankedSession = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  externalEndpoint: `${process.env.REACT_APP_BANKED_API_TOKEN_ENDPOINT}`,
  method: 'GET',
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchBankedSession];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchBankedSession };
export default reducer;
