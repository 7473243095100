import createEnhancedAsynThunk from '../../utils/createEnhancedAsynThunk';
import sliceHandler from '../../utils/sliceHandler';

const sliceArgs = {
  SLICE_NAME: 'user',
  ENDPOINT: 'users',
};

const fetchUserParamsTransformer = (params) => encodeURIComponent(params);

const fetchUser = createEnhancedAsynThunk({
  name: sliceArgs.SLICE_NAME,
  endpoint: sliceArgs.ENDPOINT,
  method: 'GET',
  paramsTransformer: fetchUserParamsTransformer,
});

const sliceName = sliceArgs.SLICE_NAME;

const extraReducers = [fetchUser];

const slice = sliceHandler({ sliceName, extraReducers });
const { reducer } = slice;

export { fetchUser };
export default reducer;
