import React from 'react';

import { ReactComponent as Tick } from '../../assets/images/confirmation-tick.svg';

const ConfirmationSpinner = () => (
  <div className="confirmation-spinner">
    <Tick />
  </div>
);

export default ConfirmationSpinner;
