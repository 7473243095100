import pick from 'lodash.pick';

const getSanitisedCartVoucher = (item) => {
  const sanitisedItem = pick(
    item,
    'description',
    'customExpiry',
    'endDate',
    'startDate',
    'itemId',
    'uniqItemId',
    'quantity',
  );

  return {
    ...sanitisedItem,
    amount: item.unitPrice,
  };
};

export default getSanitisedCartVoucher;
