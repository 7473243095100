import React, { useMemo } from 'react';
import clsx from 'clsx';

import once from 'lodash.once';
import MenuCategoryItemLazyLoadImage from '../MenuCategoryItemLazyLoadImage';
import ItemModalAllergens from '../../ItemAllergens';

import formatCurrency from '../../../utils/formatCurrency';
import { safeMultiply } from '../../../utils/safeArithmetics';

import cySelectors from '../../../tests/cySelectors';
import getAllergensContainedPerItem from '../../../pages/Menu/getAllergensContainedPerItem';
import sendGAPromotionSeen from '../../../utils/tracking/GA/sendGAPromotionSeen';

const MenuCategoryItem = ({
  item,
  handleModalToggle,
  itemCount,
  allergensSelected,
  isLazyLoadEnabled = true,
  isUpsell = false,
  isReviewUspell = false,
}) => {
  const { itemName, description, itemPrices, thumbUrl, discount, dietaryRequirements, itemId } =
    item;
  const dietaryPreferences = dietaryRequirements?.filter(({ type }) => type === 'requirement');
  const hasDietaryPreferences = !!dietaryPreferences?.length;
  const formattedPrice = formatCurrency(itemPrices[0]);
  const hasMultiplePrices = itemPrices.length > 1;
  const hasImage = !!thumbUrl;
  const discountValue = discount?.discountAmount;
  const discountedAmount = safeMultiply(itemPrices[0] / 100, discountValue);
  const finalFormattedDiscountedPrice = formatCurrency(itemPrices[0] - discountedAmount);
  const currentItemAllergens = item.dietaryRequirements?.filter(({ type }) => type === 'allergen');
  const allergensContained = getAllergensContainedPerItem(allergensSelected, currentItemAllergens);
  const hasAllergens = !!allergensContained?.length;
  const classes = clsx('menu__category-item', {
    'menu__category-item--has-allergens': hasAllergens,
    'menu__category-item--has-image': hasImage,
    'menu__category-item--has-discount': discount,
    'menu__category-item--is-upsell': isUpsell,
  });

  const SendSeentoGA = useMemo(() => once(sendGAPromotionSeen), []);

  SendSeentoGA(isReviewUspell ? 'CHECKOUT_UPSELL' : 'ITEM_UPSELL', itemId);

  const handleClick = () => {
    handleModalToggle();
  };

  return (
    <div className={classes}>
      {hasAllergens && <ItemModalAllergens allergensContained={allergensContained} />}
      <div
        role="button"
        tabIndex={0}
        className="menu__category-item__box"
        onClick={handleClick}
        onKeyDown={handleClick}
        data-cy={cySelectors.MENU_CATEGORY_ITEM}
      >
        <div className="menu__category-item__box__info">
          <h3 className="menu__category-item__box__name">
            {itemName}
            {!!itemCount && <span className="menu__category-item__box__count"> x {itemCount}</span>}
          </h3>
          {description && typeof description === 'string' && (
            <p className="menu__category-item__box__description">{description}</p>
          )}
          <div className="menu__category-item__box__price-preferences">
            <div className="menu__category-item__box__price">
              {hasMultiplePrices && (
                <span className="menu__category-item__box__price__from">from </span>
              )}
              {discount && (
                <span className="menu__category-item__box__price__discounted">
                  {finalFormattedDiscountedPrice}
                </span>
              )}
              <span className="menu__category-item__box__price__amount">{formattedPrice}</span>
            </div>
            {hasDietaryPreferences && (
              <div
                className="menu__category-item__box__preferences"
                data-cy={cySelectors.MENU_CATEGORY_ITEM__DIETARY}
              >
                {dietaryPreferences.map(({ requirementId, abbreviation }) => (
                  <span className="menu__category-item__box__preference" key={requirementId}>
                    {abbreviation}
                  </span>
                ))}
              </div>
            )}
          </div>
          {discount && (
            <div
              data-cy={cySelectors.MENU_ITEMS_DISCOUNT}
              className="menu__category-item__box__discount"
            >
              - {discountValue}%
            </div>
          )}
        </div>
        {thumbUrl &&
          (isLazyLoadEnabled ? (
            <MenuCategoryItemLazyLoadImage
              alt={itemName}
              thumbUrl={thumbUrl}
              isEnabled={isLazyLoadEnabled}
            />
          ) : (
            <div className="menu__category-item__box__img">
              <img alt={itemName} src={thumbUrl} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MenuCategoryItem;
