import React, { useContext } from 'react';
import LazyLoad from 'react-lazyload';

import AppContext from '../../App/AppContext';

import { ReactComponent as ImgPlaceholderIcon } from '../../../assets/images/img-placeholder.svg';

const MenuCategoryItemLazyLoadImage = ({ alt, thumbUrl }) => {
  const webpUrl = thumbUrl.replace(/\.jpg$/, '.webp');
  const { isWebpSupported } = useContext(AppContext);
  const imgSrc = isWebpSupported ? webpUrl : thumbUrl;

  return (
    <div className="menu__category-item__box__img">
      <LazyLoad
        height="100%"
        debounce={500}
        offset={100}
        once
        placeholder={<MenuCategoryItemLazyLoadImagePlaceholder />}
      >
        <img alt={alt} src={imgSrc} />
      </LazyLoad>
    </div>
  );
};

const MenuCategoryItemLazyLoadImagePlaceholder = () => (
  <div className="menu__category-item__box__img--placeholder">
    <ImgPlaceholderIcon title="loading..." />
  </div>
);

export default MenuCategoryItemLazyLoadImage;
