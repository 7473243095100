import React, { Suspense, lazy, memo, useCallback, useState, useEffect, useContext } from 'react';

import CheckoutContext from '../CheckoutContext';
import CheckoutPaidUpButton from '../CheckoutPaidUpButton';
import CheckoutPayWithCardModal from '../CheckoutPayWithCardModal';
import CheckoutStripePayment from '../CheckoutStripePayment';

import Button from '../../../components/Button';
import LoadingSpinner from '../../../components/LoadingSpinner';

import { isMacLike } from '../../../utils/isMacLike';

import { ReactComponent as CardIcon } from '../../../assets/images/credit-card.svg';

import cySelectors from '../../../tests/cySelectors';

const CheckoutStripeCard = lazy(() => import('../CheckoutStripeCard'));
const CheckoutComCard = lazy(() => import('../CheckoutComCard'));
const CheckoutComGooglePay = lazy(() => import('../CheckoutComGooglePay'));
const CheckoutComApplePay = lazy(() => import('../CheckoutComApplePay'));

const PaymentSystems = ({ systems = [], disableApplePay, isVouchers, isAmexEnabled }) => {
  const [isPaymentRequestPossible, setIsPaymentRequestPossible] = useState(true);
  const {
    isTotalZero,
    setPaymentError,
    isCheckoutCardModalOpen,
    setIsCheckoutCardModalOpen,
    paymentError,
  } = useContext(CheckoutContext);
  const [showOnlyCard, setShowOnlyCard] = useState(isTotalZero);

  const isApplePaySupported = !!window.ApplePaySession && window.ApplePaySession.canMakePayments();
  const isGooglePayAttempting = !isMacLike;
  const noPaidUpSystems = systems.filter((system) => system !== 'PAIDUP');
  const [system = ''] = noPaidUpSystems;
  const isCheckoutCom = system.includes('CHECKOUT');
  const isStripe = system.includes('STRIPE');
  const isPaidUp = isVouchers ? false : systems.includes('PAIDUP');
  const isPaidUpVisible = isPaidUp && !isTotalZero;
  const isStripeTotalZeroVisible = isStripe && isTotalZero;
  const isCheckoutComTotalZeroVisible = isCheckoutCom && isTotalZero;

  const handleToggleCardModal = useCallback(() => {
    setIsCheckoutCardModalOpen(!isCheckoutCardModalOpen);
  }, [isCheckoutCardModalOpen, setIsCheckoutCardModalOpen]);

  const handleCloseCardModal = () => {
    setPaymentError(null);
    setIsCheckoutCardModalOpen(false);
  };

  useEffect(() => {
    if (!isPaymentRequestPossible) setIsCheckoutCardModalOpen(false);
  }, [isPaymentRequestPossible, setIsCheckoutCardModalOpen]);

  useEffect(() => {
    if ((!isPaidUp && !isPaymentRequestPossible) || isTotalZero) {
      setShowOnlyCard(true);
    } else {
      setShowOnlyCard(false);
    }
  }, [isPaidUp, isPaymentRequestPossible, isTotalZero]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <CheckoutPayWithCardModal
        isModalOpen={isCheckoutCardModalOpen}
        handleCloseModal={handleCloseCardModal}
        setIsModalOpen={setIsCheckoutCardModalOpen}
        isPaidUpVisible={isPaidUpVisible}
        isAmexEnabled={isAmexEnabled}
      >
        {isStripe && <CheckoutStripeCard isAmexEnabled={isAmexEnabled} />}
        {isCheckoutCom && <CheckoutComCard isAmexEnabled={isAmexEnabled} />}

        {paymentError && (
          <div className="checkout__error" data-cy={cySelectors.CHECKOUT_FORM_ERROR}>
            {paymentError}
          </div>
        )}
      </CheckoutPayWithCardModal>
      {isStripeTotalZeroVisible && <CheckoutStripeCard isSubmitOnly={isTotalZero} />}
      {isCheckoutComTotalZeroVisible && <CheckoutComCard isSubmitOnly={isTotalZero} />}
      {!isTotalZero && (
        <div className="checkout-payment-systems">
          <p className="checkout-payment-systems__title">How would you like to pay?</p>
          {!isAmexEnabled && (
            <p className="checkout-payment-systems__helper-text">Amex is not accepted</p>
          )}
          <div className="checkout-payment-systems__buttons">
            {isPaidUpVisible && <CheckoutPaidUpButton />}
            <div className="checkout-payment-systems__buttons__merchant">
              <Button
                type="button"
                white
                textCenter
                onClick={handleToggleCardModal}
                data-cy={cySelectors.CHECKOUT_REVEAL_CARD}
                Icon={CardIcon}
              >
                Pay with Card
              </Button>

              {!showOnlyCard && (
                <>
                  {isCheckoutCom && (
                    <>
                      {isApplePaySupported && !disableApplePay && <CheckoutComApplePay />}
                      {isGooglePayAttempting && (
                        <CheckoutComGooglePay
                          setIsPaymentRequestPossible={setIsPaymentRequestPossible}
                        />
                      )}
                    </>
                  )}
                  {isStripe && (
                    <>
                      <CheckoutStripePayment
                        setIsPaymentRequestPossible={setIsPaymentRequestPossible}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="checkout__terms">
            <p>
              By completing payment you abide by our{' '}
              <a href="https://servedup.co/terms-conditions" target="_blank" rel="noreferrer">
                terms &amp; conditions
              </a>
              .
            </p>
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default memo(PaymentSystems);
