import React, { useCallback, useRef } from 'react';
import Modal from 'react-modal';

import { ReactComponent as CrossIcon } from '../../assets/images/cross.svg';

import cySelectors from '../../tests/cySelectors';

const FullscreenModal = ({
  isModalOpen,
  setIsModalOpen,
  onCloseModal = () => {},
  extraClasses = '',
  children,
}) => {
  const overlayRef = useRef(null);
  const classes = `fullscreen-modal__content ${extraClasses}`;
  const handleCloseModal = useCallback(() => {
    onCloseModal();
    setIsModalOpen(false);
  }, [onCloseModal, setIsModalOpen]);

  const handleCloseModalFromOverlay = useCallback(
    (event) => {
      const { target } = event;
      const isOverlayEl = target === overlayRef.current;

      if (isOverlayEl) {
        onCloseModal();
        setIsModalOpen(false);
      }
    },
    [onCloseModal, setIsModalOpen],
  );

  return (
    <Modal
      appElement={document.getElementById('root')}
      isOpen={isModalOpen}
      portalClassName="fullscreen-modal__portal"
      overlayClassName="fullscreen-modal__overlay"
      className={classes}
      overlayElement={(props, contentElement) => (
        <div
          {...props}
          onKeyDown={handleCloseModalFromOverlay}
          onClick={handleCloseModalFromOverlay}
          ref={overlayRef}
          role="button"
          tabIndex={0}
          data-cy={cySelectors.FULLSCREEN_MODAL_OVERLAY}
        >
          {contentElement}
        </div>
      )}
    >
      <button
        className="fullscreen-modal__close-btn"
        type="button"
        aria-label="Close dialog"
        onClick={handleCloseModal}
        onKeyDown={handleCloseModal}
        data-cy={cySelectors.FULLSCREEN_MODAL_CLOSE_BTN}
      >
        <CrossIcon />
      </button>
      <div className="fullscreen-modal__body">{children}</div>
    </Modal>
  );
};

export default FullscreenModal;
