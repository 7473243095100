import getGroupedCartItemsByVenueId from './getGroupedCartItemsByVenueId';

const getGroupedCartItemsWithVendorName = (items, vendors = [], priorities = []) => {
  const groupedCartItems = getGroupedCartItemsByVenueId(items);
  const hasVendors = !!vendors.length;

  if (!hasVendors) {
    return [
      {
        items,
        priorities,
      },
    ];
  }

  const groupedCartItemsWIthVendorName = Object.keys(groupedCartItems).map((vendorId) => {
    const targetVendor = vendors.find(({ venueId }) => venueId === vendorId);
    const { priorities: vendorPriorities } = targetVendor || {};
    const vendorName = targetVendor?.venueName || '';
    const vendorItems = groupedCartItems[targetVendor?.venueId];

    return {
      vendorName,
      items: vendorItems,
      priorities: vendorPriorities || [],
    };
  });

  return groupedCartItemsWIthVendorName;
};

export default getGroupedCartItemsWithVendorName;
