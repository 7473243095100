import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import Button from '../../../components/Button';
import Form from '../../../components/Form';
import QuantityChanger from '../../../components/QuantityChanger';
import FormField from '../../../components/FormField';
import Divider from '../../../components/Divider';

import MenuAllergens from '../MenuAllergens';
import MenuModalSize from '../MenuModalSize';
import MenuModalModifiers from '../MenuModalModifiers';
import MenuPriorities from '../MenuPriorities';

import sanitiseFormResponse from './sanitiseFormResponse';
import checkModifiersValidity from './checkModifiersValidity';
import MenuDietaryPreferences from '../MenuDietaryPreferences';

import formatCurrency from '../../../utils/formatCurrency';
import { safeMultiply, safeSum } from '../../../utils/safeArithmetics';

import ItemFormValidationSchema from './ItemFormValidationSchema';

import cySelectors from '../../../tests/cySelectors';

const MenuModalForm = ({
  currentItem,
  priorities,
  handleOnSubmit,
  isViewOnly,
  hasAllergensSelected,
}) => {
  const [itemQuantity, setItemQuantity] = useState(1);
  const [addToCartTotal, setAddToCartTotal] = useState(0);
  const [modifiersTotal, setModifiersTotal] = useState([]);
  const [sizeTotal, setSizeTotal] = useState(0);
  const [formInstance, setFormInstance] = useState({});
  const { watch, isValid } = formInstance || {};
  const currentModifiers = watch && watch('modifiers');

  const sanitisedModifiers = sanitiseFormResponse(currentModifiers);
  const {
    itemName,
    itemLabel,
    description,
    itemPrices,
    itemOptions,
    modifiers,
    dietaryRequirements,
    itemCalories,
    discount,
    priorityId,
  } = currentItem;

  const { loading: orderLoading } = useSelector((state) => state.order);
  const dietaryPreferences = dietaryRequirements?.filter(({ type }) => type === 'requirement');
  const allergens = dietaryRequirements?.filter(({ type }) => type === 'allergen');
  const hasAllergens = !!allergens?.length;
  const hasDietaryPreferences = !!dietaryPreferences?.length;
  const hasDietaryRequirements = hasAllergens || hasDietaryPreferences;
  const hasItemCalories = !!itemCalories?.length && itemCalories?.[0];
  const formattedAddToCartTotal = formatCurrency(addToCartTotal);
  const [itemPrice] = itemPrices;
  const discountValue = discount?.discountAmount;
  const discountedAmount = safeMultiply(sizeTotal / 100, discountValue);
  const discountTotal = safeMultiply(discountedAmount, itemQuantity);
  const formattedFinalPrice = formatCurrency(itemPrice - discountedAmount || itemPrice);
  const formattedDiscountedAddToCartTotal = formatCurrency(addToCartTotal - discountTotal);
  const classes = clsx({
    'item__modal--has-discount': discount,
  });

  const handleSubmit = (values) => {
    // swap out new modifier quantity format
    const updatedValues = { ...values };
    modifiersTotal.forEach((modifier) => {
      const { modifierId, selectedItemIds } = modifier;
      const updatedValueModifiers = Object.entries(updatedValues.modifiers).map(
        ([_key, value]) => value,
      );
      const targetModifier = updatedValueModifiers.find(
        (modifierItem) => modifierItem?.modifierId === modifierId,
      );
      if (selectedItemIds) targetModifier.modifierItems = selectedItemIds;
    });
    handleOnSubmit(itemQuantity, updatedValues);
  };
  const handleSetModifiersTotalCheckbox = useCallback(
    (modifierId, modifierItems, selectedItemIds) => {
      setModifiersTotal((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        const targetModifier = newState.find(
          (modifierItem) => modifierItem.modifierId === modifierId,
        );
        const total = selectedItemIds.reduce((acc, _curr) => {
          const targetModifierItemId = _curr.modifierItemId;
          const { itemPrice: modifierItemPrice } = modifierItems.find(
            (modifierItem) => modifierItem.itemId === targetModifierItemId,
          );

          return acc + modifierItemPrice * _curr.quantity;
        }, 0);

        if (targetModifier) {
          targetModifier.total = total;
          targetModifier.selectedItemIds = selectedItemIds;
        } else {
          newState.push({ modifierId, total, selectedItemIds });
        }

        return newState;
      });
    },
    [setModifiersTotal],
  );

  const handleSetModifiersTotalRadio = useCallback(
    (modifierId, modifierItems, selectedItemId) => {
      setModifiersTotal((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        const targetModifier = newState.find(
          (modifierItem) => modifierItem.modifierId === modifierId,
        );
        const { itemPrice: modifierItemPrice } = modifierItems.find(
          (modifierItem) => modifierItem.itemId === selectedItemId,
        );

        if (targetModifier) {
          targetModifier.total = modifierItemPrice;
        } else {
          newState.push({ modifierId, total: modifierItemPrice });
        }

        return newState;
      });
    },
    [setModifiersTotal],
  );

  const handleSetSizeTotal = useCallback((itemOptionPrice) => {
    setSizeTotal(itemOptionPrice);
  }, []);

  const increaseQuantity = () => {
    setItemQuantity(itemQuantity + 1);
  };

  const decreaseQuantity = () => {
    setItemQuantity((prevState) => (prevState <= 1 ? 1 : itemQuantity - 1));
  };

  const calculateAddToCartTotal = useCallback(() => {
    const modifiersSum = modifiersTotal.reduce(
      (acc, _curr, index, src) => acc + src[index].total,
      0,
    );

    return safeMultiply(safeSum(modifiersSum, sizeTotal), itemQuantity);
  }, [sizeTotal, modifiersTotal, itemQuantity]);

  useEffect(() => {
    setAddToCartTotal(calculateAddToCartTotal());
  }, [calculateAddToCartTotal]);

  return (
    <Form
      handleOnSubmit={handleSubmit}
      setFormInstance={setFormInstance}
      extraClasses={classes}
      disabled={isViewOnly || hasAllergensSelected}
      validationSchema={ItemFormValidationSchema}
      mode="onChange"
    >
      <div className="item__modal__body">
        {hasItemCalories && (
          <p className="item__modal__body__calories" data-cy={cySelectors.MENU_ITEMS_KCALS}>
            {itemCalories[0]} kcal
          </p>
        )}
        <div className="item__modal__body__title">
          <span className="item__modal__body__title__name">{itemLabel || itemName}</span>
          <span className="item__modal__body__title__price" data-cy={cySelectors.MENU_ITEMS_PRICE}>
            {formattedFinalPrice}
          </span>
        </div>
        {description && typeof description === 'string' && (
          <p className="item__modal__body__description">{description}</p>
        )}
        {itemOptions && itemOptions.length === 1 && (
          <p className="item__modal__body__single_size">{itemOptions[0]}</p>
        )}
        <Divider />
        {hasDietaryRequirements && (
          <>
            {hasDietaryPreferences && (
              <MenuDietaryPreferences dietaryPreferences={dietaryPreferences} />
            )}
            {hasAllergens && <MenuAllergens allergens={allergens} />}
            <Divider />
          </>
        )}
        {priorityId && (
          <>
            <MenuPriorities
              priorities={priorities}
              assignedPriorityId={priorityId}
              isViewOnly={isViewOnly}
            />
            <Divider />
          </>
        )}
        {itemPrices && itemOptions && (
          <MenuModalSize
            itemPrices={itemPrices}
            itemOptions={itemOptions}
            discount={discount}
            handleSetSizeTotal={handleSetSizeTotal}
          />
        )}
        {modifiers && (
          <>
            <MenuModalModifiers
              modifiers={modifiers}
              handleSetModifiersTotalCheckbox={handleSetModifiersTotalCheckbox}
              handleSetModifiersTotalRadio={handleSetModifiersTotalRadio}
            />
            <Divider />
          </>
        )}
        {!isViewOnly && (
          <FormField
            type="textarea"
            name="notes"
            label="Additional requests"
            maxLength={51}
            disabled={isViewOnly}
            data-cy={cySelectors.MENU_ITEMS_MODAL_NOTES}
          />
        )}
      </div>
      {!isViewOnly && !hasAllergensSelected && (
        <footer className="item__modal__footer" data-cy={cySelectors.MENU_ITEMS_MODAL_FOOTER}>
          <QuantityChanger
            quantity={itemQuantity}
            increaseQuantity={increaseQuantity}
            decreaseQuantity={decreaseQuantity}
          />
          <div className="item__modal__footer__btn">
            <Button
              fullWidth
              type="submit"
              disabled={
                orderLoading ||
                !checkModifiersValidity(sanitisedModifiers, modifiers, modifiersTotal) ||
                !isValid
              }
              loading={orderLoading}
              data-cy={cySelectors.MODIFIERS_SUBMIT_BTN}
            >
              <span>Add to order</span>
              <div className="item__modal__footer__btn__total">
                <span
                  data-cy={cySelectors.MENU_ITEMS_MODAL_TOTAL}
                  className="item__modal__footer__btn__total__amount"
                >
                  {formattedAddToCartTotal}
                </span>
                {discount && (
                  <span
                    data-cy={cySelectors.MENU_ITEMS_MODAL_TOTAL_DISCOUNTED}
                    className="item__modal__footer__btn__total__discounted"
                  >
                    {formattedDiscountedAddToCartTotal}
                  </span>
                )}
              </div>
            </Button>
          </div>
        </footer>
      )}
      {hasAllergensSelected && (
        <footer className="item__modal__footer" data-cy={cySelectors.MENU_ITEMS_MODAL_FOOTER}>
          <p>Remove allergen filter to order</p>
        </footer>
      )}
    </Form>
  );
};

export default MenuModalForm;
