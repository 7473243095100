import md5 from 'md5';
import getUniqueItemId from './getUniqueItemId';

const sanitiseModifiers = (modifiers = []) => {
  // Forces the modifierItems to be an array even when a single item is provided (for radio option).
  const sanitisedModifiers = modifiers?.map(({ modifierId, modifierItems }) => {
    const isModifierItemSingleItem = typeof modifierItems === 'string';
    const sanitisedModifierItems = isModifierItemSingleItem
      ? [modifierItems]
      : modifierItems?.filter((modifierItem) => modifierItem);

    return {
      modifierId,
      modifierItems: sanitisedModifierItems,
    };
  });

  // Removes empty modifiers and modifierItems when none is selected.
  const filteredModifiers = sanitisedModifiers?.filter(
    (modifier) => modifier && !modifier.modifierItems.every((modifierItem) => !modifierItem),
  );

  return filteredModifiers;
};

// TODO update backend to be able to collate quantities in BE
const getAddToCartPayload = (cart, newItem, newItemQuantity, childVenueId) => {
  const updatedCart = [...cart];
  const item = { ...newItem };

  const {
    modifiers,
    itemOption,
    priority,
    notes,
    isUpsell,
    upsoldFrom,
    upsoldFromItem,
    ...itemRest
  } = item;

  const sanitisedPriority = priority?.label;
  const sanitisedModifiers = sanitiseModifiers(modifiers);
  const sanitisedItemOption = itemOption.replace(/\s/g, '_').toUpperCase();
  const notesHash = notes && notes.trim() !== '' ? md5(notes) : undefined;
  const sanitizedNewItem = {
    ...itemRest,
    priority: sanitisedPriority,
    modifiers: sanitisedModifiers,
    itemOption: sanitisedItemOption,
    venueId: childVenueId,
    ...(notesHash && { notesHash }),
  };

  const newUniqItemId = getUniqueItemId(sanitizedNewItem);
  const targetItemIndex = updatedCart.findIndex(({ uniqItemId }) => uniqItemId === newUniqItemId);
  const targetItem = updatedCart[targetItemIndex];

  if (targetItem) {
    updatedCart[targetItemIndex] = {
      ...targetItem,
      quantity: targetItem.quantity + newItemQuantity,
    };

    return updatedCart;
  }

  const newItemPayload = {
    quantity: newItemQuantity,
    uniqItemId: newUniqItemId,
    priority: sanitisedPriority,
    notes,
  };

  if (isUpsell) {
    newItemPayload.isUpsell = isUpsell;
    newItemPayload.upsoldFrom = upsoldFrom;
    newItemPayload.upsoldFromItem = upsoldFromItem;
  }

  return [...cart, newItemPayload];
};

export default getAddToCartPayload;
