import groupBy from 'lodash.groupby';

const getGroupedCartItemsByVenueId = (cartItems = []) => {
  const cartItemsWithVenueId = cartItems.map((cartItem) => ({
    ...cartItem,
    ...JSON.parse(window.atob(cartItem.uniqItemId)),
  }));
  const hasCartItems = !!cartItemsWithVenueId.length;

  if (!hasCartItems) {
    return [];
  }

  const groupedCartItems = groupBy(cartItemsWithVenueId, 'venueId');

  return groupedCartItems;
};

export default getGroupedCartItemsByVenueId;
