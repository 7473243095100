import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';

import HeadTracking from '../HeadTracking';

const Head = () => {
  const { data: venueData } = useSelector((state) => state.venue);
  const title = venueData?.name || 'Sessions Serve';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <script defer src="https://cdn.checkout.com/js/framesv2.min.js" />
      </Helmet>

      <HeadTracking />
    </>
  );
};

export default Head;
