import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useAsyncServedUpError from '../../../../hooks/useAsyncServedUpError';

import Button from '../../../../components/Button';
import Form from '../../../../components/Form';
import FormField from '../../../../components/FormField';
import FormFieldToggle from '../../../../components/FormFieldToggle';
import QuantityChanger from '../../../../components/QuantityChanger';
import Divider from '../../../../components/Divider';

import { addToCartVoucher } from '../../../../store/slices/order';

import formatCurrency from '../../../../utils/formatCurrency';
import { safeMultiply } from '../../../../utils/safeArithmetics';

import VouchersFormSchema from './VouchersFormSchema';
import VouchersFormExpiryDates from './VouchersFormExpiryDates';
import sendGAItemAddToCart from '../../../../utils/tracking/GA/sendGAItemAddToCart';

import cySelectors from '../../../../tests/cySelectors';

const VouchersForm = ({ currentItem, onCloseModal }) => {
  const dispatch = useDispatch();
  const [values, setValues] = useState({});
  const [formInstance, setFormInstance] = useState();
  const [itemQuantity, setItemQuantity] = useState(1);
  const [addToCartTotal, setAddToCartTotal] = useState(0);
  const { data: venueData } = useSelector((state) => state.venue);
  const { data: orderData } = useSelector((state) => state.order);
  const { data: cartData } = useSelector((state) => state.cart);
  const { loading: orderLoading } = useSelector((state) => state.order);

  const { defaultVoucherExpiry = 6 } = venueData;

  const { description, type, itemPrices } = currentItem;
  const [itemPrice] = itemPrices;
  const { customExpiry } = values;
  const isFlatType = type === 'FLAT';
  const { watch, isValid } = formInstance || {};
  const throwError = useAsyncServedUpError();
  const formattedAddToCartTotal = formatCurrency(addToCartTotal);

  const defaultValues = useMemo(
    () => ({
      amount: isFlatType ? itemPrice : values.amount || 10,
      description: '',
      customExpiry: false,
    }),
    [isFlatType, itemPrice, values.amount],
  );

  // TODO: might need to refactor as is not pure fn venueId, orderId, patchOrder
  // are being used from the outer scope
  const handleSubmit = async (fields) => {
    const { venueId } = venueData;
    const { orderId } = orderData;
    const newItem = { ...fields };
    const payload = {
      venueId,
      newItem,
      itemQuantity,
      cart: cartData,
    };

    try {
      await dispatch(addToCartVoucher({ params: orderId, payload })).unwrap();

      sendGAItemAddToCart({ cart: [{ item_id: newItem }], venueId });
    } catch (error) {
      throwError({ error, venueId, orderId });
    } finally {
      onCloseModal();
    }
  };

  const increaseQuantity = () => {
    setItemQuantity(itemQuantity + 1);
  };

  const decreaseQuantity = () => {
    setItemQuantity((prevState) => (prevState <= 1 ? 1 : itemQuantity - 1));
  };

  const calculateAddToCartTotal = useCallback(
    () => safeMultiply(values.amount, itemQuantity),
    [values, itemQuantity],
  );

  useEffect(() => {
    if (formInstance) {
      const subscription = watch(setValues);

      return () => subscription.unsubscribe();
    }

    return setValues(defaultValues);
  }, [defaultValues, formInstance, watch]);

  useEffect(() => {
    setAddToCartTotal(calculateAddToCartTotal());
  }, [calculateAddToCartTotal, values.amount]);

  return (
    <Form
      handleOnSubmit={handleSubmit}
      setFormInstance={setFormInstance}
      validationSchema={VouchersFormSchema}
      mode="onChange"
      defaultValues={defaultValues}
      validateOnLoad
    >
      <div className="item__modal__body">
        {description && <p>{description}</p>}
        <Divider />
        <FormField
          name="amount"
          type="text"
          label="Amount"
          placeholder="£ 0"
          data-cy={cySelectors.VOUCHERS_AMOUNT_FIELD}
          helpText="Fill in desired voucher amount"
          extraClasses="vouchers__form__field__amount"
          readOnly={isFlatType}
        />
        <FormField
          name="description"
          type="textarea"
          label="Description"
          helpText="Fill in a description for the voucher"
        />
        <FormFieldToggle
          name="customExpiry"
          data-cy={cySelectors.VOUCHERS_EXPIRY_TOGGLE}
          label="Expiry"
          helpText="Vouchers expire by default after 6 months, toggle if you want to set a custom date"
        />
        <FormField name="itemId" type="hidden" value={currentItem.itemId} />
        {customExpiry && <VouchersFormExpiryDates defaultVoucherExpiry={defaultVoucherExpiry} />}
      </div>
      <footer className="item__modal__footer">
        <QuantityChanger
          quantity={itemQuantity}
          increaseQuantity={increaseQuantity}
          decreaseQuantity={decreaseQuantity}
          disabled={!isValid}
        />
        <div className="item__modal__footer__btn">
          <Button
            fullWidth
            type="submit"
            disabled={orderLoading || !isValid}
            loading={orderLoading}
            data-cy={cySelectors.MODIFIERS_SUBMIT_BTN}
          >
            <span>Add to order</span>
            <span>{formattedAddToCartTotal}</span>
          </Button>
        </div>
      </footer>
    </Form>
  );
};

export default VouchersForm;
