/* eslint-disable no-console */
import { isDev, isLocal } from '.';

class Logger {
  static dispatch(level, log) {
    const isEnvAllowed = isDev || isLocal;

    if (isEnvAllowed) {
      console[level](log);
      console.log('%c[StackTrace]', 'font-weight:bold;');
      console.trace();
    }
  }

  static log(log) {
    this.dispatch('log', log);
  }

  static info(log) {
    this.dispatch('info', log);
  }

  static error(log) {
    this.dispatch('error', log);
  }

  static warn(log) {
    this.dispatch('warn', log);
  }
}

export default Logger;
