import React from 'react';

import Button from '../../../components/Button';

import emptyResultsImg from '../../../assets/images/empty-results-plate.png';

const MenuEmptyResults = ({ handleResetQuery, title, text, btnText, dataCy }) => (
  <div className="menu__empty-results" data-cy={dataCy}>
    <img src={emptyResultsImg} alt={title || text} />
    <div className="menu__empty-results__text">
      {title && <h2>{title}</h2>}
      <p>{text}</p>
    </div>
    <Button onClick={handleResetQuery}>{btnText}</Button>
  </div>
);

export default MenuEmptyResults;
