import { errorCodes, inlineErrorCodes } from './errorCodes';

const getHumanReadableErrorMessage = (error, venueId, orderId) => {
  const { errorData, data } = error || {};
  const { nextOpeningDay, nextOpeningTime, openTime, venuePath, venueName } = errorData || {};
  const { message, errorCode } = error;

  const getMessage = (code) => {
    switch (code) {
      case errorCodes.CAN_T_CHECKOUT_IN_THIS_STATUS:
        return `Can't checkout like this. Have you added any items to your basket?`;
      case inlineErrorCodes.INVALID_PHONE:
        return 'The provided phone number is not valid.';
      case inlineErrorCodes.PROMO_CODE_WILL_EXCEED_THE_DAILY_LIMIT:
        return 'Sorry, it looks like this promo code has reached its limit';
      case inlineErrorCodes.INVALID_EMAIL:
        return 'The provided email is not valid.';
      case inlineErrorCodes.INVALID_PAYMENT:
        return `Sorry there seems to be an issue paying for this order.`;
      case inlineErrorCodes.VOUCHER_BALANCE_INSUFFICIENT:
        return `Sorry there is not enough on this voucher.`;
      case inlineErrorCodes.CAN_T_ACCEPT_ORDER_WITH_THIS_STATUS:
        return 'Sorry there seems to be an issue accepting this order.';
      case inlineErrorCodes.AMEX_NOT_SUPPORTED_VALIDATION || errorCodes.AMEX_INTERNATIONAL_CARD_NOT_SUPPORTED:
        return 'Amex cards are not currently accepted.';
      case errorCodes.FAILED_TO_SEND_EMAIL:
        return 'This email address is not valid, please check or try another one';
      case errorCodes.PAYMENT_FAILED:
        return 'Payment method failed. Please try another payment method.';
      case errorCodes.PAYMENT_DECLINED:
        return `Payment Declined: ${errorData}`;
      case errorCodes.CARD_TOKENIZED_FAILURE:
        return 'Card Payment failed, please retry, or try another card or payment method.';
      case errorCodes.CLOSED:
        return `We are currently closed, we are next open ${nextOpeningDay} at ${nextOpeningTime}.`;
      case errorCodes.QR_DISABLED:
        return `Sorry, order to table is not available at the moment`;
      case errorCodes.CLOSED_OPENING_LATER_TODAY:
        return `We are opening soon at: ${openTime}.`;
      case errorCodes.TAKEAWAY_MISSING_PICKUP_TIME_OR_PICKUP_DAY:
        return 'Please select a takeaway day and time.';
      case errorCodes.VENUE_NOT_FOUND:
        return `Venue ${venueId || venuePath} not found.`;
      case errorCodes.ORDER_NOT_FOUND:
        return `Order not found.`;
      case errorCodes.MENU_NOT_FOUND:
        return `Menu not found.`;
      case errorCodes.TABLES_NOT_FOUND:
        return 'Sorry, there are no Tables available to select.';
      case errorCodes.PROBLEM_ENCODING_ITEM:
        return 'Sorry there seems to be an issue with this menu item.';
      case errorCodes.NO_ACTIVE_MENUS:
        return "Sorry we can't find any active menus at this venue.";
      case errorCodes.TABLE_NOT_FOUND:
        return "Sorry we can't find this table at this venue.";
      case errorCodes.YOUR_CART_IS_EMPTY:
        return 'Sorry it looks like there are no items in your cart.';
      case errorCodes.VERIFICATION_CONFIRMATION_ISSUE:
        return 'Sorry there seems to be an issue veryfying your payment method.';
      case errorCodes.VERIFICATION_ISSUE:
        return 'Sorry there seems to be an issue veryfying your payment method.';
      case errorCodes.INVALID_ORDER_STATUS:
        return 'Sorry it looks like there is a problem with this order.';
      case errorCodes.APPLEPAY_ALREADY_PAYMENT_SESSION:
        return 'Sorry it looks like there might be a problem with applepay, please try using a card.';
      case errorCodes.APPLEPAY_VISA_NOT_SUPPORTED:
        return 'Sorry it looks like there might be a problem with applepay, please try using a card.';
      case errorCodes.APPLEPAY_UNSUPPORTED_ERROR:
        return 'Sorry it looks like there might be a problem with applepay, please try using a card.';
      case errorCodes.VOUCHERS_NOT_ENABLED:
        return 'Vouchers are not enabled for this venue at the moment.';
      case errorCodes.SOME_ITEMS_ARE_SOLDOUT:
        return `One or more items in your order isn't available at this time.

${data?.missingItemsName}

Affected items have been removed from your basket

No payment has been taken.`;
      case inlineErrorCodes.VOUCHER_NOT_FOUND:
        return `This code doesn’t look right`;
      case inlineErrorCodes.PROMO_CODE_ALREADY_APPLIED:
        return `This promo code is already applied`;
      case inlineErrorCodes.VOUCHER_ALREADY_USED_IN_THIS_ORDER:
        return `This voucher is already applied`;
      case inlineErrorCodes.VOUCHER_IS_DISABLED:
        return `This voucher is no longer valid`;
      case inlineErrorCodes.VOUCHER_HAS_NO_REMAINING_BALANCE:
        return `This voucher has no funds left`;
      case inlineErrorCodes.VOUCHER_NOT_STARTED_YET:
        return `This voucher is not active yet`;
      case inlineErrorCodes.VOUCHER_EXPIRED:
        return `This voucher has expired`;
      case inlineErrorCodes.ORDER_HAS_TOTAL_OF_0:
        return `This code doesn’t look right`;
      case inlineErrorCodes.CANNOT_USE_PROMOCODE_WITH_VOUCHER_CODE:
        return `You cannot combine a promocode and voucher `;
      case inlineErrorCodes.CANNOT_USE_MULTIPLE_VOUCHERS:
        return `You cannot use multiple vouchers`;
      case inlineErrorCodes.CODE_APPLIED_SUCCESSFULLY:
        return `Code applied successfully!`;
      case inlineErrorCodes.CODE_REMOVED_SUCCESSFULLY:
        return `Code removed successfully!`;
      case inlineErrorCodes.CODE_COULD_NOT_BE_REMOVED:
        return `Code could not be removed`;
      case errorCodes.PAIDUP_FAILED:
        return `Failed to Pay by Bank : please try again or choose another payment method.`;
      case inlineErrorCodes.RATING_ALREADY_GIVEN:
        return `Rating already given for this order`;
      case inlineErrorCodes.RATING_ON_UNPAID_ORDER:
        return `Can't rate an unpaid order`;
      case inlineErrorCodes.PROMO_ALREADY_REDEEMED:
        return `Sorry, this promo code has already been redeemed`;
      case inlineErrorCodes.SORRY_IT_LOOKS_LIKE_ALL_PRODUCTS_HAVE_BEEN_REDEEMED_IN_THIS_VENUE:
        return `Sorry, it looks like all products have been redeemed in this venue`;
      case inlineErrorCodes.INVALID_PROMO_CODE:
        return `Sorry, it looks like this is not a valid promo code`;
      case inlineErrorCodes.PROMO_CODE_LIMIT_REACHED:
        return `Sorry, it looks like this promo code reached his limit`;
      case inlineErrorCodes.PROMO_EXPIRED:
        return `Sorry, it looks like this promo code has expired`;
      case inlineErrorCodes.CAMPAIGN_HAS_NOT_STARTED_YET:
        return `Sorry, this campaign has not started yet`;
      case inlineErrorCodes.CAMPAIGN_EXPIRED:
        return `Sorry, this campaign has finished`;
      case inlineErrorCodes.CART_DOES_NOT_CONTAIN_PROMO_ITEM:
        return `Sorry, the promotional item is not in your cart`;
      case inlineErrorCodes.PROMO_CODE_NOT_VALID_AT_THIS_DAY_TIME:
        return `Promo code is not valid at this day/time.
Please remove and try again`;
      case inlineErrorCodes.PROMO_CODE_NOT_FOUND:
        return `Promo code not found.
Please remove and try again`;
      default:
        return `Sorry, an error occurred, please try again. (${code})`;
    }
  };

  const getRedirect = (code) => {
    switch (code) {
      case errorCodes.VENUE_NOT_FOUND:
        return {
          hide: true,
        };
      case errorCodes.ORDER_NOT_FOUND:
      case errorCodes.VOUCHERS_NOT_ENABLED:
        return {
          url: `/menu/${venueId}`,
          text: 'Back to menu',
        };
      case errorCodes.QR_DISABLED:
      case errorCodes.CLOSED:
        return {
          url: `/menu/${venueId}`,
          text: 'Try again',
        };
      default:
        return {
          url: `/menu/${venueId}/${orderId}`,
          text: 'Back to menu',
        };
    }
  };

  return {
    message: getMessage(errorCode || message),
    redirect: getRedirect(errorCode || message),
    code: message,
    venuePath,
    venueName,
  };
};

export default getHumanReadableErrorMessage;
