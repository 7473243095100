import React from 'react';

import OverlayModal from '../OverlayModal';

import cySelectors from '../../tests/cySelectors';

const PromotionsModal = ({ promotions, ...props }) => (
  <OverlayModal
    buttonText="Thanks!"
    extraClasses="promotions-modal"
    data-cy={cySelectors.PROMOTIONS_MODAL}
    {...props}
  >
    {promotions.map(({ message, items }) => (
      <div className="promotions-modal__promotion" key={message}>
        <strong>{message}</strong>

        <ul>
          {items.map(({ itemId, itemName }) => (
            <li key={itemId}>
              <span>{itemName}</span>
            </li>
          ))}
        </ul>
      </div>
    ))}
  </OverlayModal>
);

export default PromotionsModal;
