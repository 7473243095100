import axios from 'axios';
import ServedUpError from '../utils/ServedUpError';

const servedupRequest = async ({
  endpoint,
  externalEndpoint,
  method,
  headers,
  payload,
  handleError = true,
}) => {
  const { REACT_APP_API_URL } = process.env;
  const url = externalEndpoint || `${REACT_APP_API_URL}/${endpoint}`;

  if (handleError) {
    try {
      const response = await axios({
        method,
        url,
        data: payload,
        headers,
      });

      return response.data;
    } catch (error) {
      throw new ServedUpError({
        error,
      });
    }
  } else {
    const response = await axios({
      method,
      url,
      data: payload,
      headers,
    });

    return response.data;
  }
};

export default servedupRequest;
